import React from "react";
import { Button, Form, Input, message } from "antd";
import { connect } from "react-redux";

import {
  hideMessage,
  showAuthLoader,
  hideAuthLoader
} from "appRedux/actions/Auth";
import CircularProgress from "components/CircularProgress/index";
import * as Amplify from 'aws-amplify';
import notify from '../Notification';
import {
  showModal
} from 'appRedux/actions/Common';


const FormItem = Form.Item;

class Forgot extends React.Component {
  constructor() {
    super()
    this.state = {
      loader: false
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // this.props.showAuthLoader();
        this.setState({ loader: true })
        this.forgotPassword(values.email);
      }
    });
  };

  forgotPassword = (email) => {
    Amplify.Auth.forgotPassword(email, {})
      .then(success => {
        // this.props.hideAuthLoader();
        this.setState({ loader: false })
        notify.openNotificationWithIcon('success', 'Success', 'Code was sent');
        window.sessionStorage.setItem("email", email);
        this.props.showmodaldata(5)
      })
      .catch(err => {
        console.log(err)
        notify.openNotificationWithIcon('error', 'Fail', err.message);
        // this.props.hideAuthLoader();
        this.setState({ loader: false })
      })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { showMessage, alertMessage } = this.props;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
              </div>
              <div
                className="gx-app-logo"
                style={{ marginBottom: "auto" }}
              >
                <img alt="example" src={require("assets/images/logo.png")} />
              </div>
              <div className="gx-app-logo-wid">
                <h1>Forgot Password</h1>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                <FormItem
                  label="Email"
                >
                  {getFieldDecorator('email', {
                    rules: [{
                      required: true, type: 'email', message: 'The input is not valid E-mail!',
                    }],
                  })(
                    <Input placeholder="Enter Email Address" />
                  )}
                </FormItem>

                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    Submit
                  </Button>
                </FormItem>
              </Form>
            </div>

            {this.state.loader ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(Forgot);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  hideAuthLoader,
  showModal
})(WrappedNormalLoginForm);
