import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import './index.less'
import asyncComponent from '../util/asyncComponent'
import PrivateRoute from "./PrivateRoute";
import ProfilePage from "./Profile";
import CheckOutDetailsPage from "./CheckOutDetails"
import Report from './Report'
import AddCustomQuery from './CustomQuery/AddCustomQuery/index'
import PurchaseCustomQuery from './CustomQueryPurchse/index'
import CustomQueryBuilder from './CustomQueryBuilder/CreateQuey/index'
import CheckoutPage from "./Checkout";
import PaymentStatus from "./Payment/PaymentStatus";

const App = ({ match }) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}home`} exact component={asyncComponent(() => import('./Home'))} />
      <Route path={`${match.url}result`} component={asyncComponent(() => import('./Result'))} />
      <Route path={`${match.url}license-search`} component={asyncComponent(() => import('./LicenseSearch/index'))} />
      <Route path={`${match.url}about`} component={asyncComponent(() => import('./About'))} />
      <Route path={`${match.url}support`} component={asyncComponent(() => import('./Support'))} />
      <Route path={`${match.url}purchase`} component={asyncComponent(() => import('./Purchase'))} />
      <Route path={`${match.url}subscriptions`} component={asyncComponent(() => import('./Subscriptions'))} />
      <Route path={`${match.url}signup`} exact component={asyncComponent(() => import('../containers/SignUp2'))} />
      <Route path={`${match.url}error500`} component={asyncComponent(() => import('./ErrroPage/ErrorPage'))} />
      <Route path={`${match.url}verificationcodes`} exact component={asyncComponent(() => import('../containers/VerificationCode'))} />
      <Route path={`${match.url}subscription`} exact component={asyncComponent(() => import('./Subscriptions/SubscriptionPage'))} />

      {/* <Route path={`${match.url}yearlysubscription`} exact component={asyncComponent(() => import('../routes/YearlySubscription/index'))} /> */}

      <PrivateRoute
        component={ProfilePage}
        path='/Profile'
        exact
      />
      <PrivateRoute
        component={CheckOutDetailsPage}
        path='/checkoutdetails'
        exact
      />
      <PrivateRoute
        component={CheckoutPage}
        path='/checkout'
        exact
      />
      <PrivateRoute
        component={Report}
        path='/report'
        exact
      />
      {/* <Route
        path={`${match.url}customquery`}
        component={asyncComponent(() => import('./CustomQuery'))}
        exact
      /> */}
      <Route
        path={`${match.url}customquerybuilder`}
        component={asyncComponent(() => import('./CustomQueryBuilder'))}
        exact
      />
      <PrivateRoute
        component={CustomQueryBuilder}
        path='/CustomQueryBuilder/add'
        exact
      />

      {/* <PrivateRoute
        component={PaymentStatus}
        path='/paymentstatus'
        exact
      /> */}

      {/* <PrivateRoute
        component={AddCustomQuery}
        path='/customquery/add'
        exact
      /> */}
      <PrivateRoute
        component={PurchaseCustomQuery}
        path='/customquery/purchase'
        exact
      />
    </Switch>
  </div>
);

export default withRouter(App);
