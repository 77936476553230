import React from "react";
import { Table, Pagination } from "antd";

const DataTable = (props) => {
  return (
    <>
      <Table
        className={`gx-table-responsive`}
        columns={props.columns}
        dataSource={props.data}
        pagination={false}
      />
      <Pagination
        className={props.className}
        defaultCurrent={1}
        total={props.pageTotal}
        onChange={(pageNumber) => props.onChange(pageNumber)}
        showSizeChanger
        onShowSizeChange={(current, pageSize) => props.onShowSizeChange(current, pageSize)}
        pageSizeOptions={['10', '15', '50', '100']}
      />
    </>
  );
};

export default DataTable;