import React, { useState } from 'react'
import { Prompt, withRouter } from 'react-router-dom'
// import {CustomModal} from './CustomModal'
import { message,  Modal, Button } from 'antd';
import { API, } from 'aws-amplify';
import { setSubscriptionChange, userSignOutSuccessData } from "../../appRedux/actions/DataTable";
import { userSignOutSuccess } from "../../appRedux/actions/Auth";
import { signOut } from "../../appRedux/amplify";
import { connect, useDispatch } from 'react-redux';

const RouteLeavingGuard = (props) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);


  const showModal = (location) => {
    setVisible(true)
    setLastLocation(location)
  }

  const closeModal = (callback) => {
    setVisible(false)
    // callback()
  }

  const handleBlockedNavigation = (nextLocation) => {
    const { shouldBlockNavigation } = props
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      showModal(nextLocation)
      return false
    }
    return true
  }


  const handleLogout = async () => {
    signOut(dispatch(userSignOutSuccess()));
    signOut(dispatch(userSignOutSuccessData()));
    window.sessionStorage.clear()
    dispatch(setSubscriptionChange(true))
    await setConfirmedNavigation(true)
    props.history.push('/home')
  }

  const handleConfirmNavigationClick = async () => {
    let body = {
      ...props.promptParams.data
    }
    var res = await API.post('PaymentGatewayAPI', '/paymentgateway/deleteUser', { body })
    if (res.success) {
      // let isCustomer = true
      window.sessionStorage.setItem('isProcessRunning', false)
      closeModal()
      if (props.promptParams && props.promptParams.data.customerId) {
        handleLogout()
        // isCustomer = false
      } else {
        await setConfirmedNavigation(true)
        props.history.push(lastLocation)

      }
    } else {
      message.error(<p dangerouslySetInnerHTML={{ __html: props.promptParams.errorMessage }} />)
    }

  }


  const { when } = props

  let messageText = props.promptParams && props.promptParams.messageText
  return (
    <>
      <Prompt
        when={when}
        message={handleBlockedNavigation} />
      <Modal
        title="Vertically centered modal dialog"
        centered
        visible={visible}
        onOk={() => handleConfirmNavigationClick(true)}
      >
        <div className='confirm-popup-content2'>
          <div className='report-modal-content'>
            <p className='confirm-popup-img'> <img alt="" src={require("assets/images/canoja-leaf-gold.png")} /></p>
          </div>
          <div className='report-modal-content confirm-popup-text'>
            <p><p dangerouslySetInnerHTML={{ __html: messageText }} /></p>
          </div>
        </div>
        <div className='modal-button'>
          <Button onClick={() => handleConfirmNavigationClick(true)} type="primary">Ok</Button>
          <Button onClick={() => closeModal()}>Cancel</Button>
        </div>
      </Modal>
    </>
  )

}

const mapStateToProps = (state) => ({

});

export default connect(mapStateToProps, {
  signOut,
  userSignOutSuccess,
  userSignOutSuccessData,
  setSubscriptionChange,
})(withRouter(RouteLeavingGuard));