import React from "react";
import { connect } from "react-redux";
import { Button, Checkbox } from 'antd';

class Confirm extends React.Component {
  state = {
    checked: false,
    isOver: true,
  }
  handleCheckChange = () => {
    this.setState({ checked: !this.state.checked });
  }
  clickNo = () => {
    this.setState({ isOver: false });
    setTimeout(() => {
      window.location.href = "https://google.com";
    }, 2000)
  }
  clickYes = () => {
    if (this.state.checked) {
      this.setCookie('canoja', 'checked', 30)
    }
    this.props.history.push('/home');
  }
  setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (30 * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
  render() {
    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container age-container">
          <div className="gx-app-login-main-content">
            <img width='200' alt="Canoja" src={require("assets/images/logo.png")} style={{ marginBottom: "20px" }} />
            <h2>Welcome to Canoja.</h2>
            <h2>Thanks for stopping by.</h2>
            <h3>Are you at least 21 years old?</h3>
            <div style={{ display: 'flex' }}>
              <Button style={{ flex: 1 }} onClick={this.clickNo}>No</Button>
              <Button style={{ flex: 1 }} onClick={this.clickYes}>Yes</Button>
            </div>
            <Checkbox id='checked' name="checked" checked={this.state.checked} onChange={this.handleCheckChange}>
              <div>
                Remember me for 30 days.<br />
                I confirm that this is not shared device
              </div>
            </Checkbox>
            <p style={{ marginTop: '10px' }}>
              By accessing this site, you accept the <a href="https://canoja-verify-images.s3.amazonaws.com/CanojaTech-Terms-of-service-v3.pdf">Terms of Use</a> and <a href="https://canoja-verify-images.s3.amazonaws.com/CanojaTech-Privacy-Policy-v3.pdf">Privacy Policy</a>.
            </p>
            {!this.state.isOver ? <div className='ant-form-explain has-error'>You are not old enough to use Canoja</div> : null}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

export default connect(mapStateToProps, {
})(Confirm);
