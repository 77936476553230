import React, { useEffect, } from 'react'
import { Statistic, Modal, } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { userSignOutSuccess } from "appRedux/actions/Auth";
import { userSignOutSuccessData } from "../../appRedux/actions/DataTable";
import { setTimeOut } from "../../appRedux/actions/SetTime";
import { signOut } from "appRedux/amplify";
import { useHistory } from "react-router-dom";
import './CountDownTime.css'

const { Countdown } = Statistic;
const events = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress"
];


const { confirm } = Modal;


const CountDownTime = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { timeOut } = useSelector(({ time }) => time);

    const showConfirm = () => {
        let isModalOpen = true
        const modal = confirm({
            title: 'Canoja Varify',
            icon: '',
            content:
                (
                    <div>
                        <div className='confirm-popup-content'>
                            <p className='confirm-popup-img'> <img alt="" src={require("assets/images/canoja-leaf-gold.png")} /></p>
                        </div>
                        <div className='confirm-popup-content confirm-popup-text'>

                            <p>Your session is about expires.</p>
                            <p> You will be logged out in 5 minutes..</p>
                        </div>
                    </div>
                ),
            okText: 'Continue Session',
            cancelText: 'Logout',
            onOk() {
                isModalOpen = false
                dispatch(setTimeOut(Date.now()));
            },
            onCancel() {
                isModalOpen = false
                dispatch(setTimeOut(null));
                signOut(dispatch(userSignOutSuccess()));
                signOut(dispatch(userSignOutSuccessData()));
                window.sessionStorage.clear()
                history.push("/home");
            },
        });
        setTimeout(() => {
            if (isModalOpen) {
                modal.destroy();
                dispatch(setTimeOut(null));
                signOut(dispatch(userSignOutSuccess()));
                signOut(dispatch(userSignOutSuccessData()));
                window.sessionStorage.clear()
                history.push("/home");
            }
        }, 300000);

    }


    useEffect(() => {

        const timeout = () => {
            if (window.sessionStorage.getItem('user_token')) {
                dispatch(setTimeOut(Date.now()));
            }
        }

        if (sessionStorage.getItem('user_token')) {
            dispatch(setTimeOut(Date.now()));
            timeout()
            for (var i in events) {
                document.addEventListener(events[i], () => timeout());
            }
        } else {
            for (var item in events) {
                document.addEventListener(events[item], () => timeout());
            }
            dispatch(setTimeOut(null));
        }
    }, [dispatch]);


    const Completionist = () => {
        if (window.sessionStorage.getItem('user_token')) {
            dispatch(setTimeOut(null));
            showConfirm()
        }
    };

    return (
        <React.Fragment>
            <Countdown prefix={" session expire :  "} valueStyle={{ fontSize: '14px', display: 'none' }} value={timeOut ? timeOut + 100 * 60 * 10 * 10 : 0} onFinish={Completionist} format="mm:ss" />
        </React.Fragment>
    )

}


export default CountDownTime