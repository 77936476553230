import * as Amplify from 'aws-amplify';

export const signOut = async (func) => {
  Amplify.Auth.signOut()
    .then (success => {
      func();
    })
    .catch (err => {

    })
}

export const currentUser = async () => {
  return await Amplify.Auth.currentAuthenticatedUser();
}