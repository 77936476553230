import { Link } from "react-router-dom";
import React from 'react';


// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const SIGNIN_USER_SUCCESS_DATA = 'SIGNIN_USER_SUCCESS_DATA';
export const SIGNOUT_USER_SUCCESS_DATA = 'SIGNOUT_USER_SUCCESS_DATA';
export const INIT_URL = 'INIT_URL';
export const SET_TIMEOUT = 'SET_TIMEOUT';
//Sticky
export const GET_STICKY = 'GET_STICKY';
export const NOTES_UPDATE = 'NOTES_UPDATE';
export const FETCH_ALL_NOTES_SUCCESS = 'FETCH_ALL_NOTES_SUCCESS';
export const UPDATE_ALL_NOTES_SUCCESS = 'UPDATE_ALL_NOTES_SUCCESS';

//Contact
export const GET_All_SUPPORT_SUCCESS = 'GET_All_SUPPORT_SUCCESS';
export const ON_ADD_SUPPORT_SUCCESS = 'ON_ADD_SUPPORT_SUCCESS';
export const UPDATE_SUPPORT_SUCCESS = 'UPDATE_SUPPORT_SUCCESS';
export const DELETE_SUPPORT_SUCCESS = 'DELETE_SUPPORT_SUCCESS';
export const LICENCE_NUMBER = 'LICENCE_NUMBER';
export const RESET_PURCHASE = 'RESET_PURCHASE';
export const DO_PURCHASE = 'DO_PURCHASE';
export const IS_PURCHASE = 'IS_PURCHASE';
export const SHOW_MODAL = 'SHOW_MODAL';

//Aws
export const SET_GRAPHQL_API_LIMIT = 'SET_GRAPHQL_API_LIMIT';
export const SET_CURRENTLY_LOGGED_USER = 'SET_CURRENTLY_LOGGED_USER'
export const SET_SUBSCRIPTION_CHANGE = 'SET_SUBSCRIPTION_CHANGE'

export const SAVE_FILTER = 'SAVE_FILTER';
export const SAVE_SORTER = 'SAVE_SORTER';
export const SAVE_FILTERS = 'SAVE_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';

export const SORTERS = {
  "License Number": "licenseNumber",
  "License Category": "licenseCategory",
  "License Type": "licenseType",
  "License Status": "licenseStatus",
  "License Issue Date": "licenseIssueDate",
  "License Expire Date": "licenseExpireDate",
  "License Owner": "licenseOwner",
  "Retail": "retail",
  "Medical": "medical",
  "Business Name": "businessLegalName",
  "Business Doing Business As": "businessDoingBusinessAs",
  "Business Address 1": "businessAddress1",
  "Business Address 2": "businessAddress2",
  "City": "businessCity",
  "County": "businessCounty",
  "State": "businessState",
  "Zip Code": "businessZipcode",
  "Last Updated": "last_updated",
  "Country": "businessCountry",
  "Business Phone": "businessPhoneNumber",
  "Business Email": "businessEmailAddress",
  "Business Structure": "businessStructure",
  "refreshed_on": "refreshed_on",
  // "license_status_authority": "license_status_authority",
  // "license_type_authority": "license_type_authority",
  // "license_category_authority": "license_category_authority",
}

export const COLUMNS = [
  {
    title: 'License Number',
    dataIndex: 'number',
    sorter: (a, b) => a.number.localeCompare(b.number)
  },
  {
    title: 'Business Name',
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    title: 'License Status',
    dataIndex: 'status',
    sorter: (a, b) => a.status.localeCompare(b.status),
    filters: [],
    onFilter: (value, record) => record.status.includes(value),
  },
  {
    title: 'License Category',
    dataIndex: 'category',
    sorter: (a, b) => a.category.localeCompare(b.category),
    filters: [],
    onFilter: (value, record) => record.category.includes(value),
  },

  {
    title: 'City',
    dataIndex: 'city',
    sorter: (a, b) => a.city.localeCompare(b.city)
  },
  {
    title: 'State',
    dataIndex: 'state',
    sorter: (a, b) => a.state.localeCompare(b.state),
  },
  {
    title: 'Country',
    dataIndex: 'country',
    sorter: (a, b) => a.country.localeCompare(b.country)
  },
];

export const COLUMNS_FREE_PLAN = [
  {
    title: 'License Number',
    dataIndex: 'number',
    sorter: (a, b) => a.number.localeCompare(b.number)
  },
  {
    title: 'Business Name',
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    title: 'License Status',
    dataIndex: 'status',
    sorter: (a, b) => a.status.localeCompare(b.status),
    filters: [],
    render: () => <React.Fragment><Link to={{
      pathname: "/subscriptions",
    }} >Subscribe</Link></React.Fragment>,
    onFilter: (value, record) => record.status.includes(value),
  },
  {
    title: 'License Category',
    dataIndex: 'category',
    sorter: (a, b) => a.category.localeCompare(b.category),
    filters: [],
    onFilter: (value, record) => record.category.includes(value),
  },

  {
    title: 'City',
    dataIndex: 'city',
    sorter: (a, b) => a.city.localeCompare(b.city)
  },
  {
    title: 'State',
    dataIndex: 'state',
    sorter: (a, b) => a.state.localeCompare(b.state),
  },
  {
    title: 'Country',
    dataIndex: 'country',
    sorter: (a, b) => a.country.localeCompare(b.country)
  },
];

const sorter = (v1, v2) => {
  return (v1 === null) - (v2 === null) || (isFinite(v1) && isFinite(v2) ? v1 - v2 : v1.toString().localeCompare(v2))
}

export const COLUMNS_ENTERPRISE_PLAN = [
  {
    title: 'License Number',
    dataIndex: 'number',
    sorter: (a, b) => a.number.localeCompare(b.number)
  },
  {
    title: 'Business Name',
    dataIndex: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    title: 'Business Doing Business As',
    dataIndex: 'business_doing_business_as',
    sorter: (a, b) => a.business_doing_business_as.localeCompare(b.business_doing_business_as),
  },
  {
    title: 'License Status',
    dataIndex: 'status',
    sorter: (a, b) => a.status.localeCompare(b.status),
    filters: [],
    onFilter: (value, record) => record.status.includes(value),
  },
  {
    title: 'License Category',
    dataIndex: 'category',
    sorter: (a, b) => a.category.localeCompare(b.category),
    filters: [],
    onFilter: (value, record) => record.category.includes(value),
  },
  {
    title: 'License Type',
    dataIndex: 'type',
    sorter: (a, b) => a.type.localeCompare(b.type),
    onFilter: (value, record) => record.type.includes(value),
  },
  {
    title: 'Medical',
    dataIndex: 'medical',
    sorter: (a, b) => a.medical.localeCompare(b.medical),
  },
  {
    title: 'Retail',
    dataIndex: 'retail',
    sorter: (a, b) => a.retail.localeCompare(b.retail),
  },
  {
    title: 'License Issue Date',
    dataIndex: 'issue',
    sorter: (a, b) => a.issue.localeCompare(b.issue),
  },
  {
    title: 'License Expire Date',
    dataIndex: 'expire',
    sorter: (a, b) => a.expire.localeCompare(b.expire),
  },
  {
    title: 'License Owner',
    dataIndex: 'owner',
    sorter: (a, b) => a.owner.localeCompare(b.owner),
  },
  {
    title: 'Business Address 1',
    dataIndex: 'address1',
    sorter: (a, b) => a.address1.localeCompare(b.address1),
  },
  {
    title: 'Business Address 2',
    dataIndex: 'address2',
    sorter: (a, b) => a.address2.localeCompare(b.address2),
  },
  {
    title: 'City',
    dataIndex: 'city',
    sorter: (a, b) => a.city.localeCompare(b.city)
  },
  {
    title: 'State',
    dataIndex: 'state',
    sorter: (a, b) => a.state.localeCompare(b.state),
    onFilter: (value, record) => record.state.includes(value),
  },
  {
    title: 'Zip Code',
    dataIndex: 'zipcode',
    sorter: (a, b) => a.zipcode.localeCompare(b.zipcode)
  },
  {
    title: 'County',
    dataIndex: 'county',
    sorter: (a, b) => a.county.localeCompare(b.county),
  },
  {
    title: 'Country',
    dataIndex: 'country',
    sorter: (a, b) => a.country.localeCompare(b.country),
    onFilter: (value, record) => record.country.includes(value),
  },
  {
    title: 'Business Phone',
    dataIndex: 'phone',
    sorter: (a, b) => a.phone.localeCompare(b.phone),
  },
  {
    title: 'Business Email',
    dataIndex: 'email',
    sorter: (a, b) => a.email.localeCompare(b.email),
  },
  {
    title: 'Business Structure',
    dataIndex: 'structure',
    sorter: (a, b) => a.structure.localeCompare(b.structure),
  },
  // {
  //   title: 'Created At',
  //   dataIndex: 'createdAt',
  //   sorter: (a, b) => sorter(a.createdAt, b.createdAt),
  //   onFilter: (value, record) => record.createdAt && record.createdAt.includes(value),
  // },
  // {
  //   title: 'Updated At',
  //   dataIndex: 'updatedAt',
  //   sorter: (a, b) => sorter(a.updatedAt, b.updatedAt),
  //   onFilter: (value, record) => record.updatedAt && record.updatedAt.includes(value),
  // },
  // {
  //   title: 'Last Updated',
  //   dataIndex: 'lastUpdated',
  //   sorter: (a, b) => a.lastUpdated.localeCompare(b.lastUpdated),
  //   render: (index) => moment(index.lastUpdated).format('MM-DD-YYYY')
  // },
  {
    title: 'Refreshed On',
    dataIndex: 'refreshed_on',
    sorter: (a, b) => a.refreshed_on.localeCompare(b.refreshed_on),
  },
  // {
  //   title: 'License Status Authority',
  //   dataIndex: 'license_status_authority',
  //   sorter: (a, b) => a.license_status_authority.localeCompare(b.license_status_authority),
  // },
  // {
  //   title: 'License Type Authority',
  //   dataIndex: 'license_type_authority',
  //   sorter: (a, b) => a.license_type_authority.localeCompare(b.license_type_authority),
  // },
  // {
  //   title: 'License Category Authority',
  //   dataIndex: 'license_category_authority',
  //   sorter: (a, b) => a.license_category_authority.localeCompare(b.license_category_authority),
  // },
];


export const PURCHAS_ECOLUMN = [
  {
    title: 'License Number',
    dataIndex: 'number',
  },
  {
    title: 'Business Name',
    dataIndex: 'name',
  },
  {
    title: 'Doing Business As',
    dataIndex: 'business_doing_business_as',
  },
  {
    title: 'License Status',
    dataIndex: 'status',
  },
  {
    title: 'License Category',
    dataIndex: 'category',
  },
  {
    title: 'License Type',
    dataIndex: 'type',
  },
  {
    title: 'Medical',
    dataIndex: 'medical',
  },
  {
    title: 'Retail',
    dataIndex: 'retail',
  },
  {
    title: 'License Issue Date',
    dataIndex: 'issue',
  },
  {
    title: 'License Expire Date',
    dataIndex: 'expire',
  },
  {
    title: 'License Owner',
    dataIndex: 'owner',
  },
  {
    title: 'Business Address 1',
    dataIndex: 'address1',
  },
  {
    title: 'Business Address 2',
    dataIndex: 'address2',
  },
  {
    title: 'City',
    dataIndex: 'city',
  },
  {
    title: 'State',
    dataIndex: 'state',
  },
  {
    title: 'Zip Code',
    dataIndex: 'zipcode',
  },
  {
    title: 'County',
    dataIndex: 'county',
  },
  {
    title: 'Country',
    dataIndex: 'country',
  },
  {
    title: 'Business Phone Number',
    dataIndex: 'phone',
  },

  {
    title: 'Business Email',
    dataIndex: 'email',
  },
  {
    title: 'Refreshed On',
    dataIndex: 'refreshed_on',
  },
  // {
  //   title: 'License Status Authority',
  //   dataIndex: 'license_status_authority',
  // },
  // {
  //   title: 'Business Structure',
  //   dataIndex: 'License Type Authority',
  // },
  // {
  //   title: 'Business Structure',
  //   dataIndex: 'license_category_authority',
  // },
  // {
  //   title: 'Business Structure',
  //   dataIndex: 'structure',
  // },
  // {
  //   title: 'Created At',
  //   dataIndex: 'createdAt',
  // },
  // {
  //   title: 'Updated At',
  //   dataIndex: 'updatedAt',
  // },
  // {
  //   title: 'Last Updated',
  //   dataIndex: 'lastUpdated',
  // },
];

export const SAMPLE_DATA_COLUMN = [
  {
    title: 'License Number',
    dataIndex: 'licenseNumber',
  },
  {
    title: 'Business Name',
    dataIndex: 'businessLegalName',
  },
  {
    title: 'Doing Business As',
    dataIndex: 'businessDoingBusinessAs',
  },
  {
    title: 'License Category',
    dataIndex: 'licenseCategory',
  },
  {
    title: 'Business Address 1',
    dataIndex: 'businessAddress1',
  },
  {
    title: 'Business Address 2',
    dataIndex: 'businessAddress2',
  },
  {
    title: 'Business Phone Number',
    dataIndex: 'businessPhoneNumber',
  },
  {
    title: 'Business Email',
    dataIndex: 'businessEmailAddress',
  },

];



