import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from 'moment';

// define a generatePDF function that accepts a tickets argument
export const generatePDF = (data, isForDownloadPdf) => {
    return new Promise(async (resolve) => {
        // initialize jsPDF\
        console.log(data)
        // console.log(name)
        const doc = new jsPDF();
        var startx = 15
        var startY = 15
        // doc.addImage(logo, 50, 50, 300, 70);
        // doc.setTextColor("#00008B");
        doc.text(startx, startY, "Canoja Technologies, LLC");
        doc.text(startx + 165, startY, "Invoice");
        var addressData = [
            ['1000 Satellite Blvd Ste 6-24'],
            ['Suwanee GA 30024'],
            ['Unites States'],
            ['+1 770-569-3751'],
            ['support@canojatech.com'],
            [],
            ['Bill to'],
            [`${data.firstName}, ${data.lastName}`]

        ]
        // var inVoiceData = [
        //     ['Invoice Number', '2F95BE1-0001'],
        //     ['Date of issue', 'Dec 23 , 2020'],
        //     ['Date due', 'Dec 23 , 2020'],
        // ]
        let head = [
            [

                { content: 'Description', colSpan: 1, styles: { halign: 'left', fillColor: [22, 160, 133] } },


                { content: 'Qty', colSpan: 1, styles: { halign: 'center', fillColor: [22, 160, 133] } },

                { content: 'Unit Price', colSpan: 1, styles: { halign: 'left', fillColor: [22, 160, 133] } },


                { content: 'Amount', colSpan: 1, styles: { halign: 'right', fillColor: [22, 160, 133] } },

            ],
        ]

        let tableData = [
            [`${moment(data.submitTimeUTC).format("MMMM DD, YYYY")} - ${moment(data.submitTimeUTC).format("MMMM DD, YYYY")}`],
        ];

        if (data.lines && data.lines && data.lines.data && data.lines.data.length > 0) {
            let lineItems = data.lines.data
            for (let i = 0; i < lineItems.length; i++) {
               let arrLine = [lineItems[i].description,
                { content: lineItems[i].quantity, colSpan: 1, styles: { halign: 'center' } }, `${Number(lineItems[i].amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`, { content: `${Number(lineItems[i].amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`, colSpan: 1, styles: { halign: 'right' } }]
                tableData[i+1] = arrLine  
            }

         let subTotal =   ['', '', 'Subtotal',
            { content: `${Number(data.amount_paid / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`, colSpan: 1, styles: { halign: 'right' } },]
            let amountDue =   ['', '', 'Amount Due',
            { content: `${Number(data.amount_due / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`, colSpan: 1, styles: { halign: 'right' } },]
            tableData[lineItems.length + 1] = subTotal
            tableData[lineItems.length + 2] = amountDue
        }else{
            tableData = [
                [`${moment(data.submitTimeUTC).format("MMMM DD, YYYY")} - ${moment(data.submitTimeUTC).format("MMMM DD, YYYY")}`],
    
                ['Canoja Test',
    
                    { content: '1', colSpan: 1, styles: { halign: 'center' } }, `${Number(data.settleAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`, { content: `${Number(data.settleAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`, colSpan: 1, styles: { halign: 'right' } }],
                ['', '', 'Subtotal',
                    { content: `${Number(data.settleAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`, colSpan: 1, styles: { halign: 'right' } },],
                ['', '', 'Amount Due',
                    { content: `${Number(data.settleAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}`, colSpan: 1, styles: { halign: 'right' } },]
            ];
        }

        doc.autoTable({
            theme: 'plain',
            startX: startx,
            startY: startY + 5,
            styles: { overflow: 'linebreak', cellWidth: 'auto', fontSize: 8, },
            columnStyles: { text: 22, },
            body: addressData
        })
        // doc.setFontType('bold');
        doc.setFontType('normal');
        doc.setFontSize('8');
        doc.text(`Invoice number : ${data.transId}`, startY + 140, startx + 10);

        doc.text(`Date of isssue   : ${moment(data.submitTimeUTC).format("MMMM DD, YYYY")}`, startY + 140, 32);
        doc.text(`Date due           : ${moment(data.submitTimeUTC).format("MMMM DD, YYYY")}`, startY + 140, 39);
        doc.setFontType('bold');
        doc.setFontSize('12');
        doc.text(`${Number(data.settleAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} due ${moment(data.submitTimeUTC).format("MMMM DD, YYYY")}`, 15, 80);
        doc.autoTable({
            theme: 'striped',
            startX: 15,
            startY: 90,
            styles: { overflow: 'linebreak', cellWidth: 'auto' },
            columnStyles: { text: { cellWidth: 'auto', textColor: 2 } },
            head: head,
            body: tableData
        })
        doc.setLineWidth(1);
        doc.line(15, 180, 200, 180)
        doc.setFontType('normal');
        doc.setFontSize('10');
        doc.text(`Pay ${Number(data.settleAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} with card`, 15, 190);
        // doc.text(`Visit ${data.hosted_invoice_url}`, 15, 195);
        doc.text("Questions? Contact Canoja Technologies, LLC at support@canojatech.com or", 15, 210);
        doc.text("Call at +1 770-569-3751", 15, 215);
        let pageCount = doc.internal.getNumberOfPages(); //Total Page Number
        for (let i = 0; i < pageCount; i++) {
            doc.setPage(i);
            let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber; //Current Page
            doc.setFontSize('10');
            doc.text(`${data.number} - Page : ${pageCurrent} of ${pageCount}`, 145, 285);

        }


        let date = moment(new Date()).format("DD/MM/YYYY")


        if (isForDownloadPdf) {
            doc.save(`Invoice_${date}.pdf`);
            resolve(true)
        } else {

            var result = doc.output("arraybuffer");
            resolve(result)
        }
    })


};
