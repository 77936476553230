import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Menu } from "antd";
import { Link, withRouter } from "react-router-dom";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import { getSelectedPlanFeature, getFreePlanFeatures } from "../../service/SubscriptionService";
import { setSubscriptionChange } from 'appRedux/actions/DataTable';
import "./HorizontalNav.css"

const SubMenu = Menu.SubMenu;

const HorizontalNav = (props) => {
  const [features, setFeatures] = useState(null)
  const dispatch = useDispatch();
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const pathname = useSelector(({ settings }) => settings.pathname);
  const [aboutPageUrl, setAboutPageUrl] = useState('https://canojatech.com/about-us');
  const isChangeSubscription = useSelector(({ table }) => table.isChangeSubscription);
  const getNavStyleSubMenuClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-below-submenu-popup-curve";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-menu-horizontal gx-submenu-popup-curve gx-above-submenu-popup-curve";
      default:
        return "gx-menu-horizontal";

    }
  };

  const user_token = window.sessionStorage.getItem('user_token')
  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  const subscriptionId = window.sessionStorage.getItem('subscriptionId')

  useEffect(() => {
    if (isChangeSubscription === true) {
      dispatch(setSubscriptionChange(false));
    }

    const getSubscriptionId = () => {
      let subscriptionId = window.sessionStorage.getItem('subscriptionId')
      if (subscriptionId) {
        getFeatures(subscriptionId)
      } else {
        getFreePlan()
      }
    }

    const getFeatures = async (subscriptionId) => {
      let data = await getSelectedPlanFeature(subscriptionId)
      if (data === false) {
        console.log('error')
      } else {
        setFeatures(data[0])
      }
    }

    const getFreePlan = async () => {
      let data = await getFreePlanFeatures()
      if (data === false) {
        console.log('error')
      } else {
        setFeatures(data[0])
      }
    }

    getSubscriptionId()
  }, [isChangeSubscription, dispatch, subscriptionId]);


  useEffect(() => {
    let env = localStorage.getItem('envSetting')
    if (env) {
      env = JSON.parse(env)
      if (env) {

        setAboutPageUrl(env['REACT_APP_ABOUT_PAGE_URL'])
      }
    }
  }, [])


  return (

    <Menu
      defaultOpenKeys={[defaultOpenKeys]}
      selectedKeys={[selectedKeys]}
      mode="horizontal">

      <Menu.Item className={getNavStyleSubMenuClass(navStyle)} key="home" title='Home'>
        <Link to="/home">DASHBOARD</Link>
      </Menu.Item>

      <SubMenu className={`${getNavStyleSubMenuClass(navStyle)} horizontal-dropdown`} key="license-search" title='LICENSE SEARCH'>

        <Menu.Item key="license-search/number" className={features && features['isAllowLicenseNumberSearch'] === 'true' ? '' : 'premium-option'} >
          {features && features['isAllowLicenseNumberSearch'] === 'true' ?
            <Link to="/license-search/number" >License Number</Link>
            :
            <span className='premium-option'>License Number</span>
          }
        </Menu.Item>



        <Menu.Item key="license-search/category" className={features && features['isAllowLicenseCategorySearch'] === 'true' ? '' : 'premium-option'}>
          {features && features['isAllowLicenseCategorySearch'] === 'true' ?
            <Link to="/license-search/category">License Category</Link>
            :
            <span>License Category</span>
          }
        </Menu.Item>


        <Menu.Item key="license-search/name" className={features && features['isAllowNameSearch'] === 'true' ? '' : 'premium-option'}>
          {features && features['isAllowNameSearch'] === 'true' ?
            <Link to="/license-search/name">Business Name</Link>
            :
            <span>Business Name (Premium)</span>
          }
        </Menu.Item>



        <Menu.Item key="license-search/city" className={features && features['isAllowCitySearch'] === 'true' ? '' : 'premium-option'}>
          {features && features['isAllowCitySearch'] === 'true' ?
            <Link to="/license-search/city">Business City</Link>
            :
            <span>Business City (Premium)</span>
          }
        </Menu.Item>


        <Menu.Item key="license-search/state" className={features && features['isAllowStateSearch'] === 'true' ? '' : 'premium-option'}>
          {features && features['isAllowStateSearch'] === 'true' ?
            <Link to="/license-search/state">Business State</Link>
            :
            <span>Business State (Premium)</span>
          }
        </Menu.Item>


        <Menu.Item key="license-search/country" className={features && features['isAllowCountrySearch'] === 'true' ? '' : 'premium-option'}>
          {features && features['isAllowCountrySearch'] === 'true' ?
            <Link to="/license-search/country">Business Country</Link>
            :
            <span>Business Country (Premium)</span>
          }
        </Menu.Item>


        <Menu.Item key="license-search/type" className={features && features['isAllowLicenseTypeSearch'] === 'true' ? '' : 'premium-option'}>
          {features && features['isAllowLicenseTypeSearch'] === 'true' ?
            <Link to="/license-search/type">License Type</Link>
            :
            <span>License Type (Premium)</span>
          }
        </Menu.Item>

        <Menu.Item key="license-search/status" className={features && features['isAllowLicenseStatusSearch'] === 'true' ? '' : 'premium-option'}>
          {features && features['isAllowLicenseStatusSearch'] === 'true' ?
            <Link to="/license-search/status">License Status</Link>
            :
            <span >License Status (Premium)</span>
          }
        </Menu.Item>

        <Menu.Item key="license-search/owner" className={features && features['isAllowLicenseOwnerSearch'] === 'true' ? '' : 'premium-option'}>
          {features && features['isAllowLicenseOwnerSearch'] === 'true' ?
            <Link to="/license-search/owner">License Owner</Link>
            :
            <span>License Owner (Premium)</span>
          }
        </Menu.Item>

        <Menu.Item key="license-search/issuedate" className={features && features['isAllowLicenseIssueDateSearch'] === 'true' ? '' : 'premium-option'}>
          {features && features['isAllowLicenseIssueDateSearch'] === 'true' ?
            <Link to="/license-search/issuedate">License Issue Date</Link>
            :
            <span>License Issue Date (Premium)</span>
          }
        </Menu.Item>

        <Menu.Item key="license-search/expiredate" className={features && features['isAllowLicenseExpireDateSearch'] === 'true' ? '' : 'premium-option'}>
          {features && features['isAllowLicenseExpireDateSearch'] === 'true' ?
            <Link to="/license-search/expiredate">License Expire Date</Link>
            :
            <span>License Expire Date (Premium)</span>
          }
        </Menu.Item>

        <Menu.Item key="license-search/dba" className={features && features['isAllowDBASearch'] === 'true' ? '' : 'premium-option'}>
          {features && features['isAllowDBASearch'] === 'true' ?
            <Link to="/license-search/dba">Business DBA</Link>
            :
            <span>Business DBA (Premium)</span>
          }
        </Menu.Item>

        <Menu.Item key="license-search/county" className={features && features['isAllowCountySearch'] === 'true' ? '' : 'premium-option'}>
          {features && features['isAllowCountySearch'] === 'true' ?
            <Link to="/license-search/county">Business County</Link>
            :
            <span>Business County (Premium)</span>
          }
        </Menu.Item>

        <Menu.Item key="license-search/zipcode" className={features && features['isAllowPostalCodeSearch'] === 'true' ? '' : 'premium-option'}>
          {features && features['isAllowPostalCodeSearch'] === 'true' ?
            <Link to="/license-search/zipcode" >Business Zip Code</Link>
            :
            <span>Business Zip Code (Premium)</span>
          }
        </Menu.Item>

        <Menu.Item key="license-search/phonenumber" className={features && features['isAllowPhoneNumberSearch'] === 'true' ? '' : 'premium-option'}>
          {features && features['isAllowPhoneNumberSearch'] === 'true' ?
            <Link to="/license-search/phonenumber">Business Phone Number</Link>
            :
            <span>Business Phone Number (Premium)</span>
          }
        </Menu.Item>

        <Menu.Item key="license-search/email" className={features && features['isAllowEmailSearch'] === 'true' ? '' : 'premium-option'}>
          {features && features['isAllowEmailSearch'] === 'true' ?
            <Link to="/license-search/email">Business Email</Link>
            :
            <span>Business Email (Premium)</span>
          }
        </Menu.Item>
        {/* <Menu.Item key="license-search/lastUpdatedDate" className={features && features['isAllowLastUpdatedDateSearch'] === 'true' ? '' : 'premium-option'}>
          {features && features['isAllowLastUpdatedDateSearch'] === 'true' ?
            <Link to="/license-search/lastupadted">Last Updated Date</Link>
            :
            <span>Last Updated Date (Premium)</span>
          }
        </Menu.Item> */}


      </SubMenu>
      {/* 
      <Menu.Item className={getNavStyleSubMenuClass(navStyle)} key="customequery" title='Custome Query'>
        <Link to="/customquery">QUERY WORKBENCH</Link>
      </Menu.Item> */}

      <Menu.Item className={getNavStyleSubMenuClass(navStyle)} key="customquerybuilder" title='Custome Query Builder'>
        <Link to="/customquerybuilder">QUERY WORKBENCH</Link>
      </Menu.Item>

      {user_token !== null &&
        <Menu.Item className={getNavStyleSubMenuClass(navStyle)} key="reports" title='Report'>
          <Link to="/report">REPORTS</Link>
        </Menu.Item>
      }

      <Menu.Item className={getNavStyleSubMenuClass(navStyle)} key="subscription" title='Subscriptions'>
        <Link to="/subscriptions">SUBSCRIPTIONS</Link>
      </Menu.Item>

      <Menu.Item className={getNavStyleSubMenuClass(navStyle)} key="about" title='About'>
        <a href={aboutPageUrl} target='blank'>ABOUT</a>
      </Menu.Item>
    </Menu>

  );
};

HorizontalNav.propTypes = {};

export default withRouter(HorizontalNav);

