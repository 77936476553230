import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Col, Spin, Row, Icon } from "antd";
import { useHistory, withRouter } from "react-router-dom";
import './UserDiscountModal.css'

const UserDiscountModal = (props) => {
    const [showDetailsModal, setShowDetailsModal] = useState(true)
    const [showSecondModal, setShowSecondModal] = useState(false)
    const [popupData, setPopupData] = useState({})
    const history = useHistory()

    useEffect(() => {
        async function fetchData() {
            let generalData = localStorage.getItem('generalSettings')
            if (generalData) {
                generalData = JSON.parse(generalData)
                if (generalData) {
                    setPopupData(generalData)
                }
            }
        }

        fetchData()
    }, [])


    const handleSubmit = async (e) => {
        e.preventDefault();
        setShowDetailsModal(false)
        sessionStorage.setItem('isDiscountIsApplied', 'true')
        history.push('subscriptions')
    }

    const handlePopupClose = () => {
        props.handleCloseModal()
    }

    return (
        <div className="purchase-page">

            <Modal
                title="Vertically centered modal dialog"
                centered
                visible={showDetailsModal}
                onOk={(e) => handleSubmit(e)}
            >
                <div className='card-number-popup-content2'>
                    <div className='closeButonWrapper'>
                        <Button className='closeButton' onClick={(e) => handlePopupClose(e)} ><i className="icon icon-close" /></Button>
                    </div>
                    <div className='report-modal-content'>
                        <p className='confirm-popup-img'> <img alt="" src={require("assets/images/CanojaLeaf.png")} /></p>
                    </div>
                    <div className='report-modal-content card-number-popup-text confirm-popup-text'>
                        <Row>
                            <Col sm={24} xs={24} xl={16}>

                                <Form className="left-side-form" layout="vertical" >
                                <div className='card-popup-title'>
                                        <p dangerouslySetInnerHTML={{ __html: popupData['discountModalTitle'] }} />
                                    </div>
                                    <div className='card-popup-message'>
                                        <p dangerouslySetInnerHTML={{ __html: popupData['dashboardModalMainMessage'] }} />
                                    </div>
                                    <div className='cardDiscountMessage'>
                                        <p dangerouslySetInnerHTML={{ __html: popupData['dashboardDiscountMessage'] }} />
                                    </div>
                                    {/* <div className='card-popup'>
                                            <p dangerouslySetInnerHTML={{ __html: popupData['dashboardDiscountMessage'] }} />
                                        </div> */}
                                    <br />

                                    <div className='input-name' >
                                        <div className='discountModal'>
                                            {popupData['dashboardDiscountCode']}
                                        </div>
                                    </div>
                                    <div className='discountModalSubmitButton'>
                                        <Button className='submitButton' onClick={(e) => handleSubmit(e)} type="primary">Subscribe Now</Button>
                                    </div>
                                    {/* <div className='card-popup'>
                                            <p dangerouslySetInnerHTML={{ __html: popupData['dashboardFooterMessage'] }} />
                                        </div> */}


                                </Form>

                            </Col>
                        </Row>
                    </div>
                </div>

            </Modal>

            <Modal
                title="Vertically centered modal dialog"
                centered
                maskClosable={true}
                onCancel={() => handlePopupClose()}
                visible={showSecondModal}
            >
                <div className='subscription-purchase'>
                    <div className='subscription-purchase-icon-wrapper'>
                        <p><Icon className='subscription-purchase-icon' style={{ fontSize: '16px' }} type="exclamation-circle" /></p>
                    </div>
                    <div className='subscription-purchase-content'>
                        <p dangerouslySetInnerHTML={{ __html: popupData['dashboardSuccessMessage'] }} />
                    </div>
                </div>
                <div className='modal-button'>
                    <Button onClick={() => handlePopupClose()} type="primary">Ok</Button>
                </div>
            </Modal>
        </div >
    );
}

const UserDiscountModalPage = Form.create()(UserDiscountModal);

const userDiscountModalElement = (props) => (

    <UserDiscountModalPage {...props} />

);

export default withRouter(userDiscountModalElement);