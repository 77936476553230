import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Form, Input, message, Icon, Row, Col, Spin } from "antd";
import { useDispatch } from 'react-redux'
import { API, graphqlOperation } from "aws-amplify";
import { getUserByEmail, listSubscriptionPlans, searchEnvVariables } from "../../graphql/queries.js";
import { checkIsBasicPlan } from '../../service/SubscriptionService';
import { withRouter } from "react-router-dom";
import './index.css'
import Widget from "../../components/Widget";
import RouteLeavingGuard from "../../components/RouteLeavingGuard/RouteLeavingGuard.js";
import { usePaymentInputs, PaymentInputsWrapper } from 'react-payment-inputs';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { checkSubscriptionStatus, getEnvByName } from "../../service/GeneralService.js";
import images from 'react-payment-inputs/images'
import { css } from 'styled-components';
import moment from 'moment';


const FormItem = Form.Item;


const Checkout = (props) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [interval, setInterval] = useState([]);
    const [selectedPlanType, setSelectedPlanType] = useState([]);
    const [discount, setDiscount] = useState(0);
    const [oldDiscount, setOldDiscount] = useState(0);
    const [discountInPercentage, setDiscountInPercentage] = useState(0)
    const [code, setCode] = useState(null);
    const [subscriptionTerm, setSubscriptionTerm] = useState('Monthly');
    const [cost, setCost] = useState(0);
    const [priceId, setPriceId] = useState(null);
    const [totalCost, setTotalCost] = useState(null);
    const [isBasic, setIsBasic] = useState(false);
    const [customerEmail, setCustomerEmail] = useState(null);
    const [loadingText, setLoadingText] = useState('Loading...')
    const [customerId, setCustomerId] = useState(null);
    const [subscriptionId, setSubscriptionID] = useState();
    const [obj, setObj] = useState('')
    const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false)
    const [apiKey, setApiKey] = useState('')
    const [authCustomerId, setAuthCustomerId] = useState('')
    const [isShowPrompt, setIsShowPrompt] = useState(true);
    const [isAdrsErr, setIsAdrsErr] = useState(false)
    const [isCardErr, setIsCardErr] = useState(false)
    const [cardNumber, setCardNumber] = useState(null)
    const [alertMessage, setAlertMessage] = useState('Something bad happen on server');
    const [subscriptionInterruption, setSubscriptionInterruption] = useState('changeing page, your data will be removed');
    const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps, wrapperProps } = usePaymentInputs();
    const [subscriptionDetails, setSubscriptionDetails] = useState({})
    const [planData, setPlanData] = useState([])
    const [isShowCardError, setIsShowCardError] = useState(true)
    const [isHaveCardDetail, setIsHaveCardDetail] = useState(true)
    let card = ['Visa', 'Mastercard', 'American Express', 'JCB', 'Discover', 'Diners Club']
    //Diners Club ,JCB ,Discover

    useEffect(() => {
        async function fetchPlan() {

            setLoading(true)
            let data = localStorage.getItem('alertSettings')
            if (data) {
                data = JSON.parse(data)
                if (data) {
                    setSubscriptionInterruption(data.subscriptionInterruption)
                }
                if (data.genericAlert) {
                    setAlertMessage(data.genericAlert)
                }
            }
            let subscriptionDetails = {}
            if (props.location.state && props.location.state.data) {
                subscriptionDetails = props.location.state.data
            }
            if (subscriptionDetails) {
                let response = await API.graphql(graphqlOperation(listSubscriptionPlans, {}))
                if (response.errors) {
                    message.error(<p dangerouslySetInnerHTML={{ __html: alertMessage }} />)
                } else {
                    response = response.data.listSubscriptionPlans
                    if (response && response.length > 0) {
                        response = response.filter(item => item.active === 'true')
                        let result = await response.filter(item => item.planType === subscriptionDetails.planType)
                        if (result && result.length > 0) {
                            setSelectedPlanType(subscriptionDetails.planType)
                            let subscriptionTerm = 'Monthly'
                            let cost = result[0].amountPerMonth
                            let priceId = result[0].priceIdMonth

                            if (subscriptionDetails.interval === 'year') {
                                subscriptionTerm = 'Annual'
                                cost = result[0].amountPerYear
                                priceId = result[0].priceIdYear
                            }
                            subscriptionDetails.priceId = priceId

                            setSubscriptionTerm(subscriptionTerm)
                            setPriceId(priceId)
                            setCost(cost)
                            setInterval(subscriptionDetails.interval)
                            setPlanData(result)
                        }
                        if (subscriptionDetails.discount && subscriptionDetails.discount !== 0) {
                            setCode(subscriptionDetails.promotionCodes)
                            setDiscountInPercentage(subscriptionDetails.discountInPercentage)
                            setDiscount(subscriptionDetails.discount)
                            setTotalCost(subscriptionDetails.totalCost)
                        } else {
                            subscriptionDetails = {
                                ...subscriptionDetails,
                                discount: 0,
                                discountInPercentage: 0,

                            }
                        }

                        setSubscriptionDetails(subscriptionDetails)
                    }
                }
            }
        }

        async function setSubscribedPlanData() {
            var customerId = window.sessionStorage.getItem('customerId')
            if (customerId) {
                setCustomerId(customerId)
                var res = await API.get('PaymentGatewayAPI', `/paymentgateway/customers/${customerId}`,)
                if (res.success) {
                    if (res.customer && res.customer.profile && res.customer.profile.paymentProfiles && res.customer.profile.paymentProfiles[0]) {
                        setObj(res.customer.profile.paymentProfiles[0].billTo)
                        setIsHaveCardDetail(true)
                    } else {
                        setIsHaveCardDetail(false)
                    }
                    if (res.customer && res.customer.profile && res.customer.profile.email) {
                        let customer = {
                            email: res.customer.profile.email
                        }
                        await API.graphql(graphqlOperation(getUserByEmail, customer))
                            .then((result) => {
                                if (result) {
                                    let discount = result.data && result.data.getUserByEmail && result.data.getUserByEmail.discount
                                    if (discount) {
                                        discount = Number(discount)
                                        if (discount > 0) {
                                            setOldDiscount(discount)
                                        }
                                    }
                                }
                            })
                            .catch(err => {
                                console.log(err)
                            })
                        setCustomerEmail(res.customer.profile.email)
                    }
                    res = res.customer
                } else {
                    res = null
                }
                if (res) {
                    if (res.subscriptionIds && res.subscriptionIds.subscriptionId.length > 0) {
                        let subscriptionDetails = {}
                        if (props.location.state && props.location.state.data) {
                            subscriptionDetails = props.location.state.data
                        }
                        let isValidSubscriptionStatus = await checkSubscriptionStatus(subscriptionDetails.subscriptionStatus)
                        if (isValidSubscriptionStatus === true) {
                            setSubscriptionID(res.subscriptionIds.subscriptionId[0])
                            getCardDetails(res.subscriptionIds.subscriptionId[0])
                            window.sessionStorage.setItem('subscriptionId', res.subscriptionIds.subscriptionId[0])
                        } else {
                            setIsSubscriptionExpired(true)
                        }
                    } else {
                        let data = await checkIsBasicPlan(customerId)
                        if (data !== false && data && data.planType) {
                            setIsBasic(true)
                            if (res.profile && res.profile.paymentProfiles && res.profile.paymentProfiles[0]) {
                                let sub = {
                                    profile: {
                                        paymentProfile: res.profile.paymentProfiles[0]
                                    }
                                }
                                getCardDetails(sub, true)
                            }
                            setSubscriptionID('BASIC')
                        }
                    }
                }
            }
            setLoading(false)
        }
        function setCardData() {
            if (props.location.state && props.location.state.cardDetails) {
                delete props.location.state.cardDetails["cardNumber"];
                delete props.location.state.cardDetails["cardExpiryDate"];
                delete props.location.state.cardDetails["cardCVV"];
                setObj(props.location.state.cardDetails)

            }
        }

        fetchPlan();
        setSubscribedPlanData()
        setCardData()
    }, [])


    useEffect(() => {
        async function fetchEnv() {
            let envString = `'REACT_APP_GOOGLE_ADDRESS_API_KEY'`
            let envList = await getEnvByName(envString)
            if (envList !== false) {
                if (envList['REACT_APP_GOOGLE_ADDRESS_API_KEY']) {
                    setApiKey(envList['REACT_APP_GOOGLE_ADDRESS_API_KEY'])
                }
            }
        }
        async function fetchCustomerId() {
            let envString = `'REACT_APP_AUTHORIZE_NET_ANS_CUSTOMER_ID'`
            let envList = await getEnvByName(envString)
            if (envList !== false) {
                if (envList['REACT_APP_AUTHORIZE_NET_ANS_CUSTOMER_ID']) {
                    setAuthCustomerId(envList['REACT_APP_AUTHORIZE_NET_ANS_CUSTOMER_ID'])
                }
            }
        }
        fetchEnv()
        fetchCustomerId()
    }, [])


    const getCardDetails = async (id, isSub) => {
        if (isSub) {
            let subscription = id
            if (subscription) {
                let isValidSubscriptionStatus = await checkSubscriptionStatus(subscription.status)
                if (isValidSubscriptionStatus === true) {
                    if (subscription.profile && subscription.profile.paymentProfile && subscription.profile.paymentProfile) {
                        let cardNumber = subscription.profile.paymentProfile.payment && subscription.profile.paymentProfile.payment.creditCard && subscription.profile.paymentProfile.payment.creditCard.cardNumber
                        setCardNumber(cardNumber)
                    }
                }
            }
        } else if (id) {
            let result = await API.get('PaymentGatewayAPI', `/paymentgateway/subscription/${id}`)
            if (result.success) {
                let subscription = result.subscription.subscription
                if (subscription) {
                    let isValidSubscriptionStatus = await checkSubscriptionStatus(subscription.status)
                    if (isValidSubscriptionStatus === true) {
                        if (subscription.profile && subscription.profile.paymentProfile && subscription.profile.paymentProfile) {
                            let cardNumber = subscription.profile.paymentProfile.payment && subscription.profile.paymentProfile.payment.creditCard && subscription.profile.paymentProfile.payment.creditCard.cardNumber
                            setCardNumber(cardNumber)
                        }
                    }
                }
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedPlanType !== 'BASIC') {
            if (obj && obj.address && obj.address !== '') {
                setIsAdrsErr(false)
            }
            else {
                setLoading(false)
                setIsAdrsErr(true)
            }
            let isShowCardError = true
            if (meta && meta.cardType && meta.cardType.displayName) {
                let matchingIndex = card.findIndex((item) => item === meta.cardType.displayName)
                if (matchingIndex > -1) {
                    isShowCardError = false
                }
            }
            if (isShowCardError) {
                setIsCardErr(true)
            } else {
                setIsCardErr(false)
            }

            if ((!isShowCardError || subscriptionId) && !isAdrsErr) {
                props.form.validateFields((err, values) => {
                    if ((!err) || (subscriptionId && !err.email)) {
                        setLoading(true)
                        setIsShowPrompt(false)
                        setTimeout(async () => {
                            props.history.push({
                                pathname: '/checkoutdetails',
                                state: {
                                    cardData: { ...values, ...obj },
                                    subscriptionId: subscriptionId,
                                    SubscriptionCost: {
                                        ...subscriptionDetails, interval: interval,
                                        cost: cost,
                                        oldDiscount: oldDiscount,
                                        discount: discount,
                                        totalCost: totalCost,
                                        planType: selectedPlanType,
                                        priceId: priceId
                                    },
                                    setIsHaveCardDetail: isHaveCardDetail,
                                    isBasic: isBasic,
                                    planData: planData,
                                    checkoutPageProps: props && props.location && props.location.state && props.location.state.data
                                }
                            })
                        }, 2000)

                    }
                });
            }
        } else {
            setLoading(true)
            setIsShowPrompt(false)
            setTimeout(async () => {
                props.history.push({
                    pathname: '/checkoutdetails',
                    state: {
                        subscriptionId: subscriptionId,
                        SubscriptionCost: {
                            ...subscriptionDetails, interval: interval,
                            cost: cost,
                            oldDiscount: oldDiscount,
                            discount: discount,
                            totalCost: totalCost,
                            planType: selectedPlanType,
                            priceId: priceId
                        },
                        isBasic: isBasic,
                        planData: planData,
                        checkoutPageProps: props && props.location && props.location.state && props.location.state.data
                    }
                })
            }, 2000)
        }

    }


    const handleChange = (name, e) => {
        let value = e.target.value
        let data = {
            ...obj,
            [name]: value
        }
        setObj(data)
        if (name === 'cardNumber') {
            setIsShowCardError(false)
        }
    }

    const handleAddressChange = (e) => {
        let value = e.value.place_id
        setIsAdrsErr(false)
        getData(value, e)
    }

    const getData = async (value, e) => {
        if (value) {
            let placeInfo = obj ? obj : {}
            placeInfo.address = e.label
            placeInfo.country = null
            placeInfo.postal_code = null
            placeInfo.locality = null
            placeInfo.administrative_area_level_1 = null
            setObj(placeInfo)

            let place_Id = value
            let body = {
                placeId: place_Id
            }
            props.form.resetFields()
            setLoading(true)
            let res = await API.post('CustomerRestAPI', `/customers/getPlaceDetails`, { body })
            if (res.success) {
                let data = res.place
                setObj({ ...obj, ...data, })
                setLoading(false)
            } else {
                setLoading(false)
                console.error(res.error)
                message.error('something bad happen')
            }
        }
    }

    // const goBack = () => {
    //     props.history.push('/subscription')
    // }


    const { getFieldDecorator } = props.form;

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    const formItemLayout2 = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };


    let promptParams = {
        messageText: subscriptionInterruption,
        errorMessage: alertMessage,
        data: {
            email: window.sessionStorage.getItem("email"),
            customerId: customerId
        },
    };

    return (
        <>
            <RouteLeavingGuard
                when={(subscriptionId || isSubscriptionExpired) ? false : isShowPrompt}
                navigate={path => props.history.push(path)}
                promptParams={promptParams}
                shouldBlockNavigation={location => {
                    if ((subscriptionId || isSubscriptionExpired) ? false : isShowPrompt) {
                        return true
                    }
                    return false
                }}
            />
            <div className="purchase-page">
                <Spin tip={loadingText} spinning={loading}>
                    <Card className="gx-card" >
                        <Row>
                            <Col sm={24} xs={24} xl={12}>
                                <Widget styleName="gx-card-profile-sm card-title-subscription">
                                    {/* <Button type="link" className='buttonAsLink' onClick={() => goBack()}>
                                        <Icon type="arrow-left" />

                                        <span className='left-side-title'>CanojaVerify Subscription</span>
                                    </Button> */}

                                    <Form className="left-side-form">
                                        <div><b>Subscription Cost Breakdown</b></div>
                                        <FormItem
                                            {...formItemLayout2}
                                            style={{ marginBottom: '0px' }}
                                            label="Term"
                                        >

                                            {cost ? subscriptionTerm : Number('0').toLocaleString('en-US', { style: 'currency', currency: 'USD' })}

                                        </FormItem>
                                        <FormItem
                                            {...formItemLayout2}
                                            style={{ marginBottom: '0px' }}
                                            label="Cost"
                                        >

                                            {cost ? Number(cost).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' per ' + interval : Number('0').toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' per ' + interval}

                                        </FormItem>
                                        <FormItem
                                            {...formItemLayout2}
                                            style={{ marginBottom: '0px' }}
                                            label="Existing Discount"
                                        >
                                            {oldDiscount ? Number(oldDiscount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' per ' + interval : Number('0').toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' per ' + interval}
                                        </FormItem>
                                        <FormItem
                                            {...formItemLayout2}
                                            style={{ marginBottom: '0px' }}
                                            label="Discount"
                                        >

                                            {discount ? `${Number(discount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} ( ${discountInPercentage.toFixed(2)} % ) per ${interval}` : `${Number('0').toLocaleString('en-US', { style: 'currency', currency: 'USD' })} ( ${discountInPercentage.toFixed(2)} % ) per ${interval}`}

                                        </FormItem>
                                        <hr className='cost-horizontal-line' />

                                        <FormItem
                                            {...formItemLayout2}
                                            style={{ marginBottom: '0px' }}
                                            label="Total"
                                        >

                                            {totalCost ? (totalCost ? (Number(totalCost) - Number(oldDiscount)).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' per ' + interval : '') : (cost ? (Number(cost) - Number(oldDiscount)).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' per ' + interval : Number('0').toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' per ' + interval)}

                                        </FormItem>
                                    </Form>
                                </Widget>
                            </Col>
                            <Col sm={24} xs={24} xl={12}>
                                <Widget styleName="gx-card-profile-sm subscriptionPage card-title-subscription">
                                    <span className='left-side-title'>Pay with card</span>

                                    {/* <div style={{ color: 'red' }}>Note <span style={{ color: 'black' }}>:- We allow Mastercard,Visa only !</span></div> */}
                                    <Form className="left-side-form" layout="vertical" onSubmit={(e) => handleSubmit(e)} >
                                        <FormItem
                                            {...formItemLayout}
                                            label="Email"
                                        >
                                            {getFieldDecorator('email', {
                                                initialValue: customerEmail,
                                            })(
                                                <Input disabled={true} placeholder="Email Address" name='email' />
                                            )}
                                        </FormItem>

                                        {isHaveCardDetail &&
                                            <FormItem
                                                {...formItemLayout}
                                                label="Card Number"
                                            >
                                                {getFieldDecorator('cardNumber', {
                                                    initialValue: cardNumber,
                                                })(
                                                    <Input disabled={true} placeholder="Card Number" name='cardNumber' />
                                                )}
                                            </FormItem>}


                                        {!isHaveCardDetail &&
                                            <>
                                                <FormItem
                                                    {...formItemLayout}
                                                    className={isShowCardError ? ' reduce-margin ant-form-explain2' : 'card-error-handle reduce-margin ant-form-explain2'}
                                                    style={{ marginBottom: '5px' }}
                                                    label="Card Number"
                                                >
                                                    {getFieldDecorator('cardNumber', {
                                                        initialValue: obj.cardNumber,
                                                        rules: [{ required: true, message: 'Please input your card Number' }],
                                                    })(

                                                        <PaymentInputsWrapper {...wrapperProps} styles={{
                                                            fieldWrapper: {
                                                                base: css`
                                                                  width: 100%;
                                                                `
                                                            },
                                                            inputWrapper: {
                                                                base: css`
                                                                  border: none;
                                                                `,
                                                                errored: css`
                                                                  border-color: red;
                                                                `,
                                                                focused: css`
                                                                  border-color: unset;
                                                                  box-shadow: unset;
                                                                  outline: 2px solid green;
                                                                  outline-offset: 2px;
                                                                `
                                                            },
                                                        }} >
                                                            <svg {...getCardImageProps({ images })} setFieldsValue={obj.cardNumber} />
                                                            <input {...getCardNumberProps({ placeholder: 'Card Number', onChange: (e) => handleChange('cardNumber', e) })} value={obj.cardNumber} />
                                                        </PaymentInputsWrapper>

                                                        // <input  className="card-elements" {...getCardNumberProps({ onChange: (e) => handleChange('cardNumber', e) })} setFieldsValue={obj.cardNumber} />
                                                    )}
                                                </FormItem>

                                                {isCardErr ? <div style={{
                                                    color: "red", marginLeft: '18px'
                                                }}>
                                                    please input valid card details
                                                </div> : ''}
                                                <FormItem
                                                    {...formItemLayout}
                                                >
                                                    <div className='field2'>
                                                        <FormItem
                                                            className={'flex-view-cvv-payment'}
                                                        >
                                                            {getFieldDecorator('cardExpiryDate', {
                                                                initialValue: obj.cardExpiryDate,
                                                                rules: [{ required: true, message: 'Please input your card expiry date' }],
                                                            })(
                                                                <input className="card-elements" {...getExpiryDateProps({ onChange: (e) => handleChange('cardExpiryDate', e) })} value={obj.cardExpiryDate} />
                                                            )}

                                                        </FormItem>
                                                        <FormItem
                                                            className={'flex-view-cvv-payment'}
                                                        >
                                                            {getFieldDecorator('cardCVV', {
                                                                initialValue: obj.cardCVV,
                                                                rules: [{ required: true, message: 'Please input your card cvv' }],
                                                            })(
                                                                <input className="card-elements" {...getCVCProps({ onChange: (e) => handleChange('cardCVV', e) })} value={obj.cardCVV} />
                                                            )}
                                                        </FormItem>
                                                    </div>
                                                    <div className='image-seal'>

                                                        <img className='card-image' alt="valid-card" src={require("assets/images/discover.png")} />

                                                        {authCustomerId &&
                                                            // <div className='seal-logo'>
                                                            <a rel="noopener noreferrer" href={`https://verify.authorize.net/anetseal/?pid=${authCustomerId}&rurl=https://demo.canojaverify.com`} target="_blank" ><img width="55" height="42" alt="example" src={"https://verify.authorize.net/anetseal/images/secure90x72.gif"} alt="Authorize-net-seal" /></a>
                                                            // </div>
                                                        }
                                                    </div>
                                                </FormItem>

                                                <div className='input-name' >
                                                    <FormItem
                                                        {...formItemLayout}
                                                        label="Card Holder First Name"
                                                    >

                                                        {getFieldDecorator('cardHolderFirstName', {
                                                            initialValue: obj.cardHolderFirstName,
                                                            rules: [{ required: true, message: 'Please input card holder first name' }],
                                                        })(
                                                            <Input placeholder="Card Holder First Name" onChange={e => handleChange('cardHolderFirstName', e)} name='cardHolderFirstName' setFieldsValue={obj.cardHolderFirstName} />
                                                        )}


                                                    </FormItem>
                                                    <FormItem
                                                        {...formItemLayout}
                                                        label="Card Holder Last Name"
                                                    >

                                                        {getFieldDecorator('cardHolderLastName', {
                                                            initialValue: obj.cardHolderLastName,
                                                            rules: [{ required: true, message: 'Please input card holder last name' }],
                                                        })(
                                                            <Input placeholder="Card Holder Last Name" onChange={e => handleChange('cardHolderLastName', e)} name='cardHolderLastName' setFieldsValue={obj.cardHolderLastName} />
                                                        )}
                                                    </FormItem>
                                                </div>

                                                <FormItem
                                                    {...formItemLayout}
                                                    label="Phone Number"
                                                >
                                                    {getFieldDecorator('phoneNo', {
                                                        initialValue: obj.phoneNo,
                                                        rules: [{
                                                            required: true, message: 'Please input phone number',
                                                            pattern: /[+0-9 -]+$/,
                                                        }],
                                                    })(
                                                        <Input placeholder='Phone Number' name="phoneNo" onChange={e => handleChange('phoneNo', e)} setFieldsValue={obj.phoneNo} />
                                                    )}
                                                </FormItem>
                                                {apiKey &&
                                                    <FormItem
                                                        {...formItemLayout}
                                                        label="Address"
                                                    >
                                                        {getFieldDecorator('address', {
                                                            initialValue: obj.address,
                                                            rules: [{
                                                                required: true, message: 'Please input address',
                                                            }],
                                                        })(
                                                            <GooglePlacesAutocomplete
                                                                apiKey={apiKey}
                                                                selectProps={{
                                                                    placeholder: 'Type Address...',
                                                                    obj,
                                                                    onChange: handleAddressChange,
                                                                    defaultInputValue: obj.address
                                                                }}

                                                            />
                                                        )}
                                                    </FormItem>}
                                                {/* {isAdrsErr ? <div style={{
                                                    color: "red", marginLeft: '18px',
                                                    marginTop: '-11px'
                                                }}>
                                                    please input address
                                                </div> : ''} */}
                                                < FormItem
                                                    {...formItemLayout}
                                                    label="City"
                                                >
                                                    {getFieldDecorator('City', {
                                                        initialValue: obj.locality,
                                                        rules: [{
                                                            required: true, message: 'Please input city',
                                                        }],
                                                    })(
                                                        <Input placeholder='City' name="city" onChange={e => handleChange('locality', e)} setFieldsValue={obj.locality} />
                                                    )}
                                                </FormItem>

                                                <FormItem
                                                    {...formItemLayout}
                                                    label="State/Province"
                                                >
                                                    {getFieldDecorator('State', {
                                                        initialValue: obj.administrative_area_level_1,
                                                        rules: [{
                                                            required: true, message: 'Please input state',
                                                        }],
                                                    })(
                                                        <Input placeholder='State' name="state" onChange={e => handleChange('administrative_area_level_1', e)} setFieldsValue={obj.administrative_area_level_1} />
                                                    )}
                                                </FormItem>

                                                <FormItem
                                                    {...formItemLayout}
                                                    label="Country"
                                                >
                                                    {getFieldDecorator('Country', {
                                                        initialValue: obj.country,
                                                        rules: [{
                                                            required: true, message: 'Please input country',
                                                        }],
                                                    })(
                                                        <Input placeholder='Country' name="country" onChange={e => handleChange('country', e)} setFieldsValue={obj.country} />
                                                    )}
                                                </FormItem>

                                                <FormItem
                                                    {...formItemLayout}
                                                    label="Zip/Postal Code"
                                                >
                                                    {getFieldDecorator('zip', {
                                                        initialValue: obj.postal_code,
                                                        rules: [{
                                                            required: true, message: 'Please input zip/postalCode',
                                                        }],
                                                    })(
                                                        <Input placeholder='Zip/Postal Code' name="zip" onChange={e => handleChange('postal_code', e)} setFieldsValue={obj.postal_code} />
                                                    )}
                                                </FormItem>
                                            </>
                                        }
                                        <FormItem
                                            {...formItemLayout}
                                        >
                                            <div className='checkout-next-step-button'>
                                                {/* <Button type="primary" style={{ fontSize: '22px', padding: '5px 25px', height: '45px' }} className="gx-mb-0 submit-button" htmlType="submit" > */}
                                                <Button type="primary" className="gx-mb-0 submit-button" htmlType="submit" >
                                                    Next Step
                                                </Button>
                                            </div>
                                        </FormItem>


                                    </Form>


                                </Widget>

                            </Col>

                        </Row>

                    </Card>

                </Spin>
            </div >
        </>
    );


}

const CheckoutPage = Form.create()(Checkout);



const CheckoutPageElement = (props) => (
    <CheckoutPage {...props} />
);

export default withRouter(CheckoutPageElement);