import React from 'react'
import { Modal } from 'antd'
import SignIn from '../SignIn'
import SignUp from '../SignUp'
import VerifyEmail from '../VerifyEmail'
import ForgotPassword from '../ForgotPassword'
import ResetPassword from '../ResetPassword'

export default function ModalData(props) {

    // const { typeModal } = useSelector(({ auth }) => auth);
    // const [visible, setVisible] = useState(false);

    // useEffect(() => {
    //     if (typeModal === 0) {
    //         setVisible(false);
    //     } else {
    //         setVisible(true);
    //     }
    // }, [typeModal])

    return (
        <Modal
            visible={props.visible}
            title="Title"
            onCancel={() => props.handleCancel()}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
        >
            {
                props.typeModal === 1 ?
                    <SignIn handleCancel={props.handleCancel} showmodaldata={(data) => props.showmodaldata(data)} /> :
                    props.typeModal === 2 ?
                        <SignUp handleCancel={props.handleCancel} showmodaldata={(data) => props.showmodaldata(data)} /> :
                        props.typeModal === 3 ?
                            <VerifyEmail handleCancel={props.handleCancel} showmodaldata={(data) => props.showmodaldata(data)} /> :
                            props.typeModal === 4 ?
                                <ForgotPassword handleCancel={props.handleCancel} showmodaldata={(data) => props.showmodaldata(data)} /> :
                                props.typeModal === 5 ?
                                    <ResetPassword handleCancel={props.handleCancel} showmodaldata={(data) => props.showmodaldata(data)} /> : null
            }
        </Modal>
    )
}
