import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { API } from "aws-amplify";
import React from 'react'

const FooterText = () => {
    const [footerText, setFooterText] = useState(null);

    useEffect(() => {
        async function fetchData() {
            var res = await API.post('PaymentGatewayAPI', '/paymentgateway/generalSettings', {})
            if (res.success) {
                res = res.result
                if (res && res.length > 0) {
                    localStorage.setItem('generalSettings', JSON.stringify(res[0]))
                    setFooterText(res[0].copyrightText)
                    if (res[0].discountModal && res[0].discountModal === 'ENABLE') {
                        sessionStorage.setItem('isShowDiscountModal', 'true')
                    } else {
                        sessionStorage.setItem('isShowDiscountModal', 'false')
                    }
                }
            } else {
                console.log(JSON.stringify(res))
            }
        }
        async function fetchSubscriptionData() {
            var res = await API.post('PaymentGatewayAPI', '/paymentgateway/subscriptionSettings', {})
            if (res.success) {
                res = res.result
                if (res && res.length > 0) {
                    localStorage.setItem('subscriptionSettings', JSON.stringify(res[0]))
                }
            } else {
                console.log(JSON.stringify(res))
            }
        }
        async function fetchQueryWorkbenchData() {
            var res = await API.post('PaymentGatewayAPI', '/paymentgateway/queryWorkbenchSettings', {})
            if (res.success) {
                res = res.result
                if (res && res.length > 0) {
                    localStorage.setItem('queryWorkbenchSettings', JSON.stringify(res[0]))
                }
            } else {
                console.log(JSON.stringify(res))
            }
        }

        async function fetchSupportSettingsData() {
            var res = await API.post('PaymentGatewayAPI', '/paymentgateway/supportSettings', {})
            if (res.success) {
                res = res.result
                if (res && res.length > 0) {
                    localStorage.setItem('supportSettings', JSON.stringify(res[0]))
                }
            } else {
                console.log(JSON.stringify(res))
            }
        }
        async function fetchAlertSettingsData() {
            var res = await API.post('PaymentGatewayAPI', '/paymentgateway/alertSettings', {})
            if (res.success) {
                res = res.result
                if (res && res.length > 0) {
                    localStorage.setItem('alertSettings', JSON.stringify(res[0]))
                }
            } else {
                console.log(JSON.stringify(res))
            }
        }

        async function fetchPurchaseOrderSettingsData() {
            var res = await API.post('PaymentGatewayAPI', '/paymentgateway/PurchaseOrderSettings', {})
            if (res.success) {
                res = res.result
                if (res && res.length > 0) {
                    localStorage.setItem('purchaseOrderSettings', JSON.stringify(res[0]))
                }
            } else {
                console.log(JSON.stringify(res))
            }
        }
        fetchData();
        fetchQueryWorkbenchData()
        fetchSubscriptionData();
        fetchSupportSettingsData();
        fetchAlertSettingsData();
        fetchPurchaseOrderSettingsData()
    }, [])

    return (
        footerText ? <p dangerouslySetInnerHTML={{ __html: footerText }} /> : 'Copyright © 2023 Canoja Technologies, LLC and Affiliates.'
    )
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps, {
})(FooterText);

