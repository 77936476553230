import { LICENCE_NUMBER, RESET_PURCHASE, DO_PURCHASE, IS_PURCHASE, SET_SUBSCRIPTION_CHANGE ,SAVE_FILTER, SAVE_SORTER, SAVE_FILTERS, RESET_FILTERS, SIGNIN_USER_SUCCESS_DATA, SIGNOUT_USER_SUCCESS_DATA, SET_CURRENTLY_LOGGED_USER } from "../../constants/ActionTypes";

export const saveData = (data) => async dispatch => {
  dispatch({
    type: LICENCE_NUMBER,
    payload: data
  })
};

export const resetPurchase = () => {
  return {
    type: RESET_PURCHASE
  }
}

export const doPurchase = () => async dispatch => {
  dispatch({
    type: DO_PURCHASE
  })
}

export const toProfile = (data) => async dispatch => {
  dispatch({
    type: IS_PURCHASE,
    payload: data
  })
}

export const saveFilter = (data) => {
  return {
    type: SAVE_FILTER,
    payload: data
  }
}

export const saveSorter = (data) => {
  return {
    type: SAVE_SORTER,
    payload: data
  }
}

export const saveFilters = (data) => {
  return {
    type: SAVE_FILTERS,
    payload: data
  }
}

export const resetFilters = () => {
  return {
    type: RESET_FILTERS
  }
}
export const userSignInSuccessData = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS_DATA,
    payload: authUser
  }
};
export const userSignOutSuccessData = () => {
  return {
    type: SIGNOUT_USER_SUCCESS_DATA,
  }
};

// export const setTimeOut = (timeOut) => {
//   return { type: SET_TIMEOUT, payload: timeOut };
// }

export const setCurrentlyLoggedUser = (isCurrentlyLogged) => {
  return { type: SET_CURRENTLY_LOGGED_USER, payload: isCurrentlyLogged };
}

export const setSubscriptionChange = (isChangeSubscription) => {
  return { type: SET_SUBSCRIPTION_CHANGE, payload: isChangeSubscription };
}