import React, { Component } from 'react';
import './PayButton.css';
import PropTypes from 'prop-types';
import PayButtonContent from './PayButtonContent';

class PayButton extends Component {
    render() {
        const {
            apiName,
            reportname,
            amount,
            currency,
            quantity,
            description,
            disabled,
            validateCheck,
            promotioncode,
            isFrom,
            amountPerRecord,
            totalCounts,
            subscriptionId,
            userPlan,
            showPromotionCodeError,
            customerId,
            secondarySubscription,
            isBasicPlan,
            discountedPrice
        } = this.props;
        return (
                    <PayButton
                        discountedPrice={discountedPrice}
                        apiName={apiName}
                        reportname={reportname}
                        amount={amount}
                        currency={currency}
                        quantity={quantity}
                        description={description}
                        promotioncode={promotioncode}
                        isFrom={isFrom}
                        disabled={disabled}
                        validateCheck={validateCheck}
                        amountPerRecord={amountPerRecord}
                        userPlan={userPlan}
                        subscriptionId={subscriptionId}
                        totalCounts={totalCounts}
                        isBasicPlan={isBasicPlan}
                        showPromotionCodeError={showPromotionCodeError}
                        customerId={customerId}
                        secondarySubscription={secondarySubscription}
                    />
        );
    }
}

PayButton.propTypes = {
    apiName: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    reportname: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    quantity: PropTypes.number.isRequired,
};

export default PayButton;