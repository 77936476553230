import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Notes from "./Notes";
import Support from "./Support";
import Common from "./Common";
import DataTable from "./DataTable";
import SetTime from "./SetTime";

const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  notes: Notes,
  support: Support,
  common: Common,
  table: DataTable,
  time: SetTime,
});

export default createRootReducer
