import {
  DELETE_SUPPORT_SUCCESS,
  GET_All_SUPPORT_SUCCESS,
  ON_ADD_SUPPORT_SUCCESS,
  UPDATE_SUPPORT_SUCCESS
} from "../../constants/ActionTypes";

const INIT_STATE = {
  supportList: [],
  selectedSupport: []
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case GET_All_SUPPORT_SUCCESS: {
      return {
        ...state,
        supportList: action.payload,
      }
    }

    case UPDATE_SUPPORT_SUCCESS:
      return {
        ...state,
        supportList: state.supportList.map((support) => support.id === action.payload.id ? action.payload : support),
      };

    case DELETE_SUPPORT_SUCCESS:
      return {
        ...state,
        supportList: state.supportList.filter((support) => support.id !== action.payload.id),
      };

    case ON_ADD_SUPPORT_SUCCESS:
      return {
        ...state,
        supportList: action.payload.support(state.supportList),
      };

    default:
      return state;
  }
}
