import React from "react";
import { Button, Form, Input } from "antd";
import { connect } from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  hideAuthLoader
} from "appRedux/actions/Auth";
import CircularProgress from "components/CircularProgress/index";
import * as Amplify from 'aws-amplify';
import notify from '../Notification';
import {
  showModal
} from 'appRedux/actions/Common';
import { createAuthorizeCustomer } from "../service/SubscriptionService";
import { Prompt } from "react-router";

const FormItem = Form.Item;

class SignIn extends React.Component {
  constructor() {
    super()
    this.state = {
      loader: false,
      subscriptionInterruption: 'changeing page, your data will be removed'
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    let data = localStorage.getItem('alertSettings')
    if (data) {
      data = JSON.parse(data)
      if (data) {
        this.setState({ subscriptionInterruption: data.subscriptionInterruption })
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // this.props.showAuthLoader();
        // this.props.userSignIn(values);
        this.setState({ loader: true })
        const email = window.sessionStorage.getItem("email");

        this.confirmSignUp(email, values.verify_code);
      }
    });
  };

  confirmSignUp = async (email, code) => {
    Amplify.Auth.confirmSignUp(email, code)
      .then(async (success) => {
        notify.openNotificationWithIcon('success', 'Success', 'Email was Verified');
        await createAuthorizeCustomer(email)
        this.props.showmodaldata(1)
      })
      .catch(err => {
        console.log(err)
        notify.openNotificationWithIcon('error', 'Fail', err.message);
        // this.props.hideAuthLoader();
        this.setState({ loader: false })
      })
  }

  resendCode = () => {
    const email = window.sessionStorage.getItem("email");

    Amplify.Auth.resendSignUp(email, {})
      .then(success => {
        notify.openNotificationWithIcon('success', 'Success', 'Verification code was sent');
        this.setState({ loader: false })
        // this.props.hideAuthLoader();
      })
      .catch(err => {
        console.log(err)
        notify.openNotificationWithIcon('error', 'Fail', err.message);
        // this.props.hideAuthLoader();
        this.setState({ loader: false })
      })
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { showMessage, alertMessage } = this.props;
    const promptParams = {
      messageText: this.state.subscriptionInterruption,
      data: {
        email: window.sessionStorage.getItem("email"),
      }

    };
    const message = JSON.stringify(promptParams);
    return (
      <>
        <Prompt when={true} message={message} />
        <div className="gx-app-login-wrap">

          <div className="gx-app-login-container">
            <div className="gx-app-login-main-content">
              <div className="gx-app-logo-content">
                <div className="gx-app-logo-content-bg">
                </div>
                <div
                  className="gx-app-logo"
                  style={{ marginBottom: "auto" }}
                >
                  <img alt="example" src={require("assets/images/logo.png")} />
                </div>
                <div className="gx-app-logo-wid">
                  <h1>Verify your Email</h1>
                </div>
              </div>
              <div className="gx-app-login-content">
                <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                  <FormItem
                    label="Enter Code"
                  >
                    {getFieldDecorator('verify_code', {
                      rules: [{
                        required: true, message: 'The input is not valid',
                      }],
                    })(
                      <Input placeholder="code" />
                    )}
                  </FormItem>

                  <FormItem>
                    <Button type="primary" className="gx-mb-0" htmlType="submit">
                      Submit
                  </Button>
                    <Button type="primary" className="gx-mb-0" onClick={this.resendCode}>
                      Resend
                  </Button>
                  </FormItem>
                </Form>
              </div>

              {this.state.loader ?
                <div className="gx-loader-view">
                  <CircularProgress />
                </div> : null}
              {showMessage ?
                message.error(alertMessage.toString()) : null}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  hideAuthLoader,
  showModal
})(WrappedNormalLoginForm);
