import React from "react";
import { Form, Input, Select, Collapse, Checkbox, message, Button, Spin, Modal, Icon, Row, Col, notification } from "antd";
import Auxiliary from "../../util/Auxiliary";
import * as Amplify from 'aws-amplify';
import "./index.css";
import 'jspdf-autotable'
import MaskedInput from 'antd-mask-input'
import { checkCanceledSubscription, checkIsBasicPlan, isEnterprisePlanWithPlanData } from "../../service/SubscriptionService";
import { connect } from "react-redux";
import { setSubscriptionChange } from "../../appRedux/actions/DataTable";
import { withRouter } from "react-router-dom";
import { userSignOutSuccess } from "../../appRedux/actions/Auth";
import { userSignOutSuccessData, } from "../../appRedux/actions/DataTable";
import { setTimeOut, } from "../../appRedux/actions/SetTime";
import moment from "moment";
import { generatePDF } from "./CreatePdf";
import AWS from 'aws-sdk';
import CardDetailsPopup from "./CardDetailsPopup";
import { ChangePassword, UpdateUserData } from "../../service/UserService";
import { createCanceledSubscription, updateCanceledSubscription } from "../../graphql/mutations";
import { API, graphqlOperation } from 'aws-amplify';
import DataTable from "../Report/Table";
import { listUserCreditsByCustomerId } from "../../graphql/queries";
import { checkSubscriptionStatus, getEnvByName } from "../../service/GeneralService";

const { Panel } = Collapse;

const FormItem = Form.Item;
const Option = Select.Option;
const openNotificationWithIcon = (type, title, description) => {
  notification[type]({
    message: title,
    description: description,
  });
};

class Profile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      info: null,
      subscriptionType: null,
      subscriptionId: null,
      accessKey: null,
      isShowLink: false,
      totalEnterpriseRecords: 0,
      amountPerEnterpriseRecord: 0,
      totalEnterpriseAmount: 0,
      totalPremiumRecords: 0,
      amountPerPremiumRecord: 0,
      amountPerBasicRecord: 0,
      totalPremiumAmount: 0,
      subscription: null,
      paymentMethod: null,
      isErr: false,
      cardExpiration: null,
      isShowPagination: false,
      isShowPaginationAPI: false,
      tableData: null,
      limit: 10,
      isShowModal: false,
      text: null,
      isCancelation: false,
      isCancelApply: false,
      isShowReportData: false,
      envList: {},
      discount: 0,
      page: 1,
      size: 10,
      total: 0,
      userCredits: 0,
      subscriptionStatusOptions: {
        active: 'Cancel Subscription',
        expired: 'Start New Subscription',
        suspended: 'Change Payment Details',
        canceled: 'Start New Subscription',
        terminated: 'Start New Subscription',
      },
      cancelSubscriptionStatus: 'Cancel Subscription applied',
      totalAPIAmount: 0,
      totalAPIRecords: 0,
      alertMessage: 'Something bad happen on server',
      amountPerAPIRecord: 0
    }

    this.columns = [
      { title: 'Transaction ID', dataIndex: 'transId', key: 'transId', },
      { title: 'Transection Status', dataIndex: 'transactionStatus', key: 'transactionStatus' },
      { title: 'Customer', dataIndex: 'firstName', key: 'firstName', render: (text, index) => index.firstName ? `${index.firstName}, ${index.lastName}` : '' },
      { title: 'Payment Method', dataIndex: 'accountNumber', key: 'accountNumber' },
      { title: 'Amount Paid', dataIndex: 'settleAmount', key: 'settleAmount', render: (text, index) => index.settleAmount ? Number(index.settleAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : (0).toLocaleString('en-US', { style: 'currency', currency: 'USD' }), },
      { title: 'Created On', dataIndex: 'submitTimeUTC', key: 'submitTimeUTC', render: (text, index) => index.submitTimeUTC ? moment(index.submitTimeUTC).format("MM-DD-YYYY") : null, },
      {
        title: 'Action',
        dataIndex: 'id',
        key: 'id',
        render: (text, record) => {
          return <>
            <Button type="link"
              className='buttonAsLink'
              onClick={(e) => this.sendReceipt(record)}
              style={{
                pointerEvents: "inherit",
                color: "#5FA30F",
                marginRight: '0px'
              }}
            >
              Send Receipt
            </Button>

            <span> | </span>
            <Button type="link"
              className='buttonAsLink'
              onClick={(e) => this.downloadPDF(record)}
              style={{
                pointerEvents: "inherit",
                color: "#5FA30F",
                marginRight: '0px'
              }}
            >
              Download PDF
            </Button>
          </>
        }
      },
    ]
  }

  componentDidMount() {
    this.fetchMessage()
    this.fetchErrorData()
    this.fetchEnv()
  }

  fetchErrorData = () => {
    let alertData = localStorage.getItem('alertSettings')
    if (alertData) {
      alertData = JSON.parse(alertData)
      if (alertData.genericAlert) {
        this.setState({ alertMessage: alertData.genericAlert })
      }
    }
    let subscriptionSettings = localStorage.getItem('subscriptionSettings')
    if (subscriptionSettings) {
      subscriptionSettings = JSON.parse(subscriptionSettings)
      if (subscriptionSettings.cancelStatusMessage) {
        this.setState({ cancelSubscriptionStatus: subscriptionSettings.cancelStatusMessage })
      }
    }
    this.fetchUserData()
  }

  fetchMessage = async () => {
    let data = localStorage.getItem('generalSettings')
    if (data) {
      data = JSON.parse(data)
      if (data) {
        this.setState({ cancelSubscriptionMessage: data.cancelSubscriptionMessage, enableAutoRenewalMessage: data.enableAutoRenewalMessage, disableAutoRenewalMessage: data.disableAutoRenewalMessage })
      }
    }
  }

  fetchEnv = async () => {
    let envString = `'REACT_APP_CONTACT_COMPANY_EMAIL','REACT_APP_CONTACT_EMAIL_SUBJECT','REACT_APP_SES_API_VERSION','REACT_APP_SES_REGION','REACT_APP_SES_ACCESS_KEY_ID','REACT_APP_SES_SECRET_ACCESS_KEY','REACT_APP_SES_ENDPOINT','REACT_APP_INVOICE_SUBJECT', 'REACT_APP_REST_API_DOCUMENTATION'`
    let envList = await getEnvByName(envString)
    if (envList !== false) {
      this.setState({ envList: envList })
    }
  }

  fetchUserData = () => {
    this.setState({ loading: true })
    Amplify.Auth.currentAuthenticatedUser()
      .then(async user => {
        if (user) {
          Amplify.Auth.userAttributes(user)
            .then(async userAttributes => {
              if (userAttributes && userAttributes.length > 0) {
                let obj = {};
                for (let i = 0; i < userAttributes.length; i++) {
                  obj = { ...obj, [userAttributes[i]["Name"]]: userAttributes[i]["Value"] }
                }
                this.setState({ info: obj, customerEmail: obj.email });
              }
            })

          let accessKey = window.sessionStorage.getItem('accessKey')
          let customerId = window.sessionStorage.getItem('customerId')
          let res = await API.get('PaymentGatewayAPI', `/paymentgateway/customers/${customerId}`,)
          if (res.success) {
            res = res.customer
            let subscriptionId = res.subscriptionIds && res.subscriptionIds.subscriptionId && res.subscriptionIds.subscriptionId[0] ? res.subscriptionIds.subscriptionId[0] : ''
            this.setState({ subscriptionId: subscriptionId })
            let isCheckForBasicPlan = true
            if (subscriptionId) {

              window.sessionStorage.setItem('subscriptionId', subscriptionId)
              let result = await API.get('PaymentGatewayAPI', `/paymentgateway/subscription/${subscriptionId}`)
              if (result.success) {
                let subscription = result.subscription.subscription
                if (subscription) {
                  let planData = await isEnterprisePlanWithPlanData(subscription)
                  if (planData) {
                    if (accessKey && planData.isValid) {
                      this.setState({ accessKey: accessKey, isShowLink: true, });
                    }
                    if (planData.data && planData.data.length > 0) {
                      if (planData.isValid) {
                        this.setState({ amountPerEnterpriseRecord: Number(planData.data[0].amountPerRecord) })

                      } else {
                        this.setState({ amountPerPremiumRecord: Number(planData.data[0].amountPerRecord) })

                      }
                    }
                  }

                  isCheckForBasicPlan = false
                  let isValidSubscriptionStatus = await checkSubscriptionStatus(subscription.status)
                  if (isValidSubscriptionStatus === true) {
                    await this.setSubscriptionData(subscription, subscriptionId)
                  } else {
                    await this.checkForBasicPlan(res, customerId, subscription, subscriptionId)
                  }
                }
              }

            }
            if (isCheckForBasicPlan) {
              await this.checkForBasicPlan(res, customerId)
            }
            if (this.state.subscriptionId) {
              let response = await checkCanceledSubscription(this.state.subscriptionId)
              let isCancelApply = false
              let cancelSubscriptionId
              if (response !== false) {
                isCancelApply = response.isCancelApplied === 'true' ? true : false
                cancelSubscriptionId = response.id
              }
              this.setState({ cancelSubscriptionId: cancelSubscriptionId, isCancelApply: isCancelApply })
            }
            if (customerId) {
              this.fetchUserCredits(customerId)
              this.fetchReportCharge(customerId, this.state.subscriptionType)
              this.fetchChargeList(customerId)
            }
          } else {
            res = null
          }
          this.setState({ loading: false })
        }
      }).catch(e => {
        console.log(e)
        this.setState({ loading: false })
      })
  }

  checkForBasicPlan = async (res, customerId, subscription, subscriptionId) => {
    let data = await checkIsBasicPlan(customerId)
    if (data !== false && data && data.planType) {
      this.setState({ amountPerBasicRecord: Number(data.amountPerRecord) })
      if (res.profile && res.profile.paymentProfiles) {
        data['paymentProfiles'] = res.profile.paymentProfiles
      } else {
        data['paymentProfiles'] = {}
      }
      let t = new Date();
      let lastData = ("0" + (t.getMonth() + 1)).slice(-2) + '/' + 28 + '/' + t.getFullYear();
      data['paymentSchedule'] = {
        interval: {
          unit: 'monthly'
        },
        last_date: lastData,
        startDate: data.createdAt
      }
      data['amount'] = 0
      data['status'] = 'active'
      this.setState({ subscriptionType: data.planType, subscription: data, subscriptionId: `BASIC_${customerId}`, })
      if (res.profile.paymentProfiles[0]) {
        let sub = {
          profile: {
            paymentProfile: res.profile.paymentProfiles[0]
          }
        }
        await this.getPaymentMethod(sub)
      }
    } else if (subscription) {
      await this.setSubscriptionData(subscription, subscriptionId)
    }
  }

  setSubscriptionData = async (subscription, subscriptionId) => {
    await this.getPaymentMethod(subscription)
    let my_date = new Date()
    let last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0);
    subscription.paymentSchedule['last_date'] = last_date.toString()
    this.setState({ subscription: subscription, subscriptionType: subscription.name, subscriptionId: subscriptionId, })
  }

  fetchReportCharge = async (customerId, planType) => {
    if (planType === 'ENTERPRISE' || planType === 'PREMIUM') {
      let response = await API.get('PaymentGatewayAPI', `/paymentgateway/charge/${customerId}`, {})
      if (response.success) {
        if (response && response.Charge && response.Charge.length > 0) {
          let totalEnterpriseReportRecords = 0
          let amountPerEnterpriseReportRecord = 0
          let enterpriseReportTotal = 0

          let totalPremiumReportRecords = 0
          let amountPerPremiumReportRecord = 0
          let premiumReportTotal = 0

          let totalAPIRecords = 0
          let amountPerAPIRecord = 0
          let APITotal = 0
          for (let i = 0; i < response.Charge.length; i++) {
            let charge = response.Charge[i]
            if (charge.productName !== 'CUSTOMER_REST_API') {

              if (charge.productName === 'ENTERPRISE') {
                totalEnterpriseReportRecords += Number(charge.noOfRecordsFetch)
                amountPerEnterpriseReportRecord = Number(charge.pricePerUnit)
                enterpriseReportTotal += Number(charge.chargeAmount)
              } else {
                totalPremiumReportRecords += Number(charge.noOfRecordsFetch)
                amountPerPremiumReportRecord = Number(charge.pricePerUnit)
                premiumReportTotal += Number(charge.chargeAmount)
              }

              
            } else {

              totalAPIRecords += Number(charge.noOfRecordsFetch)
              amountPerAPIRecord = Number(charge.pricePerUnit)
              APITotal += Number(charge.chargeAmount)

            }
          }
          if (amountPerPremiumReportRecord > 0) {
            this.setState({ amountPerPremiumRecord: amountPerPremiumReportRecord })
          }
          if (amountPerEnterpriseReportRecord > 0) {
            this.setState({ amountPerEnterpriseRecord: amountPerEnterpriseReportRecord })
          }
          this.setState({ totalEnterpriseRecords: totalEnterpriseReportRecords, totalEnterpriseAmount: enterpriseReportTotal, totalPremiumRecords: totalPremiumReportRecords, totalPremiumAmount: premiumReportTotal, isShowReportData: true, totalAPIRecords: totalAPIRecords, totalAPIAmount: APITotal, amountPerAPIRecord: amountPerAPIRecord, })
        } else {

        }
      } else {
        console.log(response.err)
      }
    } else {
      this.setState({ isShowReportData: false })
    }
  }

  fetchUserCredits = async (customerId) => {
    let userCreditData = {
      customerId: customerId,
    }
    await API.graphql(graphqlOperation(listUserCreditsByCustomerId, userCreditData))
      .then(result => {
        if (result && result.data && result.data.listUserCreditsByCustomerId && result.data.listUserCreditsByCustomerId.items && result.data.listUserCreditsByCustomerId.items.length > 0) {
          let userCredits = result.data.listUserCreditsByCustomerId.items[0].amount || 0
          this.setState({ userCredits: Number(userCredits) })
        }
      })
      .catch(err => {
        console.log(err)
      });
  }

  fetchChargeList = async () => {
    let customerId = window.sessionStorage.getItem('customerId')
    let body = {
      page: this.state.page,
      size: this.state.size
    }
    let response = await API.post('PaymentGatewayAPI', `/paymentgateway/transection/${customerId}`, { body })
    if (response.success) {
      let tableData = []
      if (response.Transactions && response.Transactions.transactions && response.Transactions.transactions.transaction && response.Transactions.transactions.transaction.length > 0) {
        tableData = response.Transactions.transactions.transaction
        if (response.Transactions.totalNumInResultSet) {
          let total = response.Transactions.totalNumInResultSet
          this.setState({ total: total, })
        }
      }

      this.setState({ tableData: tableData, })
    } else {
      console.log(response.err)
      message.error(<p dangerouslySetInnerHTML={{ __html: this.state.alertMessage }} />)
    }
  }

  getPaymentMethod = async (subscription, paymentProfile) => {
    if (subscription.profile && subscription.profile.paymentProfile && subscription.profile.paymentProfile.payment && subscription.profile.paymentProfile.payment.creditCard && subscription.profile.paymentProfile.payment.creditCard.cardNumber && subscription.profile.paymentProfile.payment.creditCard.cardNumber) {
      this.setState({ paymentMethod: subscription.profile.paymentProfile.payment.creditCard.cardNumber, paymentProfileId: subscription.profile.paymentProfile.customerPaymentProfileId })
    }
  }

  showPopup = async (status) => {
    if (status === 'active' && this.state.isCancelApply) {
      this.showConfirmation()
    } else if (status === 'active' && !this.state.isCancelApply) {
      this.setState({ text: this.state.cancelSubscriptionMessage, isShowModal: true, isCancelation: true })
    } else if (status === 'suspended') {
      this.changeCardDetails()
    } else {
      this.redirectToSubscriptionPage()
    }
  }

  showConfirmation = async () => {
    this.setState({ text: this.state.isCancelApply ? 'Are you want to stop cancel susbcription process?' : this.state.disableAutoRenewalMessage, isShowModal: true, isCancelation: false })
  }

  confirmCancelSubscription = async () => {
    this.setState({ loading: true, isShowModal: false })
    let isCancelApply = this.state.isCancelApply
    let customerId = window.sessionStorage.getItem('customerId')
    let cancelSubscriptionId = this.state.cancelSubscriptionId

    let cancelSubscriptionData
    if (cancelSubscriptionId) {
      cancelSubscriptionData = {
        updateCanceledSubscriptionInput: {
          id: cancelSubscriptionId,
          isCancelApplied: `${!isCancelApply}`,
        }
      }
      await API.graphql(graphqlOperation(updateCanceledSubscription, cancelSubscriptionData))
        .then(result => {
          if (this.state.isCancelation && !isCancelApply === true) {
            this.info()
          } else {
            message.success(`Auto Renew ${!isCancelApply === true ? 'Disabled' : 'Enabled'}`)
          }
          this.setState({ isCancelApply: !isCancelApply, loading: false })
        })
        .catch(err => {
          console.log(err)
          this.setState({ loading: false })
          message.error(<p dangerouslySetInnerHTML={{ __html: this.state.alertMessage }} />);
        });
    } else {
      cancelSubscriptionData = {
        createCanceledSubscriptionInput: {
          customerProfileId: customerId,
          subscriptionId: this.state.subscriptionId,
          isCancelApplied: 'true',
          isCanceled: 'false',
        }
      }

      await API.graphql(graphqlOperation(createCanceledSubscription, cancelSubscriptionData))
        .then(result => {
          message.success('Auto Renew Disabled')
          this.setState({ isCancelApply: true, })
          this.fetchUserData()
        })
        .catch(err => {
          console.log(err)
          this.setState({ loading: false })
          message.error(<p dangerouslySetInnerHTML={{ __html: this.state.alertMessage }} />);
        });
    }



  }

  info = () => {
    Modal.info({
      title: 'Your subscription has successfully been cancelled',
      icon: '',
      content: (
        <div>
          <p>Your access to your {this.state.subscriptionType.charAt(0).toUpperCase() + this.state.subscriptionType.slice(1).toLowerCase()} subscription will remain until  {this.state.subscription && this.state.subscription.last_date ? moment(this.state.subscription.last_date).format("MM-DD-YYYY") : ""} </p>
        </div>
      ),
      onOk() { },
    });
  }

  changeCardDetails = () => {
    this.setState({ isShowCardModal: true })
  }

  confirmCardDetailsChange = () => {
    this.setState({ isShowCardModal: false })
    setTimeout(async () => {
      this.fetchUserData()
    }, 5000);
  }

  cancelCardDetailsChange = () => {
    this.setState({ isShowCardModal: false })
  }

  handleCopy = (e) => {
    let text = document.createElement("textarea");
    document.body.appendChild(text);
    text.value = this.state.accessKey;
    text.select();
    document.execCommand("copy");
    document.body.removeChild(text);
  }

  redirectToSubscriptionPage = () => {
    this.props.history.push("/subscriptions");
  }


  sendReceipt = async (record) => {
    this.setState({ loading: true })
    let result = await generatePDF(record, false)

    const time = new Date().getTime();
    const pdfName = `Invoice - ${record.transId} _pdf_${time}.pdf`;
    let expires = new Date();

    await Amplify.Storage.put(`purchased - reports / ${pdfName} `,
      result,
      {
        ContentType: 'application/pdf;',
        expires: new Date(expires.setDate(expires.getDate() + 30))
      })

    const url = await Amplify.Storage.get(`purchased - reports / ${pdfName} `)

    let Env = this.state.envList

    const sender = Env['REACT_APP_CONTACT_COMPANY_EMAIL'];
    const recipient = this.state.customerEmail;
    const subject = Env['REACT_APP_INVOICE_SUBJECT'];

    const ses = new AWS.SES({
      apiVersion: Env['REACT_APP_SES_API_VERSION'],
      region: Env['REACT_APP_SES_REGION'],
      accessKeyId: Env['REACT_APP_SES_ACCESS_KEY_ID'],
      secretAccessKey: Env['REACT_APP_SES_SECRET_ACCESS_KEY'],
      endpoint: Env['REACT_APP_SES_ENDPOINT'],
    });

    const params = {
      Source: sender,
      Destination: {
        ToAddresses: [
          recipient
        ],
      },
      Message: {
        Subject: {
          Data: subject
        },
        Body: {
          Html: {
            Data: `< html > <body>
            <p><b>Invoice Number : </b>${record.transId}</p>
            <p><b>To download the invoice </b><a href='${url}'>Click here </a> </p>
          </body></html > `
          },

        }
      },
    };

    const sendPromise = ses.sendEmail(params).promise();

    const me = this;
    sendPromise.then(
      function (data) {
        me.createNotification()
      }).catch(
        function (err) {
          console.error(err, err.stack);
        })
    this.setState({ loading: false })
  }

  createNotification = async () => {
    await message.success("Email has been sent successfuly");
  }

  downloadPDF = async (record) => {
    let result = await generatePDF(record, true)
    if (result !== true) {
      message.error("Email has not been sent")
    }
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('newPassword')) {
      callback('The passwords you entered do not match!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.props.confirmDirty) {
      form.validateFields(['confirmNewPassword'], { force: true });
    }
    callback();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields(["oldPassword", "newPassword", "confirmNewPassword"], (err, values) => {

      if (!err && !this.state.isErr) {
        this.handleChangePassword(values);
      }
    });
  }

  onChange = (e) => {
    let password = e.target.value
    if (password) {
      document.getElementById("passwordRequirements").style.display = "block";
      let lengthState = false
      let upperState = false
      let symbolState = false
      let numberState = false
      let lowerState = false
      let isErr = true

      if (password.length > 8) {
        lengthState = true
      }
      if (/[A-Z]/.test(password)) {
        upperState = true
      }
      if (/[a-z]/.test(password)) {
        lowerState = true
      }
      if (/\d/.test(password)) {
        numberState = true
      }
      const format = /[!@#$%^&*()_+\-={};':"\\|,.<>?]+/;
      if (format.test(password)) {
        symbolState = true
      }
      if (lengthState && upperState && numberState && symbolState && lowerState) {
        isErr = false
      }
      this.setState({ lengthState: lengthState, upperState: upperState, symbolState: symbolState, numberState: numberState, lowerState: lowerState, isErr: isErr })
    } else {
      document.getElementById("passwordRequirements").style.display = "none";
    }
  }


  handleChangePassword = async (data) => {
    let token = window.sessionStorage.getItem('user_token')
    if (token) {
      try {
        this.setState({
          loading: true
        });
        let response = await ChangePassword(token, data.oldPassword, data.newPassword)
        if (!response.message) {
          message.success('Password changed successfully ');
          document.getElementById("passwordRequirements").style.display = "none";
          document.getElementById("oldPasswordRequirements").style.display = "none";
        }
      } catch (err) {
        if (err.message.includes("proposedPassword") || err.message.includes("proposedPassword")) {
          document.getElementById("passwordRequirements").style.display = "block";

          data.newPassword.length > 7 ? this.setState({ lengthState: true }) : this.setState({ lengthState: false });


          /[A-Z]/.test(data.newPassword) ? this.setState({ upperState: true }) : this.setState({ upperState: false });

          /\d/.test(data.newPassword) ? this.setState({ numberState: true }) : this.setState({ numberState: false });

          const format = /[!@#$%^&*()_+\-={};':"\\|,.<>?]+/
            ;
          format.test(data.newPassword) ? this.setState({ symbolState: true }) : this.setState({ symbolState: false })
        } else if (err.message.includes("prevPassword") || err.message.includes("prevPassword")) {
          document.getElementById("oldPasswordRequirements").style.display = "block";

          data.newPassword.length > 7 ? this.setState({ lengthState1: true }) : this.setState({ lengthState1: false });


          /[A-Z]/.test(data.newPassword) ? this.setState({ upperState1: true }) : this.setState({ upperState1: false });

          /\d/.test(data.newPassword) ? this.setState({ numberState1: true }) : this.setState({ numberState1: false });

          const format = /[!@#$%^&*()_+\-={};':"\\|,.<>?]+/
            ;
          format.test(data.newPassword) ? this.setState({ symbolState1: true }) : this.setState({ symbolState1: false })
        } else {
          document.getElementById("passwordRequirements").style.display = "none";
          document.getElementById("oldPasswordRequirements").style.display = "none";
        }
        console.log(err)
        openNotificationWithIcon('error', 'Fail', err.message);
        this.setState({ loader: false, isDisable: false })
      }
      this.setState({
        loading: false
      });
    } else {
      message.error('User not found')
    }
  }


  handleProfileChange = (e) => {
    e.preventDefault();
    this.props.form.validateFields(["given_name", "family_name", "phone_number", 'email', 'custom:company-name'], (err, values) => {

      if (!err) {
        this.profileChange(values);
      }
    });
  }

  profileChange = async (values) => {
    try {
      this.setState({
        loading: true
      });
      values['email_verified'] = 'true'
      let number = values['phone_number'].replace(/[^0-9]/ig, "");
      values['phone_number'] = '+1' + number
      let output = Object.entries(values).map(([Name, Value]) => ({ Name, Value }));
      let response = await UpdateUserData(output, this.state.info['sub'])
      if (!response.message) {
        message.success('Profile change saved successfully ');
      }

    } catch (err) {
      console.log(err)
      message.error(<p dangerouslySetInnerHTML={{ __html: this.state.alertMessage }} />);
      this.setState({ loader: false, isDisable: false })
    }
    this.setState({
      loading: false
    });
  }

  handleSubscribe = () => {
    this.props.history.push('/subscriptions')
  }

  onPageNumberChange = (pageNumber) => {
    this.setState({ page: pageNumber }, () => {
      this.fetchChargeList()
    })
  }

  onSizeChange = (current, pageSize) => {
    this.setState({ page: 1, size: pageSize }, () => {
      this.fetchChargeList()
    })
  }

  checkPlanCancelApply = (status, cancelApply) => {
    if (status === 'active' && cancelApply) {
      return true
    } else {
      return false
    }
  }

  render() {
    const formItemLayout2 = {
      labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        xl: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 10 },
        sm: { span: 10 },
        xl: { span: 12 },
        lg: { span: 12 },
        md: { span: 12 }
      },
    };
    const isBasic = this.state.subscriptionType === 'BASIC' ? true : false

    const { getFieldDecorator } = this.props.form

    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '+1',
    })(
      <Select style={{ width: 70 }}>
        <Option value="+1">+1</Option>
      </Select>
    );
    const { loading, info, userCredits, subscription, subscriptionStatusOptions, subscriptionId, discount, amountPerAPIRecord, subscriptionType, totalAPIAmount, totalAPIRecords, isShowLink, accessKey, paymentMethod, amountPerBasicRecord, totalEnterpriseAmount, totalEnterpriseRecords, amountPerEnterpriseRecord, totalPremiumRecords, totalPremiumAmount, amountPerPremiumRecord, isCancelApply } = this.state
    return (
      <Auxiliary>
        <Spin tip="Loading..." spinning={loading}>
          <Row>
            <Col xl={12} sm={24} xs={24}>
              <Collapse defaultActiveKey={['1']} expandIconPosition={'right'} className='data-collapse-wrraper'>
                <Panel header="Profile Information" className='data-collapse-header ' key="1">
                  <div className='profile-card-default'>
                    <div className='header-title'>Profile Details</div>
                    <Form onSubmit={this.handleProfileChange} >
                      <FormItem
                        {...formItemLayout2}
                        label="First / Given Name"
                      >
                        {getFieldDecorator('given_name', {
                          rules: [{
                            required: true,
                            message: 'Please enter First / Given Name'
                          }],
                          initialValue: info && info.given_name
                        })(
                          <Input
                            placeholder="John"
                          />
                        )}
                      </FormItem>

                      <FormItem
                        {...formItemLayout2}
                        label="Last / Family Name"
                      >
                        {getFieldDecorator('family_name', {
                          rules: [{
                            required: true,
                            message: 'Please enter Last / Family Name'
                          }],
                          initialValue: info && info.family_name
                        })(
                          <Input
                            placeholder="Boss"
                          />
                        )}
                      </FormItem>

                      <FormItem
                        {...formItemLayout2}
                        label="Email Address"
                      >
                        {getFieldDecorator('email', {
                          initialValue: info && info.email
                        })(
                          <Input
                            disabled={true}
                            placeholder="Email"
                          />
                        )}
                      </FormItem>

                      <FormItem
                        {...formItemLayout2}
                        label="Phone Number"
                      >
                        {getFieldDecorator('phone_number', {
                          rules: [{
                            required: true,
                            message: 'Please enter Phone Number'
                          }],
                          initialValue: info && info.phone_number.slice(2)
                        })(
                          <MaskedInput addonBefore={prefixSelector} mask="(111) 111-1111" name="card" />
                        )}
                      </FormItem>

                      <FormItem
                        {...formItemLayout2}
                        label="Company Name"
                      >
                        {getFieldDecorator('custom:company-name', {
                          rules: [{
                            required: true,
                            message: 'Please enter Company Name'
                          }],
                          initialValue: info && info["custom:company-name"]
                        })(
                          <Input
                            placeholder="Company Name"
                          />
                        )}

                      </FormItem>

                      <FormItem
                        {...formItemLayout2}
                        label="Age Verified"
                      >
                        {getFieldDecorator("Age Verified")(
                          <Checkbox checked disabled={true}></Checkbox>
                        )}
                      </FormItem>
                      <div style={{ textAlign: 'center', }}><Button style={{ backgroundColor: '#5FA30F', color: 'white' }} htmlType="submit" >Submit</Button></div>
                    </Form>
                  </div>
                </Panel>
              </Collapse>
            </Col>
            <Col xl={12} sm={24} xs={24}>
              <Collapse defaultActiveKey={['1']} expandIconPosition={'right'} className='data-collapse-wrraper'>
                <Panel header="Password Management" className='data-collapse-header' key="1">
                  <div className='profile-card-default'>
                    <div className='header-title'>Update Password</div>
                    <Form onSubmit={this.handleSubmit} >
                      <FormItem
                        {...formItemLayout2}
                        label="Old Password"
                        className='password'
                        style={{ lineHeight: 0 }}
                      >
                        {getFieldDecorator("oldPassword", {
                          rules: [{
                            required: true,
                            message: 'Please enter old password'
                          }],
                          initialValue: this.state.oldPassword
                        })(
                          <Input.Password
                            placeholder="Enter Old Password"
                          />
                        )}
                        <div id="oldPasswordRequirements" style={{ paddingLeft: 30, paddingRight: 5, }}>
                          <span className={this.state.lengthState1 ? "set-green" : "set-red"} style={{ lineHeight: 0 }}>* More than 8 characters.</span> <br />
                          <span className={this.state.upperState1 ? "set-green" : "set-red"} style={{ lineHeight: 0 }}>* At least 1 Uppercase.</span> <br />
                          <span className={this.state.numberState1 ? "set-green" : "set-red"} style={{ lineHeight: 0 }}>* At least 1 Number.</span> <br />
                          <span className={this.state.symbolState1 ? "set-green" : "set-red"} style={{ lineHeight: 0 }}>* At least 1 Symbol.</span> <br />
                        </div>
                      </FormItem>

                      <FormItem
                        {...formItemLayout2}
                        label="New Password"
                        className='password'
                        style={{ lineHeight: 0 }}
                      >
                        {getFieldDecorator('newPassword', {
                          rules: [{
                            required: true,
                            message: 'Please enter new password'
                          }],
                          initialValue: this.state.newPassword
                        })(
                          <Input.Password
                            placeholder="Enter New Password"
                            onChange={this.onChange}
                            className={this.state.isErr ? 'form-pass-box' : ''}
                          />
                        )}
                        <div id="passwordRequirements" >
                          <span className={this.state.lengthState ? "set-green" : "set-red"}>* More than 8 Characters.</span> <br />
                          <span className={this.state.upperState ? "set-green" : "set-red"}>* At least 1 Uppercase.</span> <br />
                          <span className={this.state.lowerState ? "set-green" : "set-red"}>* At least 1 Lowercase.</span> <br />
                          <span className={this.state.numberState ? "set-green" : "set-red"}>* At least 1 Number.</span> <br />
                          <span className={this.state.symbolState ? "set-green" : "set-red"}>* At least 1 Symbol.</span> <br />
                        </div>
                      </FormItem>

                      <FormItem
                        {...formItemLayout2}
                        label="Confirm New Password"
                      >
                        {getFieldDecorator('confirmNewPassword', {
                          rules: [{
                            required: true,
                            message: 'Please enter confirm new password'
                          }, {
                            validator: this.compareToFirstPassword,
                          }],
                          initialValue: this.state.confirmNewPassword
                        })(
                          <Input.Password
                            placeholder="Enter New Password"
                          />
                        )}
                      </FormItem>

                      <div style={{ textAlign: 'center', }}><Button style={{ backgroundColor: '#5FA30F', color: 'white' }} htmlType="submit" >Submit</Button></div>
                    </Form>
                  </div>
                </Panel>
              </Collapse>

            </Col>
          </Row>
          <Row>
            <Col xl={8} sm={24} xs={24}>
              <Collapse expandIconPosition={'right'} className='data-collapse-wrraper'>
                <Panel header="Subscription Details" className='data-collapse-header' key="1">
                  <div className=' card-default-height'>
                    <div className='header-title'>Subscription Details</div>
                    <br />
                    {subscription ?
                      <>

                        <div className="container raw-container-wrraper">
                          <div className="row">
                            <div className="col-25">
                              <label htmlFor="fname">Type :</label>
                            </div>
                            <div className="col-75">
                              {subscriptionType}
                              {!isShowLink && ' ('}
                              {!isShowLink && <span
                                onClick={() => this.handleSubscribe()}
                                style={{
                                  pointerEvents: "inherit",
                                  color: "#5FA30F",
                                  cursor: "pointer"
                                }}
                              >
                                Upgrade Subscription
                              </span>}
                              {!isShowLink && ')'}
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-25">
                              <label htmlFor="lname">Term :</label>
                            </div>
                            <div className="col-75">
                              {isBasic ? 'Perpetual' : (subscription && subscription.paymentSchedule && subscription.paymentSchedule.interval && subscription.paymentSchedule.interval.unit && subscription.paymentSchedule.interval.unit === 'months' ? 'Monthly' : 'Monthly')}
                            </div>
                          </div>
                          <br />
                          {subscriptionId && subscriptionType &&
                            <>
                              <div className="row">
                                <div className="col-25">
                                  <label htmlFor="lname">Status :</label>
                                </div>
                                <div className="col-75">
                                  {subscription.status && `${subscription.status[0].toUpperCase()}${subscription.status.slice(1)} `} (
                                  <span
                                    onClick={() => this.showPopup(subscription.status)}
                                    style={{
                                      pointerEvents: "inherit",
                                      color: "#5FA30F",
                                      cursor: "pointer"
                                    }}
                                  >
                                    {this.checkPlanCancelApply(subscription.status, isCancelApply) ? 'Reactivate' : subscriptionStatusOptions[subscription.status]}
                                  </span>)
                                </div>
                              </div>
                              <br />
                            </>
                          }

                          <div className="row">
                            <div className="col-25">
                              <label htmlFor="lname">Number :</label>
                            </div>
                            <div className="col-75">
                              {isBasic ? 'Not Applicable' : subscriptionId}
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-25">
                              <label htmlFor="lname">List Price :</label>
                            </div>
                            <div className="col-75">
                              {isBasic ? 'Not Applicable' : (Number(subscription.amount)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                            </div>
                          </div>
                          <br />
                          {subscription && discount !== 0 &&
                            <>
                              <div className="row">
                                <div className="col-25">
                                  <label htmlFor="lname">Discount :</label>
                                </div>
                                <div className="col-75">
                                  {isBasic ? 'Not Applicable' : (Number(discount)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                </div>
                              </div>
                              <br />
                            </>
                          }
                          <div className="row">
                            <div className="col-25">
                              <label htmlFor="lname">Total Price :</label>
                            </div>
                            <div className="col-75">
                              {isBasic ? 'Not Applicable' : (Number(subscription.amount) - discount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                            </div>
                          </div>
                          <br />


                          <div className="row">
                            <div className="col-25">
                              <label htmlFor="lname">Start Date :</label>
                            </div>
                            <div className="col-75">
                              {isBasic ? 'Not Applicable' : moment(subscription.paymentSchedule.startDate).format("MM-DD-YYYY")}
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-25">
                              <label htmlFor="lname">{this.checkPlanCancelApply(subscription.status, isCancelApply) ? 'Expiration Date' : 'Renewal Date'} :</label>
                            </div>
                            <div className="col-75">
                              {isBasic ? 'Not Applicable' : subscription && subscription.paymentSchedule.last_date ? moment(subscription.paymentSchedule.last_date).format("MM-DD-YYYY") : ""}
                            </div>
                          </div>
                          <br />

                          <br /> <br />

                          <div className='header-title'>API Data Access</div>
                          <br />
                          <div className="row">
                            <div className="col-25">
                              <label htmlFor="lname">Access Key :</label>
                            </div>
                            <div className="col-75-button">
                              {accessKey !== null ?
                                (
                                  <div className={'copy-acesskey-button'}>
                                    <Input
                                      className="access-key-input"
                                      id="accessKey"
                                      placeholder="accesskey"
                                      value={accessKey}
                                      disabled={true}
                                    />
                                    <Button onClick={(e) => this.handleCopy(e)}><Icon type="copy" /></Button>
                                  </div>
                                ) : (
                                  <div className="row">Not Applicable</div>
                                )}
                            </div>
                          </div>
                          <br />

                          <div className="row">
                            <div className="col-25">
                              <label htmlFor="lname">API Documentation :</label>
                            </div>
                            <div className="col-75">
                              {isShowLink ?
                                <a href={this.state.envList['REACT_APP_REST_API_DOCUMENTATION'] ? this.state.envList['REACT_APP_REST_API_DOCUMENTATION'] : 'https://documenter.getpostman.com/view/13853916/TVzSkHJu'} rel="noopener noreferrer" target="_blank" style={{ color: '#5FA30F' }} > Rest Api sample</a>
                                : <div className="row">Not Applicable</div>}
                            </div>
                          </div>
                          <br />
                        </div>
                      </>
                      :
                      <span>
                        <Button type="link"
                          className='buttonAsLink'
                          onClick={() => this.handleSubscribe()}
                          style={{
                            pointerEvents: "inherit",
                            color: "#5FA30F",
                            fontSize: '18px'
                            // marginRight: '0px'
                          }}>
                          Visit the subscription page to subscribe
                        </Button>
                      </span>

                    }
                  </div>
                </Panel>
              </Collapse>

            </Col>
            <Col xl={8} sm={24} xs={24}>
              <Collapse expandIconPosition={'right'} className='data-collapse-wrraper'>
                <Panel header="Monthly Data Usage and Billing" className='data-collapse-header' key="1">
                  <div className='card-default-height'>
                    {subscription ?
                      <>
                        <div className="container raw-container-wrraper">

                          {(subscriptionType === 'ENTERPRISE' || totalEnterpriseAmount > 0) &&
                            <>
                              <div className='header-title'>Report Data Usage For Enterprise Plan</div>
                              <br />

                              <div className="row">
                                <div className="col-60">
                                  <label htmlFor="fname">Report Records Used :</label>
                                </div>
                                <div className="col-40">
                                  {Number(totalEnterpriseRecords) ? Number(totalEnterpriseRecords) : 0}
                                </div>
                              </div>
                              <br />

                              <div className="row">
                                <div className="col-60">
                                  <label htmlFor="lname">Per Record Cost :</label>
                                </div>
                                <div className="col-40">
                                  {amountPerEnterpriseRecord.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                </div>
                              </div>
                              <br />


                              <div className="row">
                                <div className="col-60">
                                  <label htmlFor="lname">Report Data Subtotal :</label>
                                </div>
                                <div className="col-40">
                                  {totalEnterpriseAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                </div>
                              </div>
                              <br /> <br />
                            </>
                          }

                          {(subscriptionType === 'PREMIUM' || totalPremiumAmount > 0) &&
                            <>
                              <div className='header-title'>Report Data Usage For Premium Plan</div>
                              <br />

                              <div className="row">
                                <div className="col-60">
                                  <label htmlFor="fname">Report Records Used :</label>
                                </div>
                                <div className="col-40">
                                  {Number(totalPremiumRecords) ? Number(totalPremiumRecords) : 0}
                                </div>
                              </div>
                              <br />

                              <div className="row">
                                <div className="col-60">
                                  <label htmlFor="lname">Per Record Cost :</label>
                                </div>
                                <div className="col-40">
                                  {amountPerPremiumRecord.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                </div>
                              </div>
                              <br />


                              <div className="row">
                                <div className="col-60">
                                  <label htmlFor="lname">Report Data Subtotal :</label>
                                </div>
                                <div className="col-40">
                                  {totalPremiumAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                </div>
                              </div>
                              <br /> <br />
                            </>
                          }

                          {subscriptionType === 'BASIC' &&
                            <>
                              <div className='header-title'>Report Data Usage For Basic Plan</div>
                              <br />

                              <div className="row">
                                <div className="col-60">
                                  <label htmlFor="fname">Report Records Used :</label>
                                </div>
                                <div className="col-40">
                                  <div className="row">Not Applicable</div>
                                </div>
                              </div>
                              <br />

                              <div className="row">
                                <div className="col-60">
                                  <label htmlFor="lname">Per Record Cost :</label>
                                </div>
                                <div className="col-40">
                                  {amountPerBasicRecord.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                </div>
                              </div>
                              <br />

                              <div className="row">
                                <div className="col-60">
                                  <label htmlFor="lname">Report Data Subtotal :</label>
                                </div>
                                <div className="col-40">
                                  <div className="row">Not Applicable</div>
                                </div>
                              </div>
                              <br /> <br />
                            </>

                          }


                          <div className='header-title'>API Data Usage</div>
                          <br />
                          <div className="row">
                            <div className="col-60">
                              <label htmlFor="fname">API Records Used :</label>
                            </div>
                            <div className="col-40">
                              {isShowLink ?
                                (Number(totalAPIRecords) ? Number(totalAPIRecords) : 0)
                                : <div className="row">Not Applicable</div>}
                            </div>
                          </div>
                          <br />

                          <div className="row">
                            <div className="col-60">
                              <label htmlFor="lname">Per Record Cost :</label>
                            </div>
                            <div className="col-40">
                              {isShowLink ?
                                (amountPerAPIRecord.toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
                                : <div className="row">Not Applicable</div>}
                            </div>
                          </div>
                          <br />

                          <div className="row">
                            <div className="col-60">
                              <label htmlFor="lname">API Data Subtotal :</label>
                            </div>
                            <div className="col-40">
                              {isShowLink ?
                                (totalAPIAmount.toLocaleString('en-US', { style: 'currency', currency: 'USD' }))
                                : <div className="row">Not Applicable</div>}
                            </div>
                          </div>
                          <br /> <br />
                          <div className='header-title'>Data Billing Total</div>
                          <br />
                          <div className="row">
                            <div className="col-60">
                              <label htmlFor="lname">Total :</label>
                            </div>
                            <div className="col-40">
                              {subscriptionType === 'BASIC' ? <div className="row">Not Applicable</div> : (totalAPIAmount + totalPremiumAmount + totalEnterpriseAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-60">
                              <label htmlFor="lname">User Credits :</label>
                            </div>
                            <div className="col-40">
                              {subscriptionType === 'BASIC' ? <div className="row">Not Applicable</div> : userCredits.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-60">
                              <label htmlFor="lname">Data Billing Date :</label>
                            </div>
                            <div className="col-40">
                              {subscriptionType === 'BASIC' ? <div className="row">Not Applicable</div> : subscription && subscription.paymentSchedule.last_date ? moment(subscription.paymentSchedule.last_date).format("MM-DD-YYYY") : "Not Applicable"}
                            </div>
                          </div>
                        </div>


                      </>
                      :
                      <span>
                        <Button type="link"
                          className='buttonAsLink'
                          onClick={() => this.handleSubscribe()}
                          style={{
                            pointerEvents: "inherit",
                            color: "#5FA30F",
                            fontSize: '18px'
                            // marginRight: '0px'
                          }}>
                          Visit the subscription page to subscribe
                        </Button>
                      </span>

                    }
                  </div>
                </Panel>
              </Collapse>
            </Col>


            <Col xl={8} sm={24} xs={24}>
              <Collapse expandIconPosition={'right'} className='data-collapse-wrraper'>
                <Panel header="Billing Preferences" className='data-collapse-header' key="1">
                  <div className=' card-default-height'>

                    {subscription ?
                      <>
                        <div className="container raw-container-wrraper">
                          <div className='header-title'>Billing Method</div>
                          <br />
                          <div className="row">
                            <div className="col-25">
                              <label htmlFor="lname">Type :</label>
                            </div>
                            <div className="col-75">
                              {isBasic ? 'Not Applicable' : 'Card'}
                            </div>
                          </div>
                          <br />
                          <div className="row">
                            <div className="col-25">
                              <label htmlFor="lname">Card Number :</label>
                            </div>
                            <div className="col-75">
                              {isBasic ? 'Not Applicable' : paymentMethod}
                            </div>
                          </div>
                          {/* <br />
                          <div className="row">
                            <div className="col-25">
                              <label htmlFor="lname">Card Expiration :</label>
                            </div>
                            <div className="col-75">
                              {cardExpiration}
                            </div>
                          </div> */}
                          <br />
                          <div className="row">
                            <div className="col-25">
                              <label htmlFor="lname">Card Details :</label>
                            </div>

                            {isBasic ? 'Not Applicable' :
                              <div className="col-75-button">
                                <Button type="link"
                                  className='buttonAsLink'
                                  onClick={() => this.changeCardDetails()}
                                  style={{
                                    pointerEvents: "inherit",
                                    color: "#5FA30F",
                                  }}
                                >
                                  Update Card Details
                                </Button>
                              </div>}
                          </div>
                          <br />
                        </div>
                      </>
                      :
                      <span>
                        <Button type="link"
                          className='buttonAsLink'
                          onClick={() => this.handleSubscribe()}
                          style={{
                            pointerEvents: "inherit",
                            color: "#5FA30F",
                            fontSize: '18px'
                          }}>
                          Visit the subscription page to subscribe
                        </Button>
                      </span>

                    }
                  </div>
                </Panel>
              </Collapse>
            </Col>

          </Row>

          <Collapse expandIconPosition={'right'} className='data-collapse-wrraper'>
            <Panel header="Paid Invoices" className='data-collapse-header' key="1">
              {subscription &&
                <>
                  <div className='header-title'>Paid Invoices (Updates Monthly)</div>
                  <br /> <br />
                  <DataTable
                    className={'profile-page-table'}
                    data={this.state.tableData}
                    onShowSizeChange={(current, pageSize) => this.onSizeChange(current, pageSize)}
                    onChange={(pageNumber) => this.onPageNumberChange(pageNumber)}
                    pageTotal={this.state.total}
                    columns={this.columns}
                  />
                </>
              }

            </Panel>
          </Collapse>

          <Modal
            title="Vertically centered modal dialog"
            centered
            visible={this.state.isShowModal}
            onOk={() => this.confirmCancelSubscription()}
          >
            <div className='confirm-popup-content2' style={{ display: 'flex' }}>
              <div className='report-modal-content'>
                <p className='confirm-popup-img'> <img alt="" src={require("assets/images/canoja-leaf-gold.png")} /></p>
              </div>
              <div className='report-modal-content confirm-popup-text'>
                <p>  <p dangerouslySetInnerHTML={{ __html: this.state.text }} />
                </p>
              </div>
            </div>
            <div className='modal-button'>
              <Button onClick={() => this.confirmCancelSubscription()} type="primary">Ok</Button>
              <Button onClick={() => { this.setState({ isShowModal: false }) }}>Cancel</Button>
            </div>
          </Modal>
          {this.state.isShowCardModal &&
            <CardDetailsPopup paymentProfileId={this.state.paymentProfileId} confirmCardDetailsChange={() => this.confirmCardDetailsChange()} cancelCardDetailsChange={this.cancelCardDetailsChange} />
          }


        </Spin>
      </Auxiliary >
    )
  }
}


const ProfilePage = Form.create()(Profile);

const mapStateToProps = (state) => ({
  table: state.table,
  isCurrentlyLogged: state.table.isCurrentlyLogged
});

export default connect(mapStateToProps, {
  userSignOutSuccess,
  userSignOutSuccessData,
  setSubscriptionChange,
  setTimeOut
})(withRouter(ProfilePage));
