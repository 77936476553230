import React, { useEffect } from "react";
import { Layout } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { Link } from "react-router-dom";
import HorizontalNav from "./HorizontalNav";
import { toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
import { signOut } from "../../appRedux/amplify";
import { userSignOutSuccess } from "../../appRedux/actions/Auth";
// import { setTimeOut } from "../../../appRedux/actions/DataTable";
import { setTimeOut } from "../../appRedux/actions/SetTime";
import { userSignOutSuccessData } from "../../appRedux/actions/DataTable";
import ModalData from '../App/ModalData'
import { useState } from "react";
const { Header } = Layout;

const HorizontalDark = (props) => {

  const dispatch = useDispatch();
  const { authUserdata } = useSelector(({ table }) => table);
  const [visible, setVisible] = useState(false);
  const [visibledata, setVisibledata] = useState(false);
  const [headerImage, setHeaderImage] = useState(null);
  const [typeModal, setTypeModal] = useState(0);
  // const { type } = useSelector(({common}) => common);

  const navCollapsed = useSelector(({ settings }) => settings.navCollapsed);
  const history = useHistory();

  const signIn = () => {
    setTypeModal(1);
    setVisibledata(true)
    setVisible(true);
    // dispatch(showModal(1));
  }

  useEffect(() => {
    async function fetchData() {
      let sampleData = localStorage.getItem('generalSettings')
      if (sampleData) {
        sampleData = JSON.parse(sampleData)
        if (sampleData.headerImage && sampleData.headerImage !== 'null') {
          setHeaderImage(sampleData.headerImage)
        }
      }
    }
    fetchData();
  }, [])

  const handleCancel = () => {
    setVisible(false);
    setTypeModal(0);
  }
  const handelchangeModal = (data) => {
    setTypeModal(data);
    setVisibledata(true)
    setVisible(true);
  }

  const signUp = () => {
    setVisible(false);
    history.push("/subscriptions");
    setTypeModal(0);
    // dispatch(showModal(2));
  }

  const logOut = () => {
    if (window.sessionStorage.getItem('isProcessRunning') === 'true') {
      history.push("/home");
    } else {
      dispatch(setTimeOut(null))
      signOut(dispatch(userSignOutSuccess()));
      signOut(dispatch(userSignOutSuccessData()));
      window.sessionStorage.clear()
      history.push("/home");
    }
  }

  const profile = () => {
    history.push("/profile");
  }

  const user_token = window.sessionStorage.getItem('user_token')
  const isProcessRunning = window.sessionStorage.getItem('isProcessRunning')
  return (

    <div className="gx-header-horizontal gx-header-horizontal-dark">
      <Header
        className="gx-header-horizontal-main">
        <div className="gx-container">
          <div className="gx-header-horizontal-main-flex">
            <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3">
              <i className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  dispatch(toggleCollapsedSideNav(!navCollapsed));
                }}
              />

            </div>
            {/* <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo">
              <img alt="" src={require("assets/images/w-logo.png")}/></Link> */}
            <Link to="/home" className="gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo">
              <img className='gx-logo-image' alt="" src={require("assets/images/logo.png")} /></Link>
            <div className="gx-header-search gx-d-none gx-d-lg-flex gx-ml-auto right-logo">
              {/* <img alt="" src={require("assets/images/canoja-leaf-gold.png")} /> */}
              <img alt="" style={{ maxWidth: "none" }} src={headerImage ? headerImage : require("assets/images/CanojaLeaf.png")} />
              {/* <img alt="" src={require("assets/images/canoja-leaf-gold.png")}/> */}
            </div>
          </div>
          <div className='gx-d-none gx-d-lg-block'><h1 style={{ color: "#5FA30F", fontSize: '18px' }}> The Source for Cannabis License Data and Verification </h1></div>
        </div>
      </Header>
      <div className="gx-header-horizontal-nav gx-d-none gx-d-lg-block">
        <div className="gx-container">
          <div className="gx-header-horizontal-nav-flex">
            <HorizontalNav />
            <ul className="gx-header-notifications gx-ml-auto">
              {
                !authUserdata && user_token === null ?
                  <>
                    <li onClick={signIn} style={{ background: "none", marginRight: 5, fontSize: '14px' }}><span className="gx-pointer gx-d-block">LOGIN</span></li>
                    <span style={{ fontSize: 22 }}>|</span>
                    <li onClick={signUp} style={{ marginLeft: 5, fontSize: '14px' }}><span className="gx-pointer gx-d-block">REGISTER</span></li>
                  </> :
                  <>
                    <li onClick={profile} style={{ background: "none", marginRight: 5, fontSize: '14px' }}><span className="gx-pointer gx-d-block">PROFILE</span></li>
                    <span style={{ fontSize: 22 }}>|</span>
                    {isProcessRunning === 'true' ?
                      <Link to="/home" style={{ marginLeft: 5, fontSize: '14px', color: 'white' }}><span className="gx-pointer gx-d-block">LOG OUT</span></Link>
                      :
                      <li onClick={logOut} style={{ marginLeft: 5, fontSize: '14px' }}><span className="gx-pointer gx-d-block">LOG OUT</span></li>
                    }


                  </>
              }
            </ul>
            <span style={{ fontSize: 22, marginLeft: 5 }}>|</span>
            <Link to="/support" style={{ color: 'white', marginLeft: 5 }}><img alt="" style={{ width: '44px' }} src={require("assets/images/great_customer_service.png")} /></Link>
          </div>
        </div>
      </div>
      {visibledata ? <ModalData visible={visible} handleCancel={handleCancel} typeModal={typeModal} showmodaldata={(data) => handelchangeModal(data)} /> : null}
    </div>
  );
};
export default HorizontalDark;
