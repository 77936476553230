import React, { PureComponent } from 'react';
import { Col } from "antd";
import InputSelect from '../InputSelect/InputSelect';
import InputSwitch from '../InputSwitch/InputSwitch';
import InputText from '../InputText/InputText';
import InputDate from '../InputDate/InputDate'

export default class ColumnsWithFilter extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            tableList: [
                { name: 'License Information', id: 'LicenseInformation' },
            ],
            isChecked: false,
            connectorList: [
                { name: 'And', id: 'and' },
                { name: 'Or', id: 'or' },
            ],
            operatorList: [
                { name: 'Equal ', id: '=' },
                { name: 'Like ', id: 'LIKE' },
                { name: 'In ', id: 'in' },
                { name: 'Not Equal ', id: '<>' },
            ],
            filterObj: {},
            optionValueArray: ['licenseCategory', 'licenseStatus', 'businessState', 'licenseType', 'businessCountry', 'businessStructure', 'retail', 'medical'],
            dateValueArray: [ 'licenseIssueDate', 'licenseExpireDate']
        }

    }

    handleChange = (name, value) => {
        let filter = this.state.filterObj
        filter = { ...filter, key: this.props.index, [name]: value }
        this.setState({ filterObj: filter }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.props.index, filter)
            }
        })
    }

    handleChangeFilter = (field, name, value) => {
        this.setState({ isChecked: value })
        let filter = this.state.filterObj
        filter = { ...filter, key: this.props.index, [field]: name, active: value }
        this.setState({ filterObj: filter }, () => {
            if (this.props.onChange) {
                this.props.onChange(this.props.index, filter)
            }
        })
    }


    render() {
        const { isChecked, optionValueArray, dateValueArray } = this.state
        let isSelectable = false
        let isDate = false
        let isMatchFound = optionValueArray.filter(item => item === this.props.name)
        if (isMatchFound && isMatchFound.length > 0) {
            isSelectable = true
        } else {
            let isDateField = dateValueArray.filter(item => item === this.props.name)
            if (isDateField && isDateField.length > 0) {
                isDate = true
            }
        }
        return (
            <React.Fragment>
                <Col sm={24} xs={24} md={16} lg={8} xl={8} >
                    <InputSwitch
                        field={this.props.form}
                        label={this.props.name}
                        name={this.props.name}
                        validationMessage='Please input correct name'
                        requiredMessage='Please select name'
                        display={true}
                        required={true}
                        list={this.state.connectorList}
                        onChange={(e) => this.handleChangeFilter('columnName', this.props.name, e)}
                    />

                    {isChecked &&
                        <InputSelect
                            field={this.props.form}
                            label='Connectors'
                            name='connector'
                            validationMessage='Please input correct connector'
                            requiredMessage='Please select connector'
                            display={true}
                            required={true}
                            list={this.state.connectorList}
                            onChange={(e) => this.handleChange('connector', e)}
                        />
                    }

                    {isChecked &&
                        <InputSelect
                            field={this.props.form}
                            label='Operator'
                            name='operator'
                            validationMessage='Please input correct operator'
                            requiredMessage='Please select operator'
                            display={true}
                            required={true}
                            list={this.state.operatorList}
                            onChange={(e) => this.handleChange('operator', e)}
                        />
                    }

                    {isChecked &&
                        (isSelectable ?
                            <InputSelect
                                field={this.props.form}
                                label='Value'
                                name='filterValue'
                                validationMessage='Please input correct value'
                                requiredMessage='Please select value'
                                display={true}
                                showSearch={true}
                                required={true}
                                list={this.props.options[this.props.name]}
                                onChange={(e) => this.handleChange('filterValue', e)}
                            />
                            : isDate ?
                            <InputDate
                                field={this.props.form}
                                placeholder={isDate ? 'MM-DD-YYYY' : ''}
                                label='Value'
                                name='filterValue'
                                validationMessage='Please input correct value'
                                requiredMessage='Please select value'
                                display={true}
                                required={true}
                                onChange={(e) => this.handleChange('filterValue', e)}
                            /> 
                            : 
                            <InputText
                            field={this.props.form}
                            placeholder={isDate ? 'MM-DD-YYYY' : ''}
                            label='Value'
                            name='filterValue'
                            validationMessage='Please input correct value'
                            requiredMessage='Please select value'
                            display={true}
                            required={true}
                            onChange={(e) => this.handleChange('filterValue', e)}
                        /> 
                        )
                    }
                </Col>

            </React.Fragment>


        )
    }
}

