
import { API, graphqlOperation } from 'aws-amplify'
import { currentUser } from '../appRedux/amplify/index.js';
import { listAllBusinessCountry, listAllBusinessState, listAllBusinessStructure, listAllCreatedAt, listAllUpdatedAt, listAllLicenseCategroy, listAllLicenseStatus, listAllLicenseType, searchEnvVariables, } from "../graphql/queries.js";
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import { checkIsBasicPlan } from './SubscriptionService.js';
const biri = require('biri')

export const getFiltersForColumns = async (data, isPlanEnterprise) => {
    let licenseCategory = []
    let licenseStatus = []
    let licenseTypes = []
    let licenseStructure = []
    let licenseState = []
    let licenseCountry = []
    // let created_at = []
    // let updated_at = []

    await API.graphql(graphqlOperation(listAllLicenseCategroy, data))
        .then(result => {
            licenseCategory = result.data.listAllLicenseCategroy
        })
        .catch(err => {
            console.log(err)
        });

    await API.graphql(graphqlOperation(listAllLicenseStatus, data))
        .then(result => {
            licenseStatus = result.data.listAllLicenseStatus
        })
        .catch(err => {
            console.log(err)
        });

    if (isPlanEnterprise) {
        await API.graphql(graphqlOperation(listAllLicenseType, data))
            .then(result => {
                licenseTypes = result.data.listAllLicenseType
            })
            .catch(err => {
                console.log(err)
            });

        await API.graphql(graphqlOperation(listAllBusinessStructure, data))
            .then(result => {
                licenseStructure = result.data.listAllBusinessStructure
            })
            .catch(err => {
                console.log(err)
            });

        await API.graphql(graphqlOperation(listAllBusinessState, data))
            .then(result => {
                licenseState = result.data.listAllBusinessState
            })
            .catch(err => {
                console.log(err)
            });

        await API.graphql(graphqlOperation(listAllBusinessCountry, data))
            .then(result => {
                licenseCountry = result.data.listAllBusinessCountry
            })
            .catch(err => {
                console.log(err)
            });

        // await API.graphql(graphqlOperation(listAllCreatedAt, data))
        //     .then(result => {
        //         created_at = result.data.listAllCreatedAt
        //     })
        //     .catch(err => {
        //         console.log(err)
        //     });

        // await API.graphql(graphqlOperation(listAllUpdatedAt, data))
        //     .then(result => {
        //         updated_at = result.data.listAllUpdatedAt
        //     })
        //     .catch(err => {
        //         console.log(err)
        //     });

    }

    const category = licenseCategory.map(t => {
        return t.items;
    })
    const status = licenseStatus.map(t => {
        return t.items;
    })
    const type = licenseTypes.map(t => {
        return t.items;
    })
    const country = licenseCountry.map(t => {
        return t.items;
    })
    const state = licenseState.map(t => {
        return t.items;
    })
    const structure = licenseStructure.map(t => {
        return t.items;
    })
    // const createdAt = created_at.map(t => {
    //     return t.items;
    // }).filter(item => item !== null)
    // const updatedAt = updated_at.map(t => {
    //     return t.items;
    // }).filter(item => item !== null)

    const retail = ['True', 'False']
    const medical = ['True', 'False']

    return {
        category,
        status,
        type,
        country,
        state,
        structure,
        retail,
        medical,
        // createdAt,
        // updatedAt
    }

}

export const getUniqueID = async () => {
    return new Promise(async (resolve) => {
        const uniqueId = await biri()
        resolve(uniqueId)
    })
}

export const getUserValidation = async (bodyData, customerId) => {
    return new Promise(async (resolve) => {
        let isGuestUser = true
        var user_token = window.sessionStorage.getItem('user_token')
        if (user_token) {
            await currentUser()
                .then(async user => {
                    if (user) {
                        let subscriptionId = await sessionStorage.getItem('subscriptionId')
                        if (subscriptionId) {
                            if (subscriptionId.includes('BASIC')) {
                                isGuestUser = false
                                resolve({ isValidate: true, isSubscribed: true })
                            } else {
                                let result = await API.get('PaymentGatewayAPI', `/paymentgateway/subscription/${subscriptionId}`)
                                if (result.success) {
                                    let subscription = result.subscription.subscription
                                    if (subscription) {
                                        let isValidSubscriptionStatus = await checkSubscriptionStatus(subscription.status)
                                        if (isValidSubscriptionStatus === true) {
                                            isGuestUser = false
                                            resolve({ isValidate: true, isSubscribed: true })
                                        } else {
                                            isGuestUser = false
                                            resolve({ isValidate: true, isSubscribed: true, subscriptionStatus: isValidSubscriptionStatus })
                                        }
                                    }
                                }
                            }

                        } else {
                            let id = await window.sessionStorage.getItem('customerId')
                            if (id) {
                                customerId = id
                                let data = await checkIsBasicPlan(customerId)
                                if (data !== false && data && data.planType) {
                                    isGuestUser = false
                                    resolve({ isValidate: true, isSubscribed: true })
                                }
                            }
                        }
                    }
                })
        }

        if (isGuestUser) {
            let uniqueId = ''
            try {
                uniqueId = await biri()
            } catch {
                await FingerprintJS.load()
                    .then(fp => fp.get())
                    .then(async (result) => {
                        uniqueId = result.visitorId
                    })
            }
            if (uniqueId) {
                let body = {
                    uniqueId: uniqueId,
                    ...bodyData,
                    customerId: customerId
                }
                let res = await API.post('PaymentGatewayAPI', '/paymentgateway/addUsageControl', { body })
                if (res.success) {
                    resolve({ isValidate: true, isSubscribed: false, usage: res.usage, limit: res.limit })
                } else {
                    if (res.code && res.code === 'LIMIT_EXCEEDED') {
                        resolve({ isValidate: 'LIMIT_EXCEEDED', isSubscribed: false, usage: res.usage, limit: res.limit })
                    } else {
                        console.log(JSON.stringify(res))
                        resolve({ isValidate: false, isSubscribed: false })
                    }
                }
            } else {
                resolve({ isValidate: false, isSubscribed: false })
            }

        }
    })

}


export const getEnvByName = async (string) => {

    return new Promise(async (resolve) => {
        let envQuery = `SELECT * FROM EnvVariables WHERE name in (${string})`
        let envVariable = {
            EnvVariablesListQuery: {
                query: envQuery
            }
        }
        let Env = {}
        await API.graphql(graphqlOperation(searchEnvVariables, envVariable))
            .then(res => {
                if (res.data && res.data.searchEnvVariables && res.data.searchEnvVariables.items) {
                    let data = res.data.searchEnvVariables.items
                    for (let i = 0; i < data.length; i++) {
                        Env[data[i].name] = data[i].value
                    }
                    resolve(Env)
                }
            })
            .catch(err => {
                console.log(err)
                resolve(false)
            });
    })
}

export const checkSubscriptionStatus = (status) => {
    return new Promise(async (resolve) => {
        let response = true
        if (status === 'expired') {
            response = 'your subscription expired please start new subscription'
        } else if (status === 'suspended') {
            response = 'your subscription suspended please change payment details from Profile'
        } else if (status === 'canceled') {
            response = 'your subscription canceled please start new subscription'
        } else if (status === 'terminated') {
            response = 'your subscription terminated please start new subscription'
        }
        resolve(response)
    })
}
