import React, { PureComponent } from 'react';
import { DatePicker, Form } from "antd";
import moment from 'moment';


class InputDate extends PureComponent {
    handleChange = (e) => {
        if (this.props.onChange) {
            e = moment(new Date(e))
            e = e.format("MM-DD-YYYY")
            this.props.onChange(e)

        }
    }

    render() {
        const dateFormatList = ['MM/DD/YYYY', 'MM/DD/YY'];
        return (

            <Form.Item
                name={this.props.name}
                label={this.props.label}
                style={this.props.display ? '' : { display: 'none' }}
                rules={[{
                    required: this.props.required ? true : false,
                    message: this.props.requiredMessage
                }]}
            >
                 <DatePicker className='date-picker' style={{width:'100%'}} placeholder={this.props.placeholder} onChange={(e)=>{this.handleChange(e)}}  disabled={this.props.disabled} />
                {/* <Input placeholder={this.props.placeholder} onChange={(e)=>{this.handleChange(e)}} disabled={this.props.disabled} /> */}
            </Form.Item>


        )
    }
}


export default InputDate
