import React, { useEffect, useState } from "react";
import { Form, Row, Col, Card, Spin, message, Button, Modal, Icon, } from "antd";
import FormItem from "antd/lib/form/FormItem";
import Widget from "../../components/Widget";
import * as Amplify from 'aws-amplify';
import { API } from 'aws-amplify';
import { checkSubscriptionStatus } from "../../service/GeneralService.js";
import { addUsageForCoupon, cancelSubscription, checkIsBasicPlan, getSelectedPlan } from "../../service/SubscriptionService";
import { userSignOutSuccess } from "../../appRedux/actions/Auth";
import { userSignOutSuccessData } from "../../appRedux/actions/DataTable";
import { signOut } from "appRedux/amplify";
import { useDispatch } from "react-redux";
import { setSubscriptionChange } from 'appRedux/actions/DataTable';
import RouteLeavingGuard from "../../components/RouteLeavingGuard/RouteLeavingGuard";
import './index.css'

const CheckOutDetails = (props) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [info, setInfo] = useState('')
    const [cardInfo, setCardInfo] = useState('')
    const [costInfo, setCostInfo] = useState('')
    const [discountInPercentage, setDiscountInPercentage] = useState(0)
    const [interval, setInterval] = useState([])
    const [subscriptionDetail, setSubscriptionDetail] = useState('')
    const [subscriptionId, setSubscriptionId] = useState(null)
    const [customerId, setCustomerId] = useState(null)
    const [loadingText, setLoadingText] = useState('Loading...')
    const [isShowPrompt, setIsShowPrompt] = useState(true);
    const [isBasic, setIsBasic] = useState(false);
    const [isShowPopup, setIsShowPopup] = useState(false)
    const [alertMessage, setAlertMessage] = useState('Something bad happen on server');
    const [subscriptionInterruption, setSubscriptionInterruption] = useState('changeing page, your data will be removed');
    const [isSubscriptionExpired, setIsSubscriptionExpired] = useState(false)
    const [planData, setPlanData] = useState({})
    const [startSubscriptionMessage, setStartSubscriptionMessage] = useState('')
    const [upgradeSubscriptionMessage, setUpgardeSubscriptionMessage] = useState('')
    const [previousPlanRecordCost, setPreviousPlanRecordCost] = useState('')
    const [cardErrors, setCardErrors] = useState({})
    const [cardErrorMessage, setCardErrorMessage] = useState(null)
    const [selectedPlan, setSelectedPlan] = useState('')

    useEffect(() => {
        async function getUserData() {
            setLoading(true)
            let data = localStorage.getItem('alertSettings')
            if (data) {
                data = JSON.parse(data)
                if (data) {
                    setSubscriptionInterruption(data.subscriptionInterruption)
                    setCardErrors(data)
                }
                if (data.genericAlert) {
                    setAlertMessage(data.genericAlert)
                }

            }
            if (props.location.state.SubscriptionCost.planType) {
                let selectedPlane = props.location.state.SubscriptionCost.planType
                setSelectedPlan(selectedPlane)
                let planData = (props.location.state.planData && props.location.state.planData[0]) || {}
                setPlanData(planData)
            }
            let subscriptionSettings = localStorage.getItem('subscriptionSettings')
            if (subscriptionSettings) {
                subscriptionSettings = JSON.parse(subscriptionSettings)
                if (subscriptionSettings.startSubscriptionMessage) {
                    setStartSubscriptionMessage(subscriptionSettings.startSubscriptionMessage)
                }
                if (subscriptionSettings.upgradeSubscriptionMessage) {
                    setUpgardeSubscriptionMessage(subscriptionSettings.upgradeSubscriptionMessage)
                }
            }
            Amplify.Auth.currentAuthenticatedUser()
                .then(async user => {
                    if (user) {
                        Amplify.Auth.userAttributes(user)
                            .then(async userAttributes => {
                                let customerId = window.sessionStorage.getItem('customerId')
                                setCustomerId(customerId)
                                if (userAttributes && userAttributes.length > 0) {
                                    let obj = {};
                                    for (let i = 0; i < userAttributes.length; i++) {
                                        obj = { ...obj, [userAttributes[i]["Name"]]: userAttributes[i]["Value"] }
                                    }
                                    setInfo(obj)
                                    // ({ info: obj, customerEmail: obj.email });
                                }
                            })
                    }
                })
        }
        async function getCardDetails() {
            if (props.location.state) {
                setLoading(true)
                if (props.location.state.subscriptionId && props.location.state.subscriptionId !== 'BASIC') {

                    let subscriptionId = props.location.state.subscriptionId
                    setSubscriptionId(props.location.state.subscriptionId)
                    let result = await API.get('PaymentGatewayAPI', `/paymentgateway/subscription/${subscriptionId}`)
                    if (result.success) {

                        let subscription = result.subscription.subscription
                        if (subscription) {
                            let isValidSubscriptionStatus = await checkSubscriptionStatus(subscription.status)
                            if (isValidSubscriptionStatus === true) {
                                if (subscription.profile && subscription.profile.paymentProfile && subscription.profile.paymentProfile) {
                                    let my_date = new Date()
                                    let last_date = new Date(my_date.getFullYear(), my_date.getMonth() + 1, 0);
                                    subscription.paymentSchedule['last_date'] = last_date.toString()
                                    let subscriptionName = subscription.name
                                    let obj = {
                                        ...subscriptionDetail, name: subscriptionName, ...subscription
                                    }
                                    getCostDetails(obj)
                                    if (subscriptionId) {
                                        let response = await getSelectedPlan(subscriptionId)
                                        if (response !== false) {
                                            let data = response[0].amountPerRecord
                                            setPreviousPlanRecordCost(data)
                                        }
                                    }
                                    let cardDetails = subscription.profile.paymentProfile.billTo
                                    let cardNumber = subscription.profile.paymentProfile.payment && subscription.profile.paymentProfile.payment.creditCard && subscription.profile.paymentProfile.payment.creditCard.cardNumber
                                    setCardInfo({ ...cardInfo, ...cardDetails, cardNumber: cardNumber })
                                }
                            } else {
                                setIsSubscriptionExpired(true)
                            }
                        }
                    }
                } else if (props.location.state.subscriptionId === 'BASIC') {
                    setSubscriptionId(props.location.state.subscriptionId)
                    let customerID = window.sessionStorage.getItem('customerId')
                    setLoading(true)
                    let res = await API.get('PaymentGatewayAPI', `/paymentgateway/customers/${customerID}`)
                    if (res.success) {
                        res = res.customer
                        let basicPlanData = await checkIsBasicPlan(customerID)
                        if (basicPlanData !== false) {
                            let recordData = basicPlanData.amountPerRecord
                            setPreviousPlanRecordCost(recordData)
                        }
                        if (props.location.state.cardData) {
                            setIsBasic(true)
                            getCostDetails({})
                            let cardData = props.location.state.cardData
                            setCardInfo({ ...cardInfo, ...cardData })
                            setLoading(false)
                        }
                    }
                } else {
                    if (props.location.state.cardData) {
                        getCostDetails({})
                        let cardData = props.location.state.cardData
                        setCardInfo({ ...cardInfo, ...cardData })
                        setLoading(false)
                    }
                }
            }
            setLoading(false)
        }


        getUserData()
        getCardDetails()
    }, [])

    const getCostDetails = async (obj) => {
        if (props.location.state && props.location.state.SubscriptionCost) {
            let data = props.location.state.SubscriptionCost
            let discountInPercentage = props.location.state.SubscriptionCost && props.location.state.SubscriptionCost.discountInPercentage
            let interval = props.location.state.SubscriptionCost && props.location.state.SubscriptionCost.interval
            setCostInfo(data)
            setDiscountInPercentage(discountInPercentage)
            setInterval(interval)
            let planData = (props.location.state.planData && props.location.state.planData[0]) || {}
            setPlanData(planData)
            if (props.location.state.subscriptionId === 'BASIC') {
                props.location.state.SubscriptionCost['name'] = 'BASIC'
                props.location.state.SubscriptionCost['amount'] = 'FREE'
                setSubscriptionDetail({ ...subscriptionDetail, ...obj, ...props.location.state.SubscriptionCost })
            } else {
                setSubscriptionDetail({ ...subscriptionDetail, ...obj, ...props.location.state.SubscriptionCost })
            }
        }
    }


    const handleSubmit = async () => {
        var user_token = window.sessionStorage.getItem('user_token')
        if (user_token && customerId && subscriptionId) {
            handleChangeSubscription(customerId)
        } else if (user_token && customerId) {
            handleCheckout()
        } else {
            setIsShowPrompt(false)
            sessionStorage.setItem('isProcessRunning', false)
            props.history.push('/subscription')
        }
    }

    const handleChangeSubscription = async (customerId) => {
        setLoading(true)
        let discount = subscriptionDetail.discount ? subscriptionDetail.discount : '0'
        if (subscriptionDetail.priceId === null || (subscriptionId && subscriptionDetail.planType === 'BASIC')) {
            let id = null
            if (subscriptionId) {
                id = subscriptionId
                var response = await cancelSubscription(id)
                if (response === 'logout') {
                    signOut(dispatch(userSignOutSuccess()));
                    signOut(dispatch(userSignOutSuccessData()));
                    window.sessionStorage.clear()
                    dispatch(setSubscriptionChange(true))
                    setIsShowPrompt(false)
                    sessionStorage.setItem('isProcessRunning', false)
                    props.history.push('/home')
                } else if (response === true) {
                    setIsShowPrompt(false)
                    sessionStorage.setItem('isProcessRunning', false)
                    setIsShowPopup(true)
                    setCardErrorMessage(null)
                    dispatch(setSubscriptionChange(true))
                } else {
                    message.error(<p dangerouslySetInnerHTML={{ __html: alertMessage }} />);
                }
            } else {
                message.error('Subscription Id not provided');
            }

        } else if (isBasic === true) {
            setLoading(true)
            if (props.location.state.setIsHaveCardDetail) {
                setLoadingText('Creating Subscription...')
                let body = {
                    price_id: subscriptionDetail.priceId,
                    subscription_name: subscriptionDetail.planType,
                    discount: discount,
                    customer_profile_id: customerId
                }
                let result = await API.post('PaymentGatewayAPI', `/paymentgateway/subscription/update/basic`, { body })
                if (result.success) {
                    await addUsageForCoupon(discount, subscriptionDetail.code)
                    dispatch(setSubscriptionChange(true))
                    setIsShowPrompt(false)
                    sessionStorage.setItem('isProcessRunning', false)
                    setIsShowPopup(true)
                    setCardErrorMessage(null)
                } else {
                    if (result.err && result.err.messages && result.err.messages.message && result.err.messages.message[0] && result.err.messages.message[0].code === 'E00013') {
                        let errMessage = result.err.messages.message[0].text
                        setLoading(false)
                        message.error(errMessage);
                    } else if (result.err.headers['content-length'] === '188') {
                        message.error('You can change plan interval after existing plan end')
                    } else {
                        message.error(<p dangerouslySetInnerHTML={{ __html: alertMessage }} />)
                    }
                    console.log(result.err)
                    setLoading(false)
                }
            } else {
                let expiryDate = cardInfo.cardExpiryDate.replace(/[^A-Z0-9]/ig, "");
                let card_number = cardInfo.cardNumber.replace(/[^A-Z0-9]/ig, "");
                let discount = subscriptionDetail.discount ? subscriptionDetail.discount : '0'
                let body = {
                    customer_profile_id: customerId,
                    card_no: card_number,
                    price_id: subscriptionDetail.priceId,
                    expiry_date: expiryDate,
                    subscription_name: subscriptionDetail.planType,
                    given_name: cardInfo.cardHolderFirstName,
                    family_name: cardInfo.cardHolderLastName,
                    address: cardInfo.address.substring(0, 59),
                    city: cardInfo.locality,
                    zip: cardInfo.postal_code,
                    country: cardInfo.country,
                    phone_no: cardInfo.phoneNo,
                    state: cardInfo.administrative_area_level_1,
                    discount: discount
                }
                setLoadingText('Creating Payment Profile...')
                await API.post('PaymentGatewayAPI', '/paymentgateway/createcustomerspaymentprofile', { body })
                    .then(async (result) => {
                        if (!result.err) {
                            setTimeout(async () => {
                                setLoadingText('Creating Subscription...')
                                let body = {
                                    price_id: subscriptionDetail.priceId,
                                    subscription_name: subscriptionDetail.planType,
                                    discount: discount,
                                    customer_profile_id: customerId
                                }
                                let result = await API.post('PaymentGatewayAPI', `/paymentgateway/subscription/update/basic`, { body })
                                if (result.success) {
                                    await addUsageForCoupon(discount, subscriptionDetail.code)
                                    dispatch(setSubscriptionChange(true))
                                    setIsShowPrompt(false)
                                    sessionStorage.setItem('isProcessRunning', false)
                                    setIsShowPopup(true)
                                    setCardErrorMessage(null)
                                } else {
                                    if (result.err && result.err.messages && result.err.messages.message && result.err.messages.message[0] && result.err.messages.message[0].code === 'E00013') {
                                        let errMessage = result.err.messages.message[0].text
                                        setLoading(false)
                                        message.error(errMessage);
                                    } else if (result.err.headers['content-length'] === '188') {
                                        message.error('You can change plan interval after existing plan end')
                                    } else {
                                        message.error(<p dangerouslySetInnerHTML={{ __html: alertMessage }} />)
                                    }
                                    console.log(result.err)
                                    setLoading(false)
                                }
                            }, 10000);
                        }
                    })
                    .catch((error) => {
                        setLoading(false)
                        console.log(error)
                        let errMessage = error.message
                        message.error(errMessage);
                    })
            }


        } else {
            let body = {
                price_id: subscriptionDetail.priceId,
                subscription_name: subscriptionDetail.planType,
                discount: discount,
                customer_profile_id: Number(customerId)
            }
            let result = await API.post('PaymentGatewayAPI', `/paymentgateway/updatesubscription/${subscriptionId}`, { body })
            if (result.success) {
                await addUsageForCoupon(discount, subscriptionDetail.code)
                dispatch(setSubscriptionChange(true))
                setIsShowPrompt(false)
                sessionStorage.setItem('isProcessRunning', false)
                setIsShowPopup(true)
                setCardErrorMessage(null)
            } else {
                if (result.err && result.err.messages && result.err.messages.message && result.err.messages.message[0] && result.err.messages.message[0].code === 'E00013') {
                    let errMessage = result.err.messages.message[0].text
                    setLoading(false)
                    message.error(errMessage);
                } else if (result.err.headers['content-length'] === '188') {
                    message.error('You can change plan interval after existing plan end')
                } else {
                    message.error(<p dangerouslySetInnerHTML={{ __html: alertMessage }} />)
                }
                console.log(result.err)
                setLoading(false)
            }
        }
        // setLoading(false)
    }

    const handleCheckout = async () => {

        if (selectedPlan !== 'BASIC') {
            let expiryDate = cardInfo.cardExpiryDate.replace(/[^A-Z0-9]/ig, "");
            let card_number = cardInfo.cardNumber.replace(/[^A-Z0-9]/ig, "");
            let discount = subscriptionDetail.discount ? subscriptionDetail.discount : '0'
            let body = {
                customer_profile_id: customerId,
                card_no: card_number,
                price_id: subscriptionDetail.priceId,
                expiry_date: expiryDate,
                subscription_name: subscriptionDetail.planType,
                given_name: cardInfo.cardHolderFirstName,
                family_name: cardInfo.cardHolderLastName,
                address: cardInfo.address.substring(0, 59),
                city: cardInfo.locality,
                zip: cardInfo.postal_code,
                country: cardInfo.country,
                phone_no: cardInfo.phoneNo,
                state: cardInfo.administrative_area_level_1,
                discount: discount
            }
            setLoading(true)
            setLoadingText('Creating Payment Profile...')
            await API.post('PaymentGatewayAPI', '/paymentgateway/createcustomerspaymentprofile', { body })
                .then(async (result) => {
                    if (!result.err) {
                        setTimeout(async () => {
                            setLoadingText('Creating Subscription...')
                            await API.post('PaymentGatewayAPI', '/paymentgateway/subscription', { body })
                                .then(async (result) => {
                                    if (!result.err) {
                                        await addUsageForCoupon(discount, subscriptionDetail.code)
                                        message.success('Requested subscription starts')
                                        window.sessionStorage.setItem('subscriptionId', result.subscription.subscriptionId)
                                        dispatch(setSubscriptionChange(true))
                                        setIsShowPrompt(false)
                                        sessionStorage.setItem('isProcessRunning', false)
                                        setIsShowPopup(true)
                                        setCardErrorMessage(null)
                                    } else {
                                        if (result.err && result.err.messages && result.err.messages.message && result.err.messages.message[0] && result.err.messages.message[0].code === 'E00013' || result.err.messages.message[0].code === 'E00027') {
                                            setLoading(false)
                                            handleShowCardErrorPopup('invalidCardErrorMessage')
                                        } else {
                                            setLoading(false)
                                            handleShowCardErrorPopup('generalCardErrorMessage')
                                        }
                                    }
                                    setLoading(false)
                                })
                        }, 10000);
                    } else {
                        setLoading(false)
                        let errMessage = result.message
                        if (result.err && result.err.messages && result.err.messages.message && result.err.messages.message[0] && result.err.messages.message[0].code === 'E00027') {
                            errMessage = result.err.messages.message[0].text
                        } else if (result.err && result.err.code && result.err.raw && result.err.raw.message) {
                            errMessage = result.err.raw.message
                        } else if (result.error && result.error.decline_code) {
                            errMessage = result.error.decline_code
                        }
                        if (errMessage) {
                            handleShowCardErrorPopup('invalidCardErrorMessage')
                        } else {
                            handleShowCardErrorPopup('generalCardErrorMessage')

                        }
                    }
                })
                .catch((error) => {
                    setLoading(false)
                    console.log(error)
                    let errMessage = error.message
                    if (error.err && error.err.messages && error.err.messages.message && error.err.messages.message[0] && error.err.messages.message[0].code === 'E00027') {
                        errMessage = error.err.messages.message[0].text
                    } else if (error.err && error.err.code && error.err.raw && error.err.raw.message) {
                        errMessage = error.err.raw.message
                    } else if (error.error && error.error.decline_code) {
                        errMessage = error.error.decline_code
                    }
                    message.error(errMessage);
                })


        } else {
            let body = {
                customer_profile_id: customerId,
                subscription_name: selectedPlan,
            }
            setLoading(true)
            await API.post('PaymentGatewayAPI', '/paymentgateway/customerspaymentprofile', { body })
                .then((result) => {
                    if (!result.err) {
                        message.success('Requested subscription starts')
                        dispatch(setSubscriptionChange(true))
                        setIsShowPrompt(false)
                        sessionStorage.setItem('isProcessRunning', false)
                        setIsShowPopup(true)
                        setCardErrorMessage(null)
                    } else {
                        console.log(result)
                        let errMessage = result.message
                        if (result.err && result.err.messages && result.err.messages.message && result.err.messages.message[0] && result.err.messages.message[0].code === 'E00027') {
                            errMessage = result.err.messages.message[0].text
                        } else if (result.err && result.err.code && result.err.raw && result.err.raw.message) {
                            errMessage = result.err.raw.message
                        } else if (result.error && result.error.decline_code) {
                            errMessage = result.error.decline_code
                        }
                        if (errMessage) {
                            setLoading(false)
                            handleShowCardErrorPopup('invalidCardErrorMessage')
                        } else {
                            setLoading(false)
                            handleShowCardErrorPopup('generalCardErrorMessage')
                        }
                    }
                    setLoading(false)
                })
                .catch((error) => {
                    console.log(error)
                    let errMessage = error.message
                    if (error.err && error.err.messages && error.err.messages.message && error.err.messages.message[0] && error.err.messages.message[0].code === 'E00027') {
                        errMessage = error.err.messages.message[0].text
                    } else if (error.err && error.err.code && error.err.raw && error.err.raw.message) {
                        errMessage = error.err.raw.message
                    } else if (error.error && error.error.decline_code) {
                        errMessage = error.error.decline_code
                    }
                    message.error(errMessage);
                })
        }

    }

    const handleCancelClick = () => {
        props.history.push('/home')
    }

    const goBack = () => {
        setLoading(true)
        setIsShowPrompt(false)
        setTimeout(() => {
            props.history.push({
                pathname: '/checkout',
                state: {
                    data: props.location.state && props.location.state.checkoutPageProps,
                    cardDetails: props.location.state && props.location.state.cardData
                }
            })
        }, 2000)
    }

    const handleShowCardErrorPopup = (name) => {
        setIsShowPopup(true)
        setCardErrorMessage(cardErrors[name])
    }

    const handlePopupClose = () => {
        setIsShowPopup(false)
        if (!cardErrorMessage) {
            props.history.push('/profile')
        }
    }

    const formItemLayout2 = {
        labelCol: {
            xs: { span: 12 },
            sm: { span: 8 },
            xl: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 10 },
            sm: { span: 10 },
            xl: { span: 12 },
            lg: { span: 12 },
            md: { span: 12 }
        },
        style: { marginBottom: '0px' }
    };

    let promptParams = {
        messageText: subscriptionInterruption,
        errorMessage: alertMessage,
        data: {
            email: window.sessionStorage.getItem("email"),
            customerId: customerId
        },
    };

    let basic = selectedPlan === 'BASIC' ? true : false

    return (
        <>
            <RouteLeavingGuard
                when={(subscriptionId || isSubscriptionExpired) ? false : isShowPrompt}
                navigate={path => props.history.push(path)}
                promptParams={promptParams}
                shouldBlockNavigation={location => {
                    if ((subscriptionId || isSubscriptionExpired) ? false : isShowPrompt) {
                        return true
                    }
                    return false
                }}
            />
            <div className="purchase-page">
                <Spin tip={loadingText} spinning={loading}>
                    <Card className="gx-card" >
                        <Row>
                            <Col xl={12} sm={24} xs={24}>

                                <Widget styleName="gx-card-profile-sm card-title-subscription">
                                    {/* <Button type="link" className='buttonAsLink' onClick={() => goBack()}>
                                        <Icon type="arrow-left" />
                                        <span className='left-side-title'>Back to Checkout</span>
                                    </Button> */}
                                    {/* <br></br><br></br> */}
                                    <div><b>PROFILE DETAILS</b></div>
                                    <FormItem
                                        {...formItemLayout2}
                                        label="First / Given Name"

                                    >
                                        {info && info.given_name}
                                    </FormItem>

                                    <FormItem
                                        {...formItemLayout2}
                                        label="Last / Family Name"

                                    >
                                        {info && info.family_name}
                                    </FormItem>

                                    <FormItem
                                        {...formItemLayout2}
                                        label="Email Address"

                                    >
                                        <div className="col-75">
                                            {info && info.email}
                                        </div>
                                    </FormItem>

                                    <FormItem
                                        {...formItemLayout2}
                                        label="Phone Number"

                                    >
                                        <div className="col-75">
                                            {info && info.phone_number}
                                        </div>
                                    </FormItem>

                                    <FormItem
                                        {...formItemLayout2}
                                        label="Company Name"

                                    >
                                        <div className="col-75">
                                            {info && info["custom:company-name"]}
                                        </div>

                                    </FormItem>

                                </Widget>
                                <Widget styleName="gx-card-profile-sm card-title-subscription" >
                                    <div><b>COST BREAK-DOWN</b></div>
                                    <FormItem
                                        {...formItemLayout2}

                                        label="Cost"
                                    >
                                        {basic ? 'FREE' : (costInfo.cost ? Number(costInfo.cost).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' per ' + interval : Number('0').toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' per ' + interval)}

                                    </FormItem>
                                    <FormItem
                                        {...formItemLayout2}

                                        label="Existing Discount"
                                    >
                                        {basic ? 'Not Applicable' : (costInfo.oldDiscount ? Number(costInfo.oldDiscount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' per ' + interval : Number('0').toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' per ' + interval)}
                                    </FormItem>
                                    <FormItem
                                        {...formItemLayout2}

                                        label="Discount"
                                    >
                                        {basic ? 'Not Applicable' : (costInfo.discount ? `${Number(costInfo.discount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })} ( ${discountInPercentage.toFixed(2)} % ) per ${interval}` : `${Number('0').toLocaleString('en-US', { style: 'currency', currency: 'USD' })} ( ${discountInPercentage.toFixed(2)} % ) per ${interval}`)}

                                    </FormItem>
                                    <hr className='cost-horizontal-line' />

                                    <FormItem
                                        {...formItemLayout2}

                                        label="Total"
                                    >
                                        {basic ? 'FREE' : (costInfo.totalCost ? (costInfo.totalCost ? (Number(costInfo.totalCost) - Number(costInfo.oldDiscount)).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' per ' + interval : '') : (costInfo.cost ? (Number(costInfo.cost) - Number(costInfo.oldDiscount)).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' per ' + interval : Number('0').toLocaleString('en-US', { style: 'currency', currency: 'USD' }) + ' per ' + interval))}

                                    </FormItem>
                                </Widget>
                            </Col>

                            <Col sm={24} xs={24} xl={12}>
                                {(subscriptionId || isSubscriptionExpired) &&
                                    <Widget styleName="gx-card-profile-sm subscriptionPage card-title-subscription">
                                        <div><b>OLD SUBSCRIPTION PLAN</b></div>
                                        < FormItem
                                            {...formItemLayout2}
                                            label="Current Plan"

                                        >

                                            {subscriptionDetail && subscriptionDetail.name}

                                        </FormItem>
                                        <FormItem
                                            {...formItemLayout2}
                                            label="Report Record Cost"

                                        >
                                            {previousPlanRecordCost ? Number(previousPlanRecordCost).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : ''}

                                        </FormItem>
                                        < FormItem
                                            {...formItemLayout2}
                                            label="Current Plan Price"

                                        >
                                            {subscriptionDetail.name === 'BASIC' ? subscriptionDetail.amount : (Number(subscriptionDetail.amount)).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
                                        </FormItem>
                                    </Widget>
                                }
                                <Widget styleName="gx-card-profile-sm subscriptionPage card-title-subscription">
                                    <div><b>NEW SUBSCRIPTION PLAN</b></div>
                                    <FormItem
                                        {...formItemLayout2}
                                        label="Subscription Type"
                                    >
                                        {selectedPlan || ''}

                                    </FormItem>
                                    <FormItem
                                        {...formItemLayout2}
                                        label="Subscription Term"
                                    >
                                        {basic ? 'Perpetual' : (interval === 'month' ? 'Monthly' : interval)}

                                    </FormItem>

                                    <FormItem
                                        {...formItemLayout2}
                                        label="Subscription Cost"
                                    >
                                        {basic ? 'FREE' : (costInfo.cost ? Number(costInfo.cost).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : Number('0').toLocaleString('en-US', { style: 'currency', currency: 'USD' }))}

                                    </FormItem>

                                    <FormItem
                                        {...formItemLayout2}
                                        label="Report Record Cost"

                                    >
                                        {basic ? '$4.00' : (planData.amountPerRecord ? Number(planData.amountPerRecord).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : '')}

                                    </FormItem>

                                    <FormItem
                                        {...formItemLayout2}
                                        label="API Record Cost"
                                    >
                                        {planData.APICost ? planData.APICost.toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : 'Not Applicable'}

                                    </FormItem>
                                </Widget>

                                <Widget styleName="gx-card-profile-sm subscriptionPage card-title-subscription">
                                    <div><b>CARD DETAILS</b></div>
                                    <FormItem
                                        {...formItemLayout2}
                                        label="Card Number"
                                    >
                                        {basic ? 'Not Applicable' : (cardInfo && cardInfo.cardNumber && `XXXX${cardInfo.cardNumber.slice(cardInfo.cardNumber.length - 5)}`)}

                                    </FormItem>
                                    <FormItem
                                        {...formItemLayout2}
                                        label="Card Holder First/Given Name"
                                    >
                                        {basic ? 'Not Applicable' : (cardInfo && cardInfo.cardHolderFirstName || cardInfo.firstName)}

                                    </FormItem>
                                    <FormItem
                                        {...formItemLayout2}
                                        label="Card Holder Last/Family Name"
                                    >
                                        {basic ? 'Not Applicable' : (cardInfo && cardInfo.cardHolderLastName || cardInfo.lastName)}
                                    </FormItem>

                                    {cardInfo && cardInfo.phoneNo &&
                                        <FormItem
                                            {...formItemLayout2}
                                            label="Phone-Number"
                                        >
                                            {basic ? 'Not Applicable' : (cardInfo && cardInfo.phoneNo)}
                                        </FormItem>}
                                    < FormItem
                                        {...formItemLayout2}
                                        label="Address"
                                    >
                                        {basic ? 'Not Applicable' : (cardInfo && cardInfo.address)}
                                    </FormItem>
                                    < FormItem
                                        {...formItemLayout2}
                                        label="City"
                                    >
                                        {basic ? 'Not Applicable' : (cardInfo && cardInfo.locality || cardInfo.city)}
                                    </FormItem>
                                    <FormItem
                                        {...formItemLayout2}
                                        label="State/Province"
                                    >
                                        {basic ? 'Not Applicable' : (cardInfo && cardInfo.administrative_area_level_1 || cardInfo.state)}
                                    </FormItem>
                                    <FormItem
                                        {...formItemLayout2}
                                        label="Country"
                                    >
                                        {basic ? 'Not Applicable' : (cardInfo && cardInfo.country)}
                                    </FormItem>
                                    <FormItem
                                        {...formItemLayout2}
                                        label="Zip/Postal Code"
                                    >
                                        {basic ? 'Not Applicable' : (cardInfo && cardInfo.zip)}
                                    </FormItem>
                                </Widget>

                            </Col>
                        </Row>
                        {/* <div style={{ textAlign: 'center', }} >
                            <Button style={{ width: '283px', fontSize: '12px' }} type="primary" onClick={handleSubmit} className="gx-mb-0 submit-button"  >
                                {subscriptionId ? 'Confirm Subscription Update' : 'Start Subscription'}
                            </Button>
                            <Button type="primary" className="gx-mb-0" onClick={handleCancelClick} >
                                Cancel
                            </Button>
                        </div> */}
                        <div
                            className=' button-div-checkout'
                        >
                            <Button className='checkout-btn' onClick={handleCancelClick} >
                                Cancel
                            </Button>
                            {!basic &&
                                <Button className='checkout-btn previous-btn' onClick={goBack} >
                                    Previous Step
                                </Button>}

                            <Button type="primary" className='checkout-btn' onClick={handleSubmit} htmlType="submit" >
                                {subscriptionId ? 'Confirm Subscription Update' : 'Start Subscription'}
                            </Button>

                        </div>
                    </Card>
                </Spin >
            </div >
            <Modal
                title="Vertically centered modal dialog"
                centered
                maskClosable={true}
                onCancel={() => handlePopupClose()}
                visible={isShowPopup}
            >
                <div className='subscription-purchase'>
                    <div className='subscription-purchase-icon-wrapper'>
                        <p><Icon className='subscription-purchase-icon' style={{ fontSize: '16px' }} type="exclamation-circle" /></p>
                    </div>
                    <div className='subscription-purchase-content'>
                        {cardErrorMessage ? <p dangerouslySetInnerHTML={{ __html: cardErrorMessage }} /> : (subscriptionId ? <p dangerouslySetInnerHTML={{ __html: upgradeSubscriptionMessage }} /> : <p dangerouslySetInnerHTML={{ __html: startSubscriptionMessage }} />)}

                    </div>
                </div>
                <div className='modal-button'>
                    <Button onClick={() => handlePopupClose()} type="primary">Ok</Button>
                </div>
            </Modal>
        </>
    );


}

const CheckOutDetailsPage = Form.create()(CheckOutDetails);


const CheckOutDetailsPageElement = (props) => (
    <CheckOutDetailsPage {...props} />
);

export default (CheckOutDetailsPageElement);