import React from "react";
import { Button, Form, Input, message, Checkbox } from "antd";
import { connect } from "react-redux";

import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userTwitterSignIn,
  hideAuthLoader,
  userSignInSuccess,

} from "appRedux/actions/Auth";
import {
  showModal
} from "appRedux/actions/Common";
import {
  setCurrentlyLoggedUser
} from "appRedux/actions/DataTable";
import {
  setTimeOut
} from "appRedux/actions/SetTime";
import { toProfile, userSignInSuccessData } from "appRedux/actions/DataTable";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";
import * as Amplify from 'aws-amplify';
import notify from '../Notification';
import { API, graphqlOperation } from "aws-amplify";
import { getUserByEmail } from '../graphql/queries'
import { createAuthorizeCustomer } from '../service/SubscriptionService'
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { withRouter } from "react-router-dom";

const FormItem = Form.Item;

class SignIn extends React.Component {

  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    const { cookies } = props;
    this.state = {
      loading: false,
      visible: true,
      loader: false,
      isDisable: false,
      isRememberMe: false,
      loginSuccess: 'Signin has been successfully done',
      loginError: 'something bad happen',
      email: cookies.get('userEmailCookie') || null
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    let data = localStorage.getItem('alertSettings')
    if (data) {
      data = JSON.parse(data)
      if (data) {
        this.setState({ loginSuccess: data.loginSuccess, loginError: data.loginError })
      }
    }
  }


  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({ loader: true, isDisable: true })
        // this.props.showAuthLoader();
        // this.props.userSignIn(values);
        this.signIn(values.email, values.password);
      }
    });
  };

  signIn = (username, password) => {
    Amplify.Auth.signIn(username, password)
      .then(async (success) => {

        // this.props.hideAuthLoader();
        this.props.userSignInSuccessData(success);
        this.props.setTimeOut(Date.now())
        let customer = {
          email: username
        }
        await API.graphql(graphqlOperation(getUserByEmail, customer))
          .then(async (result) => {
            result = result.data.getUserByEmail
            if (result) {
              window.sessionStorage.setItem('customerId', result.customerProfileId)
              window.sessionStorage.setItem('accessKey', result.accessKey)
              var res = await API.get('PaymentGatewayAPI', `/paymentgateway/customers/${result.customerProfileId}`,)
              if (res.success) {
                if (this.state.isRememberMe) {
                  const { cookies } = this.props;
                  cookies.set('userEmailCookie', username, { path: '/' });
                }
                res = res.customer
              } else {
                res = null
              }
              if (res && res.subscriptionIds && res.subscriptionIds.subscriptionId.length > 0) {
                  window.sessionStorage.setItem('subscriptionId', res.subscriptionIds.subscriptionId[0])
              }
            } else {
              await createAuthorizeCustomer(username)
            }
          })
          .catch(err => {
            console.log(err)
            window.sessionStorage.setItem('subscriptionId', null)
            window.sessionStorage.setItem('secondarySubscriptionId', null)
          });

        this.props.setCurrentlyLoggedUser(true)

        window.sessionStorage.setItem("user_data", success);
        sessionStorage.setItem('isDiscountIsApplied', 'false')
        window.sessionStorage.setItem("user_token", success.signInUserSession.accessToken.jwtToken);
        if (this.props.authPurchase) {
          this.props.toProfile(false);
          this.props.handleCancel();
        } else {
          this.props.handleCancel();
        }
        notify.openNotificationWithIcon('success', 'Success',  <p dangerouslySetInnerHTML={{ __html: this.state.loginSuccess }} />);
        this.setState({ loader: false, isDisable: false })
      })
      .catch(err => {
        notify.openNotificationWithIcon('error', 'Fail', <p dangerouslySetInnerHTML={{ __html: this.state.loginError }}/>);
        // this.props.hideAuthLoader();
        console.log(err)
        this.setState({ loader: false, isDisable: false })
        if (err.message === "User is not confirmed.") {
          const email = username;
          Amplify.Auth.resendSignUp(email, {})
            .then(success => {
              window.sessionStorage.setItem("email", username);
              this.props.showModal(3);
            })
            .catch(err => {
              console.log(err)
            })
        }
      })
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = () => {
    this.setState({ loading: true });
    setTimeout(() => {
      this.setState({ loading: false, visible: false });
    }, 3000);
  };

  handleCancel = () => {
    this.setState({ visible: false });
    this.props.showModal(0)
  };

  handleRememberMe = () => {
    this.setState({ isRememberMe: !this.state.isRememberMe })
  }

  handleSignupClick = () => {
    this.props.handleCancel();
    this.props.history.push("/subscriptions");
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { showMessage, alertMessage } = this.props;

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
              </div>
              <div className="gx-app-logo" style={{ marginBottom: "auto" }}>
                <img alt="example" src={require("assets/images/logo.png")} />
              </div>
              <div className="gx-app-logo-wid" style={{ marginBottom: 0 }}>
                <h1><IntlMessages id="app.userAuth.signIn" /></h1>
                <p><IntlMessages id="app.userAuth.bySigning" /></p>
                {/* <p><IntlMessages id="app.userAuth.getAccount" /></p> */}
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('email', {
                    initialValue: this.state.email,
                  })(
                    <Input placeholder="Enter Email Address" />
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Please input your Password!' }],
                  })(
                    <Input.Password placeholder="Enter Password" />
                  )}
                </FormItem>
                <FormItem

                >
                  {getFieldDecorator('Remember me')(
                    <Checkbox checked={this.state.isRememberMe} onChange={this.handleRememberMe}>Remember me</Checkbox>
                  )}

                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit" disabled={this.state.isDisable}>
                    <IntlMessages id="app.userAuth.signIn" />
                  </Button>
                  <span className="gx-pointer gx-d-block"><li style={{ marginLeft: 5, marginTop: 5, color: '#5FA30F' }} onClick={() => this.handleSignupClick()}>
                    or <IntlMessages id="app.userAuth.signUp" /></li></span>
                </FormItem>
                {/* <div style={{ display: 'flex' }} className="gx-flex-row gx-justify-content-between">

                </div> */}
                <div style={{ display: 'flex' }} className="gx-flex-row gx-justify-content-between">
                  <span className="gx-pointer gx-d-block"><li style={{ marginLeft: 5, color: '#5FA30F' }} onClick={() => { this.props.showmodaldata(4) }}>or Recover Password</li></span>
                </div>
              </Form>
            </div>

            {this.state.loader ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>
        </div>

      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({ auth, table }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  const { authPurchase } = table
  return { loader, alertMessage, showMessage, authUser, authPurchase }
};

const WrappedCookies = withCookies(WrappedNormalLoginForm)

export default connect(mapStateToProps, {
  setCurrentlyLoggedUser,
  setTimeOut,
  userSignIn,
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn,
  hideAuthLoader,
  userSignInSuccess,
  toProfile,
  showModal,
  userSignInSuccessData
})(withRouter(WrappedCookies));
