import React from "react";
import 'jspdf-autotable'
import Widget from "../../../components/Widget";
import { Form, Row, Button, Col, message, Spin, } from "antd";
import InputSelect from "../../../components/InputControl/InputSelect/InputSelect";
import MultipleSelect from "../../../components/InputControl/MultipleSelect/MultipleSelect";
import { API, graphqlOperation } from "aws-amplify";
import ColumnsWithFilter from "../../../components/InputControl/ColumnsWithFilter/ColumnsWithFilter";
import '../index.css'
import { getColumns, getFilterForCustomQuery, getSelectedColumns } from "../../../service/ReportService";
import DataTable from "../../../components/Table/Table";
import Search from "antd/lib/input/Search";
import InputText from "../../../components/InputControl/InputText/InputText";
import { isEnterprisePlan } from "../../../service/SubscriptionService";
import { listAllLicenseCategroy, listAllLicenseStatus, listAllLicenseType, listAllBusinessCountry, listAllBusinessState, listAllBusinessStructure } from "../../../graphql/queries";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 9 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 15 },
  },
};

class CustomQuery extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      tableList: [
        { name: 'License Information', id: 'LicenseInformation' },
      ],
      data: null,
      defaultColumns: [],
      tableColumnsList: null,
      selectedColumns: null,
      filters: [],
      sortingOrderList: [
        { name: 'Ascending Order', id: 'ASC' },
        { name: 'descending Order', id: 'DESC' },
        { name: 'None', id: null },
      ],
      sortedColumn: null,
      sortingOrder: null,
      tableName: null,
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      columns: null,
      queryName: null,
      disabled: true,
      alertMessage: 'Something bad happen on server',
      options: {
        licenseCategory: [],
        licenseStatus: [],
        businessState: [],
        licenseType: [],
        businessCountry: [],
        businessStructure: [],
        retail: [{ id: true, name: 'True', }, { id: false, name: 'False' }],
        medical: [{ id: true, name: 'True', }, { id: false, name: 'False' }],
      }
    }

  }

  componentDidMount() {
    this.checkIsValidPlan()
  }

  checkIsValidPlan = async () => {
    this.setState({ loading: true })
    let alertData = localStorage.getItem('alertSettings')
    if (alertData) {
      alertData = JSON.parse(alertData)
      if (alertData.genericAlert) {
        this.setState({ alertMessage: alertData.genericAlert })
      }
    }
    let isPlanEnterprise = await isEnterprisePlan()
    if (!isPlanEnterprise) {
      this.props.history.push("/home");
    } else {
      this.fetchFieldOptions()
    }

  }

  fetchFieldOptions = async () => {
    let options = this.state.options

    await API.graphql(graphqlOperation(listAllLicenseCategroy, {}))
      .then(async (result) => {
        let licenseCategroyoptions = result.data.listAllLicenseCategroy
        licenseCategroyoptions = await this.formatOptions(licenseCategroyoptions)
        options['licenseCategory'] = licenseCategroyoptions
      })
      .catch(err => {
        console.log(err)
      });

    await API.graphql(graphqlOperation(listAllLicenseStatus, {}))
      .then(async (result) => {
        let licenseStatusOptions = result.data.listAllLicenseStatus
        licenseStatusOptions = await this.formatOptions(licenseStatusOptions)
        options['licenseStatus'] = licenseStatusOptions
      })
      .catch(err => {
        console.log(err)
      });

    await API.graphql(graphqlOperation(listAllLicenseType, {}))
      .then(async (result) => {
        let licenseTypeOptions = result.data.listAllLicenseType
        licenseTypeOptions = await this.formatOptions(licenseTypeOptions)
        options['licenseType'] = licenseTypeOptions
      })
      .catch(err => {
        console.log(err)
      });

    await API.graphql(graphqlOperation(listAllBusinessCountry, {}))
      .then(async (result) => {
        let businessCountryOptions = result.data.listAllBusinessCountry
        businessCountryOptions = await this.formatOptions(businessCountryOptions)
        options['businessCountry'] = businessCountryOptions
      })
      .catch(err => {
        console.log(err)
      });

    await API.graphql(graphqlOperation(listAllBusinessState, {}))
      .then(async (result) => {
        let businessStateOptions = result.data.listAllBusinessState
        businessStateOptions = await this.formatOptions(businessStateOptions)
        options['businessState'] = businessStateOptions
      })
      .catch(err => {
        console.log(err)
      });

    await API.graphql(graphqlOperation(listAllBusinessStructure, {}))
      .then(async (result) => {
        let businessStructureOptions = result.data.listAllBusinessStructure
        businessStructureOptions = await this.formatOptions(businessStructureOptions)
        options['businessStructure'] = businessStructureOptions
      })
      .catch(err => {
        console.log(err)
      });

    this.setState({ loading: false, options: options })
  }

  formatOptions = async (data) => {
    return data.map((item) => ({
      id: item.items,
      name: item.items,
    }));
  }

  fetchData = async () => {
    this.setState({ loadingData: true })
    let body = {
      query: this.state.query,
      page: this.state.pageNumber,
      size: this.state.pageSize
    }

    var response = await API.post('CustomerRestAPI', '/customers/getData', { body })
    if (response.success) {
      if (response.customQueryData.length > 0) {
        let columns = await getColumns(response.customQueryData[0])
        this.setState({ columns: columns })
      }
      this.setState({ data: response.customQueryData, total: response.total })
    } else {
      console.log(response.err)
      message.error(<p dangerouslySetInnerHTML={{ __html: this.state.alertMessage }} />)
    }
    this.setState({ loadingData: false })
  }

  selectTable = async (e) => {
    this.setState({ tableName: e }, () => {
      this.fetchTableColumns(e)
    })
  }

  fetchTableColumns = async (tableName) => {
    this.setState({ loading: true })
    let body = {
      tableName: tableName
    }
    var response = await API.post('CustomerRestAPI', '/customers/getColumnName', { body })
    if (response.success) {
      response = response.result
      let notNeededColumns = ['id', 'csvRowUniqueKey', 'active', 'last_updated', 'created_at', 'updated_at']

      response = await response.filter(function (el) {
        let matchIndex = notNeededColumns.findIndex(item => item === el.COLUMN_NAME)
        if (matchIndex > -1) {
          return false;
        } else {
          return true;
        }
        

      });
      let columns = await this.formatColumns(response)
      let defaultColumns = await this.formatColumnsDefaultValue(response)
      this.setState({ tableColumnsList: columns, defaultColumns: defaultColumns, selectedColumns: defaultColumns, disabled: false })
    } else {
      console.log(response.err)
      message.error(<p dangerouslySetInnerHTML={{ __html: this.state.alertMessage }} />)
    }
    this.setState({ loading: false })
  }

  formatColumns = async (data) => {
    return data.map((item, i) => {
      let defaultValue = this.state.defaultColumns
      defaultValue.push(item.COLUMN_NAME)

      return {
        ...item,
        name: item.COLUMN_NAME,
        id: item.COLUMN_NAME
      };
    });
  }

  formatColumnsDefaultValue = async (data) => {
    return data.map((item, i) => {
      return item.COLUMN_NAME
    });
  }

  onSelectedColumnChange = async (e) => {
    this.setState({ selectedColumns: e })
  }

  onFilterValueChange = async (index, filter) => {
    let filterArr = this.state.filters
    if (filterArr.length) {
      let objIndex = filterArr.findIndex((obj => obj.key === index));
      if (objIndex >= 0) {
        filterArr[objIndex] = filter
      } else {
        filterArr.push(filter)
      }
    } else {
      filterArr.push(filter)
    }
    this.setState({ filters: filterArr });
  }


  handleSave = async () => {
    this.setState({ loading: true })
    if (this.state.queryName && this.state.queryName !== '') {
      let selectedColumns = this.state.selectedColumns
      if (selectedColumns.length > 0) {
        let query = await this.getQuery()
        let filterForJson = await this.getFiltersForJson()
        let cusomerId = window.sessionStorage.getItem('customerId')
        filterForJson = JSON.stringify(filterForJson)
        let body = {
          userId: cusomerId,
          query: query,
          filter: filterForJson,
          name: this.state.queryName
        }
        var response = await API.post('CustomerRestAPI', '/customers/saveQuery', { body })
        if (response.success) {
          message.success('Query saved successfully')
        } else {
          console.log(response.err)
          message.error(<p dangerouslySetInnerHTML={{ __html: this.state.alertMessage }} />)
        }
      } else {
        message.error('please select at least one column')
      }
    } else {
      message.error('please Input Query name')
    }
    this.setState({ loading: false })
  }

  getQuery = async () => {
    let filters = this.state.filters
    filters = filters.filter(item => item.active === true && item.filterValue && item.filterValue !== '' && item.columnName)
    let filterString = ''
    if (filters.length > 0) {
      for (let i = 0; i < filters.length; i++) {
        let connector = filters[i].connector ? filters[i].connector : 'and'
        let operator = filters[i].operator ? filters[i].operator : '='
        let String = await getFilterForCustomQuery(filters[i].columnName, operator, filters[i].filterValue)
        if (i === 0) {
          filterString += ` where ${String}`
        } else {
          filterString += ` ${connector} ${String}`
        }
      }
    }

    let sorter = ''
    if (this.state.sortedColumn && this.state.sortingOrder) {
      sorter = ` order by ${this.state.sortedColumn} ${this.state.sortingOrder}`
    }

    let selectedColumns = await getSelectedColumns(this.state.selectedColumns)

    return `select ${selectedColumns} from ${this.state.tableName} ${filterString} ${sorter} `
  }

  getFiltersForJson = async () => {
    let filters = this.state.filters
    filters = filters.filter(item => item.active === true && item.filterValue && item.filterValue !== '' && item.columnName)
    let filterArr = []
    if (filters.length > 0) {
      for (let i = 0; i < filters.length; i++) {
        let value = filters[i].filterValue
        if (filters[i].operator === 'in') {
          value = value.split(",")
        }
        let obj = {
          connector: filters[i].connector,
          operator: filters[i].operator,
          [filters[i].columnName]: value
        }
        filterArr.push(obj)
      }
    }

    return filterArr
  }

  handleExcute = async () => {
    let query = await this.getQuery()
    this.setState({ query: query }, () => {
      this.fetchData()
    })
  }

  SelectForSorter = async (name, value) => {
    this.setState({ [name]: value })
  }

  filteredData = (data, value) => data.filter(elem => (
    Object.keys(elem).some(key => elem[key] != null ? elem[key].toString().toLowerCase().includes(value) : "")
  ));

  onSearchStringChange = event => {
    if (event.target.value === '') {
      this.setState({ searchText: null, filterdData: null })
      return;
    }
    const value = event.target.value.toLowerCase();
    const newData = this.filteredData(this.state.data, value);
    this.setState({ searchText: event.target.value, filterdData: newData })

  };

  onSearch = value => {
    if (value === '') {
      this.setState({ filterdData: null })
      return;
    }
    const newData = this.filteredData(this.state.data, value);
    this.setState({ searchText: value, filterdData: newData })
  };

  onPageNumberChange = (pageNumber) => {
    this.setState({ pageNumber: pageNumber }, () => {
      this.fetchData()
    })
  }

  onSizeChange = (current, pageSize) => {
    this.setState({ pageNumber: 1, pageSize: pageSize }, () => {
      this.fetchData()
    })
  }

  handleNameChange = (e) => {
    this.setState({ queryName: e })
  }

  handlePurchase = () => {
    window.sessionStorage.setItem("reportname", this.state.queryName)
    window.sessionStorage.setItem("name", this.state.queryName)
    window.sessionStorage.setItem("title", 'Custom Query List')
    window.sessionStorage.setItem('filter', this.state.query)
    window.sessionStorage.removeItem('sorter')
    this.props.history.push("/customquery/purchase");
  }

  render() {

    return (
      <>
        {/* {this.state.loading ? <LoadingBar content={this.state.loadingContent} /> : null} */}

        <Widget title="Custom Query">
          <Spin tip="Loading..." spinning={this.state.loading}>
            <Form {...formItemLayout} >
              <Row type="flex" justify="center">
                <Col sm={12} xs={24}>
                  <InputText
                    field={this.props.form}
                    label='Query Name'
                    name='queryName'
                    validationMessage='Please input correct name'
                    requiredMessage='Please select name'
                    display={true}
                    required={true}
                    onChange={(e) => this.handleNameChange(e)}
                  />
                </Col>
              </Row>
              <Row type="flex" justify="center">
                <Col sm={12} xs={24}>
                  <InputSelect
                    field={this.props.form}
                    label='Choose Table'
                    name='table'
                    validationMessage='Please input correct table'
                    requiredMessage='Please select table'
                    display={true}
                    required={true}
                    list={this.state.tableList}
                    onChange={(e) => this.selectTable(e)}
                  />
                </Col>
              </Row>

              {this.state.tableColumnsList &&
                <Row type="flex" justify="center">
                  <Col sm={12} xs={24}>
                    <MultipleSelect
                      field={this.props.form}
                      label='Choose Columns '
                      name='columns'
                      validationMessage='Please input correct table'
                      requiredMessage='Please select table'
                      display={true}
                      required={true}
                      defaultValue={this.state.defaultColumns}
                      list={this.state.tableColumnsList}
                      onChange={(e) => this.onSelectedColumnChange(e)}
                    />
                  </Col>
                </Row>
              }

              <Row>
                {this.state.tableColumnsList && this.state.tableColumnsList.map((item, index) => (
                  <ColumnsWithFilter
                    key={index}
                    form={this.props.form}
                    name={item.COLUMN_NAME}
                    index={index}
                    onChange={(index, filter) => this.onFilterValueChange(index, filter)}
                    options={this.state.options}
                  />
                )
                )}
              </Row>

              {this.state.tableColumnsList &&
                <Row type="flex" justify="center">
                  <Col sm={12} xs={24}>
                    <InputSelect
                      field={this.props.form}
                      label='Sorter Column Name'
                      name='sorterColumn'
                      validationMessage='Please input correct column'
                      requiredMessage='Please select column'
                      display={true}
                      required={true}
                      list={this.state.tableColumnsList}
                      onChange={(e) => this.SelectForSorter('sortedColumn', e)}
                    />
                  </Col>
                </Row>
              }

              {this.state.tableColumnsList &&
                <Row type="flex" justify="center">
                  <Col sm={12} xs={24}>
                    <InputSelect
                      field={this.props.form}
                      label='Sorting Order'
                      name='sortervalue'
                      validationMessage='Please input correct order'
                      requiredMessage='Please select order'
                      display={true}
                      required={true}
                      list={this.state.sortingOrderList}
                      onChange={(e) => this.SelectForSorter('sortingOrder', e)}
                    />
                  </Col>
                </Row>
              }
              <div className='buttons-group'>
                <Button type="primary" onClick={() => this.handleSave()} disabled={this.state.disabled}>Save</Button>
                <Button onClick={() => this.handleExcute()} disabled={this.state.disabled}>Execute</Button>
              </div>
            </Form>
          </Spin>

        </Widget>

        {this.state.data &&
          <Widget title="Executed Query Records">
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
              <Search
                placeholder={'Search'}
                onChange={this.onSearchStringChange}
                onSearch={this.onSearch}
                value={this.state.searchText}
                allowClear
                style={{ width: 500, marginRight: 15 }}
              />
              {this.state.data.length > 0 && <Button style={{ marginRight: 0 }} className='add-button' type="primary" onClick={() => this.handlePurchase()}>Purchase</Button>}
            </div>
            <DataTable
              data={this.state.filterdData || this.state.data}
              onShowSizeChange={(current, pageSize) => this.onSizeChange(current, pageSize)}
              onChange={(pageNumber) => this.onPageNumberChange(pageNumber)}
              pageTotal={this.state.total}
              columns={this.state.columns}
            />
          </Widget>

        }
      </>
    )
  }
}

const WrappedNormalCustomQuery = Form.create()(CustomQuery);

export default WrappedNormalCustomQuery;
