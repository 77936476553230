import React from "react";
import { Table, Pagination } from "antd";

const DataTable = (props) => {

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      onSelectionChange(selectedRowKeys, selectedRows);
    }
  };

  const onSelectionChange = (selectedRowKeys, selectedRows) => {
    props.onSelectionChange(selectedRowKeys, selectedRows)
  };

  return (
    <>
      {props.isSelectable ?
        <Table
          className="gx-table-responsive"
          columns={props.columns}
          dataSource={props.data}
          pagination={false}
          rowSelection={{
            type: 'radio',
            ...rowSelection,
          }}
        /> :
        <Table
          className="gx-table-responsive"
          columns={props.columns}
          dataSource={props.data}
          pagination={false}
        />
      }
      <Pagination
        defaultCurrent={1}
        total={props.pageTotal}
        onChange={(pageNumber) => props.onChange(pageNumber)}
        showSizeChanger
        onShowSizeChange={(current, pageSize) => props.onShowSizeChange(current, pageSize)}
        pageSizeOptions={['10', '15', '50', '100']}
      />
    </>
  );
};

export default DataTable;