import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const user_token = window.sessionStorage.getItem('user_token')
    return (
        <Route {...rest} render={props => (
            user_token ?
                <Component {...props} {...rest} />
                :
                <Redirect to="/home" />
        )} />
    );
};

export default PrivateRoute;