import React from "react";
import * as Amplify from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify'
import "./index.css";
import 'jspdf-autotable'
import {
    getData, setPendingReportToDB, updatePendingReportToDB, clearSessionForPayment,
    openSanckbarNotification, openSanckbarNotificationError, setSessionForPayment, getJSONData
} from '../../service/ReportService';
import {
    Button,
    message,
    Spin,
    Modal,
} from "antd";
import Widget from "../../components/Widget";
import { listPendingReportPagination, listPurchaseReportPagination } from '../../graphql/queries'
import Search from "antd/lib/input/Search";
import DataTable from './Table';
import { isEnterprisePlan } from "../../service/SubscriptionService";

const key = 'updatable';

class Report extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            authUser: '',
            user: '',
            purchasedList: [],
            pendingList: [],
            errorInReportGeneration: false,
            currentPurchaseReport: '',
            cancelAPiCall: false,
            loadingContent: '',
            isShowModal: false,
            totalPendingRecords: 0,
            pendigngPageSize: 10,
            totalPurchasedRecords: 0,
            purchaseSuccess: 'report generated successfuly',
            purchaseError: 'Error in report generation',
            purchasedPageSize: 10,
            currantPendingPage: 1,
            currantPurchasedPage: 1,
            isPremiumUser: false,
            generateMessage: 'report is generating..',
            alertMessage: 'Something bad happen on server',
            button: (
                <Button type="primary" size="small" onClick={() => this.props.history.push('/report')}>
                    Go to Report
                </Button>
            )
        }

        this.columns = [
            { title: 'Report Type', dataIndex: 'type', key: 'type' },
            { title: 'Report Name', dataIndex: 'reportname', key: 'reportname' },
            { title: 'Report Description', dataIndex: 'description', key: 'description' },
            { title: 'Purchase Date', dataIndex: 'purchase', key: 'purchase', },
            { title: 'Expiration Date', dataIndex: 'expiration', key: 'expiration' },
            { title: 'Amount', dataIndex: 'amount', key: 'amount', render: (text, index) => index.amount ? Number(index.amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : null },
            { title: 'Record Cost', dataIndex: 'recordCost', key: 'recordCost', render: (text, index) => index.recordCost ? Number(index.recordCost).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : null },
            { title: 'Promotion Code', dataIndex: 'promotionCode', key: 'promotionCode' },
            { title: 'Discount', dataIndex: 'discount', key: 'discount', render: (text, index) => index.discount ? `(-${Number(index.discount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })})` : null },
            { title: 'Total Paid Amount', dataIndex: 'totalAmount', key: 'totalAmount', render: (text, index) => index.totalAmount ? Number(index.totalAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : null },
            {
                title: 'Link',
                dataIndex: 'link',
                key: 'link',
                render: (text, record) => {
                    const date = new Date(record.expiration)
                    const today = new Date()
                    return <>
                        <Button type="link"
                            className='buttonAsLink'
                            onClick={(e) => this.downloadFile(record, record.csvFileName)}
                            style={{
                                pointerEvents: today > date && this.state.isPremiumUser ? "none" : "inherit",
                                color: today > date && this.state.isPremiumUser ? "#ccc" : "#5FA30F",
                                marginRight: '0px'
                            }}
                        >
                            CSV
                        </Button>

                        <span> | </span>
                        <Button type="link"
                            className='buttonAsLink'
                            onClick={(e) => this.downloadFile(record, record.pdfFileName)}
                            style={{
                                pointerEvents: today > date && this.state.isPremiumUser ? "none" : "inherit",
                                color: today > date && this.state.isPremiumUser ? "#ccc" : "#5FA30F",
                                marginRight: '0px'
                            }}
                        >
                            PDF
                        </Button>
                        { this.state.isPremiumUser &&
                            <>
                                <span> | </span>
                                <Button type="link"
                                    className='buttonAsLink'
                                    onClick={(e) => this.downloadJsonData(record)}
                                    style={{
                                        pointerEvents: today > date && this.state.isPremiumUser ? "none" : "inherit",
                                        color: today > date && this.state.isPremiumUser ? "#ccc" : "#5FA30F",
                                        marginRight: '0px'
                                    }}
                                >
                                    JSON
                                </Button>
                            </>
                        }
                    </>
                }
            },
        ]


        this.report_download_pending__columns = [
            { title: 'Report Name', dataIndex: 'reportName', key: 'reportName' },
            { title: 'Report Description', dataIndex: 'reportDescription', key: 'reportDescription' },
            { title: 'Purchase Date', dataIndex: 'purchaseDate', key: 'purchaseDate' },
            { title: 'Amount', dataIndex: 'amount', key: 'amount', render: (text, index) => index.amount ? Number(index.amount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : null },
            { title: 'Record Cost', dataIndex: 'recordCost', key: 'recordCost', render: (text, index) => index.recordCost ? Number(index.recordCost).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : null },
            { title: 'Promotion Code', dataIndex: 'promotionCode', key: 'promotionCode' },
            { title: 'Discount', dataIndex: 'discount', key: 'discount', render: (text, index) => index.discount ? `(-${Number(index.discount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })})` : null },
            { title: 'Total Paid Amount', dataIndex: 'totalAmount', key: 'totalAmount', render: (text, index) => index.totalAmount ? Number(index.totalAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : null },
            {
                title: 'Download Status',
                dataIndex: 'downloadStatus',
                key: 'downloadStatus',
                render: (text, record) => {
                    return <>
                        <Button type="link"
                            className='buttonAsLink'
                            onClick={(e) => this.downloadReport(record)}
                            style={{
                                pointerEvents: "inherit",
                                color: "#5FA30F",
                                marginRight: '0px'
                            }}
                        >
                            Generate Report
                        </Button>
                    </>
                }
            }
        ]
    }

    componentDidMount() {
        this.fetchErrorData()
    }

    fetchErrorData = () => {
        let alertData = localStorage.getItem('alertSettings')
        if (alertData) {
            alertData = JSON.parse(alertData)
            if (alertData.genericAlert) {
                this.setState({ alertMessage: alertData.genericAlert })
            }
            if (alertData.reportGenerate) {
                this.setState({ generateMessage: alertData.reportGenerate })
            }
        }
        this.fetchData()
    }

    fetchData = () => {
        this.setState({ loading: true })
        window.sessionStorage.setItem("demo", "demo")
        let data = localStorage.getItem('alertSettings')
        if (data) {
            data = JSON.parse(data)
            if (data) {
                this.setState({ purchaseSuccess: data.purchaseSuccess, purchaseError: data.purchaseError, reportSuccess: data.reportSuccess, reportError: data.reportError })
            }
        }
        Amplify.Auth.currentAuthenticatedUser()
            .then(async user => {
                if (user) {
                    let isPlanTypeEnterprisePlan = await isEnterprisePlan()
                    this.setState({ user: user, isPremiumUser: isPlanTypeEnterprisePlan })
                    this.addCurrentPurchaseReportEntry();
                    await this.fetchInitialPendingReportData(user);
                    await this.fetchInitialPurchasedReportData(user);
                }
            }).catch(e => {
                console.log(e)
                this.setState({ loading: false })
            })
    }

    downloadFile = async (report, fileName) => {

        var purchaseDate = Date.parse(report.purchase);
        var expirationDate = Date.parse(report.expiration);
        if (purchaseDate >= expirationDate && !this.state.isPremiumUser) {
            message.error("Link is expired");
        } else {
            await this.setState({ loading: true });
            const url = await Amplify.Storage.get(fileName)
            var link = document.createElement("a");
            link.style.display = 'none';
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            await this.setState({ loading: false });
        }
    }

    downloadJsonData = async (report) => {
        this.setState({ loading: true })
        let data = await getJSONData(report)
        if (data !== false) {
            const fileName = report.reportname;
            const json = JSON.stringify(data);
            const blob = new Blob([json], { type: 'application/json' });
            const href = await URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.download = fileName + ".json";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            message.error(<p dangerouslySetInnerHTML={{ __html: this.state.alertMessage }} />)
        }
        this.setState({ loading: false })
    }

    filteredData = (data, value) => data.filter(elem => (
        Object.keys(elem).some(key => elem[key] != null ? elem[key].toString().toLowerCase().includes(value) : "")
    ));

    onSearchStringChangePurchased = event => {
        if (event.target.value === '') {
            this.setState({ searchPurchased: null, filterPurchasedData: null })
            return;
        }
        const value = event.target.value.toLowerCase();
        const newData = this.filteredData(this.state.purchasedList, value);
        this.setState({ searchPurchased: event.target.value, filterPurchasedData: newData })

    };

    onSearchPurchased = value => {
        if (value === '') {
            this.setState({ filterPurchasedData: null })
            return;
        }
        const newData = this.filteredData(this.state.purchasedList, value);
        this.setState({ searchPurchased: value, filterPurchasedData: newData })
    };

    onSearchStringChangePending = event => {
        if (event.target.value === '') {
            this.setState({ searchPending: null, filterPendingData: null })
            return;
        }
        const value = event.target.value.toLowerCase();
        const newData = this.filteredData(this.state.pendingList, value);
        this.setState({ searchPending: event.target.value, filterPendingData: newData })

    };

    onSearchPending = value => {
        if (value === '') {
            this.setState({ filterPendingData: null })
            return;
        }
        const newData = this.filteredData(this.state.pendingList, value);
        this.setState({ searchPending: value, filterPendingData: newData })
    };

    getMessage = (data, name) => {
        let text = this.state[name]
        text = text.split("{{REPORT_NAME}}").join(data)
        let message = <p dangerouslySetInnerHTML={{ __html: text }} />
        return message
    }


    downloadReport = async (record) => {
        try {
            console.log(record);
            this.setState({ isShowModal: true });
            Amplify.Auth.currentAuthenticatedUser()
                .then(async user => {
                    if (user) {
                        await setSessionForPayment(record);
                        var result = await getData(user);
                        if (result.data !== null && result.data) {
                            result = await updatePendingReportToDB(record.id);
                            if (result.errors) {
                                message.error("We are not able to set payment status");
                            } else {
                                await clearSessionForPayment();
                                await this.fetchInitialPendingReportData(user);
                                await this.fetchInitialPurchasedReportData(user);
                                openSanckbarNotification(record.id, 'bottomRight', this.getMessage(record.reportName, 'purchaseSuccess'))
                            }
                        } else {
                            this.setState({ isShowModal: false });
                            message.error("Error in report generation");
                            openSanckbarNotificationError(record.id, 'bottomRight', this.getMessage(record.reportName, 'purchaseError'))
                        }
                    }
                    this.setState({ isShowModal: false });
                }).catch(async e => {
                    this.setState({ isShowModal: false });
                    message.error("Error in report generation")
                    openSanckbarNotificationError(record.id, 'bottomRight', this.getMessage(record.reportName, 'purchaseError'))
                })
        } catch (e) {
            this.setState({ isShowModal: false });
            console.log(e);
            message.error("Error in report generation")
            openSanckbarNotificationError(record.id, 'bottomRight', this.getMessage(record.reportName, 'purchaseError'))
        }

    }

    addCurrentPurchaseReportEntry = async () => {
        const isPurchase = window.sessionStorage.getItem("isPurchase");
        let isPaymentDone = false
        if (isPurchase === 'true' || isPaymentDone) {
            Amplify.Auth.currentAuthenticatedUser()
                .then(async user => {
                    if (user) {
                        try {
                            this.setState({ loading: true }, async () => {
                                var result = await setPendingReportToDB(user);
                                if (result.errors) {
                                    message.error(this.getMessage('', 'reportError'))
                                    openSanckbarNotificationError(key, 'bottomRight', this.getMessage('', 'reportError'), this.state.button)
                                    this.setState({ errorInReportGeneration: true, loading: false });
                                } else {
                                    this.setState({ currentPurchaseReport: result.data });
                                    await this.fetchInitialPendingReportData(user);
                                    window.sessionStorage.setItem("isPurchase", false);
                                    await clearSessionForPayment();
                                }
                            })
                        } catch (e) {
                            console.log(e);
                            message.error("this.getMessage('', 'reportError')")
                            openSanckbarNotificationError(key, 'bottomRight', this.getMessage('', 'reportError'), this.state.button)
                            this.setState({ errorInReportGeneration: true, loading: false });
                        }
                    }
                }).catch(e => {
                    message.error("this.getMessage('', 'reportError')")
                    openSanckbarNotificationError('bottomRight', this.getMessage('', 'reportError'), this.state.button)
                    this.setState({ errorInReportGeneration: true, loading: false });
                })
        }
    }

    fetchInitialPurchasedReportData = async (user) => {
        this.setState({ loading: true });
        let body = {
            filter: `username = '${user.username}'`,
            page: this.state.currantPurchasedPage,
            size: this.state.purchasedPageSize
        }

        let query = await API.graphql(graphqlOperation(listPurchaseReportPagination, body))
        let purchases = []
        query.data.listPurchaseReportPagination.items.forEach((d, i) => {
            if (d.reportType) {
                purchases.push({
                    key: i + 1,
                    type: d.reportType,
                    reportname: d.reportname,
                    description: d.description,
                    purchase: d.datePurchase,
                    expiration: d.expirationDate,
                    csvFileName: d.csvFileName,
                    pdfFileName: d.pdfFileName,
                    amount: d.amount,
                    promotionCode: d.promotionCode,
                    discount: d.discount,
                    totalAmount: d.totalAmount,
                    isFrom: d.isFrom,
                    filter: d.filter,
                    sorter: d.sorter,
                    recordCost: d.recordCost
                })
            }
        });
        await this.setState({ purchasedList: purchases, totalPurchasedRecords: query.data.listPurchaseReportPagination.total, loading: false })
    }

    fetchInitialPendingReportData = async (user) => {
        this.setState({ loading: true });
        let pendingReportBody = {
            filter: `username = '${user.username}' and   downloadStatus = 'false'`,
            page: this.state.currantPendingPage,
            size: this.state.pendigngPageSize
        }

        let query = await API.graphql(graphqlOperation(listPendingReportPagination, pendingReportBody))
        let pendingPurchases = []
        query.data.listPendingReportPagination.items.forEach((d, i) => {
            pendingPurchases.push({
                ...d,
                key: i + 1
            })

        });
        console.log(pendingPurchases);
        await this.setState({ pendingList: pendingPurchases, totalPendingRecords: query.data.listPendingReportPagination.total, loading: false });
    }

    pendingReportPageChange = (pageNumber) => {
        this.setState({ currantPendingPage: pageNumber }, () => {
            this.fetchInitialPendingReportData(this.state.user)
        })
    }

    pendingReportSizeChange = (current, pageSize) => {
        this.setState({ currantPendingPage: 1, pendigngPageSize: pageSize }, () => {
            this.fetchInitialPendingReportData(this.state.user)
        })
    }

    purchaseReportPageChange = (pageNumber) => {
        this.setState({ currantPurchasedPage: pageNumber }, () => {
            this.fetchInitialPurchasedReportData(this.state.user)
        })
    }

    purchaseReportSizeChange = (current, pageSize) => {
        this.setState({ currantPurchasedPage: 1, purchasedPageSize: pageSize }, () => {
            this.fetchInitialPurchasedReportData(this.state.user)
        })
    }

    render() {
        return (
            <>

                {this.state.pendingList.length > 0 &&
                    <Widget title="Pending Download Reports">
                        <Search
                            placeholder={'Search'}
                            onChange={this.onSearchStringChangePending}
                            onSearch={this.onSearchPending}
                            value={this.state.searchPending}
                            allowClear
                            style={{ width: 500, marginRight: 15 }}
                        />
                        <Spin tip="Loading..." spinning={this.state.loading}>
                            <DataTable data={this.state.filterPendingData || this.state.pendingList}
                                onChange={(pageNumber) => this.pendingReportPageChange(pageNumber)}
                                onShowSizeChange={(current, pageSize) => this.pendingReportSizeChange(current, pageSize)}
                                columns={this.report_download_pending__columns}
                                pageTotal={this.state.totalPendingRecords}
                            />
                        </Spin>
                    </Widget>
                }
                <Widget title="Purchased Reports">
                    <Search
                        placeholder={'Search'}
                        onChange={this.onSearchStringChangePurchased}
                        onSearch={this.onSearchPurchased}
                        value={this.state.searchPurchased}
                        allowClear
                        style={{ width: 500, marginRight: 15 }}
                    />
                    <Spin tip="Loading..." spinning={this.state.loading}>
                        <DataTable
                            data={this.state.filterPurchasedData || this.state.purchasedList}
                            onShowSizeChange={(current, pageSize) => this.purchaseReportSizeChange(current, pageSize)}
                            onChange={(pageNumber) => this.purchaseReportPageChange(pageNumber)}
                            pageTotal={this.state.totalPurchasedRecords}
                            columns={this.columns}
                        />
                    </Spin>
                </Widget>
                <Modal
                    title="Vertically centered modal dialog"
                    centered
                    visible={this.state.isShowModal}
                >
                    <div className='confirm-popup-content2'>
                        <div className='report-modal-content'>
                            <p><Spin size="large" /></p>
                        </div>
                        <div className='report-modal-content confirm-popup-text'>
                            <p dangerouslySetInnerHTML={{ __html: this.state.generateMessage }} />
                        </div>
                    </div>
                </Modal>


            </>
        )
    }
}

export default Report;
