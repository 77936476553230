import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import URLSearchParams from 'url-search-params'
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch, withRouter } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";
import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import Confirm from "../Confirm";
import { setInitUrl, userSignInSuccess, } from "appRedux/actions/Auth";
import { onLayoutTypeChange, onNavStyleChange, setThemeType } from "appRedux/actions/Setting";
import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";
import * as Amplify from 'aws-amplify';
import asyncComponent from "../../util/asyncComponent";
import { API } from "aws-amplify";
import { getEnvByName } from "../../service/GeneralService";

const RestrictedRoute = ({ component: Component, location, authUser, isMaintenanceEnable, ...rest }) =>
(
  <Route
    {...rest}
    render={props =>
      isMaintenanceEnable
        ? <Redirect
          to={{
            pathname: '/maintenance',
            state: { from: location }
          }}
        />
        : <Component {...props} />}
  />
);


const App = (prevProps, nextProps) => {

  const dispatch = useDispatch();
  const { locale, navStyle, layoutType } = useSelector(({ settings }) => settings);
  const { authUser, initURL } = useSelector(({ auth }) => auth);
  const [ageRestriction, setAgeRestriction] = useState(false)
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  useEffect(() => {
    let link = document.createElement('link');
    link.type = 'text/html';
    link.rel = 'stylesheet';
    link.href = "/css/style.css";
    link.className = 'gx-style';
    document.body.appendChild(link);
    Amplify.Auth.currentAuthenticatedUser()
      .then(async user => {
        if (user) {
          dispatch(userSignInSuccess(user));
        }
      }).catch(e => {
        // history.push("/signin");
      })
  }, [dispatch]);


  useEffect(() => {
    async function fetchData() {
      try {
        var res = await API.post('PaymentGatewayAPI', '/paymentgateway/maintenanceWindowSettings', {})
        if (res.success) {
          res = res.result
          if (res && res.length > 0) {
            localStorage.setItem('maintenanceWindowSettings', JSON.stringify(res[0]))
            if (res[0].maintenanceWindow && res[0].maintenanceWindow === 'ENABLE') {
              if (localStorage.getItem('isMaintenance') !== 'true') {
                window.location.href = '/maintenance'
                localStorage.setItem('isMaintenance', 'true')
              }
            } else {
              localStorage.setItem('isMaintenance', 'false')
            }
          }
        } else {
          console.log(JSON.stringify(res))
        }
      } catch (err) {
        console.log(err)
      }
      // setIsMaintenanceEnable(true)
    }

    async function fetchEnv() {

      let envString = `'REACT_APP_ABOUT_PAGE_URL', 'REACT_APP_AGE_RESTRICTION', 'REACT_APP_END_USER_AGREEMENT_URL'`
      let envList = await getEnvByName(envString)
      if (envList !== false) {
        if (envList['REACT_APP_AGE_RESTRICTION'] && envList['REACT_APP_AGE_RESTRICTION'].toLowerCase() === 'enables') {
          setAgeRestriction('enables')
        }

        localStorage.setItem('envSetting', JSON.stringify(envList))
      }
    }



    fetchEnv()
    fetchData();
  }, []);


  useEffect(() => {

    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
    const params = new URLSearchParams(location.search);

    if (params.has("theme")) {
      dispatch(setThemeType(params.get('theme')));
    }
    if (params.has("nav-style")) {
      dispatch(onNavStyleChange(params.get('nav-style')));
    }
    if (params.has("layout-type")) {
      dispatch(onLayoutTypeChange(params.get('layout-type')));
    }

    setLayoutType(layoutType);
    setNavStyle(navStyle);
  });


  const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  const setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  const getCookie = (name) => {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  useEffect(() => {
    if (location.pathname === '/') {
      if (authUser === null) {
        history.push('/home');
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        const age = ageRestriction
        if (age === 'enables') {
          const x = getCookie('canoja')
          !x ? history.push('/confirm') : history.push('/home')
        } else {
          history.push('/home')
        }
      } else {
        history.push(initURL);
      }
    }
  }, [authUser, initURL, location, history, ageRestriction]);

  const currentAppLocale = AppLocale[locale.locale];


  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      {/* <LocaleProvider locale={currentAppLocale.antd}> */}
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}>

        <Switch>
          <Route exact path='/confirm' component={Confirm} />
          <Route exact path='/maintenance' component={asyncComponent(() => import('../../routes/MaintenanceWindow'))} />
          <RestrictedRoute path={`${match.url}`} authUser={authUser} location={location} isMaintenanceEnable={localStorage.getItem('isMaintenance') === 'true'}
            component={MainApp} />
        </Switch>
      </IntlProvider>
      {/* </LocaleProvider> */}
    </ConfigProvider>
  )
};

export default memo(withRouter(App));
