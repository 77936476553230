import React, { Component } from 'react';
import { Button, Card, Spin, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { API } from 'aws-amplify';
import { REACT_APP_DECREPT_KEY } from '../../config/aws-config';
var CryptoJS = require("crypto-js")

class PaymentStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSuccess: false,
            loading: false,
            isShowReportButton: false,
            message: ''
        }

    }

    componentDidMount() {
        this.handleStatus()
    }

    handleStatus = async () => {
        this.setState({ loading: true })
        if (this.props.location.search) {
            let status = this.props.location.search
            status = status.split("?");
            let demo = await this.decrypt(status[1])
            if (demo && demo.receipt) {
                let body = {
                    token: status[1]
                }
                var res = await API.post('PaymentGatewayAPI', '/paymentgateway/acceptjs/verifytoken', { body })
                if (res.active) {
                    this.setState({ isSuccess: true, message: 'PAYMENT SUCCESSFULL' })
                    let purchaseData = localStorage.getItem('basicPurchaseData')
                    purchaseData = JSON.parse(purchaseData)
                    if (purchaseData) {
                        window.sessionStorage.setItem("isPurchase", true);
                        await this.saveReportData(purchaseData)
                        this.setState({ isShowReportButton: true })
                    }
                } else {
                    this.setState({ message: 'INVALID', loading: false })
                }
            } else if (demo && demo.cancel) {
                localStorage.removeItem('basicPurchaseData')
                this.setState({ isSuccess: false, loading: false, message: 'PAYMENT CANCELLED' })
            } else {
                localStorage.removeItem('basicPurchaseData')
                this.setState({ message: 'INVALID' })
                message.error('Something bad happen')
            }
        } else {
            localStorage.removeItem('basicPurchaseData')
            this.setState({ message: 'INVALID', loading: false })
        }
        this.setState({ loading: false })
    }

    decrypt = (data) => {
        try {
            var ciphertext = data.replace(/a5sf8sef9fse5/g, '+').replace(/awd7a6wd65awd7/g, '/').replace(/skjhf98rdrhg78rg/g, '=');
            var bytes = CryptoJS.AES.decrypt(ciphertext, REACT_APP_DECREPT_KEY);
            var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

            return JSON.parse(decryptedData)
        } catch (error) {
            console.log(error)
        }
    }

    saveReportData = async (purchaseData) => {
        window.sessionStorage.setItem('isFrom', purchaseData.isFrom)
        window.sessionStorage.setItem("discount", purchaseData.discount);
        window.sessionStorage.setItem("promotionCode", purchaseData.promotionCode);
        window.sessionStorage.setItem("totalAmount", purchaseData.totalAmount);
        window.sessionStorage.setItem("amount", purchaseData.amount);
        window.sessionStorage.setItem("reportname", purchaseData.reportname);
        window.sessionStorage.setItem("description", purchaseData.description);
        window.sessionStorage.setItem("amountPerRecord", purchaseData.amountPerRecord);
    }

    handleClick = () => {
        this.props.history.push("/report");
    }

    render() {
        const { loading, isSuccess, isShowReportButton, message } = this.state;
        return (
            <>
                <Card className="gx-card" title="PAYMENT STATUS">
                    <Spin spinning={loading}>
                        <div>
                            {message === 'PAYMENT SUCCESSFULL' && <span>* please do not refresh page</span>}
                            <br></br><br></br>
                            <h1>{message}</h1>
                        </div>

                        {isShowReportButton && <Button onClick={this.handleClick} >Go to Report Page</Button>}

                    </Spin>
                </Card>
            </>
        );
    }
}

export default withRouter(PaymentStatus);