import React, { useState, useEffect } from "react";
import { Layout, Row, Col, Modal } from "antd";

import Sidebar from "../Sidebar/index";
import HorizontalDark from "../Topbar/index";
import App from "routes/index";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import { useRouteMatch, withRouter } from "react-router-dom";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import VerifyEmail from "../VerifyEmail";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";
import { showModal } from 'appRedux/actions/Common';
// Canoja Verify -- Google Analytics
import ReactGoogleAnalytics from 'react-ga';

// Canoja Verify -- Git Version
import CV_VERSION from '../../_cv_version.js';
import CountDownTime from "../../components/CountDownTime/CountDownTime";
import FooterText from "./FooterText";
import UserDiscountModal from "../../components/UserDiscountModal/UserDiscountModal";
import { checkIsShowDiscountModal } from "../../service/SubscriptionService";

const { Content, Footer } = Layout;

const MainApp = (props) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const isCurrentlyLogged = useSelector(({ table }) => table.isCurrentlyLogged);
  const [isShowDiscountModal, setIsShowDiscountModla] = useState(false)
  const [isLoggedUser, setIsLoggedUser] = useState(false)
  const dispatch = useDispatch();
  if (document.location.hostname === "www.canojaverify.com" || document.location.hostname === "canojaverify.com") {
    ReactGoogleAnalytics.initialize('UA-162780134-1');
    ReactGoogleAnalytics.pageview(window.location.pathname + window.location.search);
  } else if (document.location.hostname === "dev.canojaverify.com" || document.location.hostname === "localhost") {
    ReactGoogleAnalytics.initialize('UA-162780134-3');
    ReactGoogleAnalytics.pageview(window.location.pathname + window.location.search);
  }

  const { width, navStyle } = useSelector(({ settings }) => settings);
  const { typeModal } = useSelector(({ auth }) => auth);

  // const { setLimit } = useSelector(({ table }) => table);
  const match = useRouteMatch();
  const [visible, setVisible] = useState(false);
  // const [customerId, setCustomerId] = useState('')

  useEffect(() => {
    if (typeModal === 0) {
      setVisible(false);
    } else {
      setVisible(true);
    }
  }, [typeModal])

  useEffect(() => {
    const fetchData = async () => {
      let isShowModal = sessionStorage.getItem('isShowDiscountModal')
      if (!sessionStorage.getItem('isShowDiscountModal')) {
        let response = await checkIsShowDiscountModal()
        if (response === false) {
          isShowModal = 'true'
        }
      }
      if (!isCurrentlyLogged && !authUser && isShowModal === 'true') {
        let customerId = window.sessionStorage.getItem('customerId')
        if(!customerId){
          setIsShowDiscountModla(false)
        }
       
      }
    }


    fetchData()
  }, [isCurrentlyLogged, authUser]);

  const getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return '';
    }
  };
  const getNavStyles = (navStyle) => {
    return <HorizontalDark />;
  };

  const getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar />;
    }

    return null;
  };

  const handleCancel = () => {
    setVisible(false);
    dispatch(showModal(0));
  };

  const handleCloseModal = () => {
    setIsShowDiscountModla(false)
  }

  var arr = CV_VERSION.version ? CV_VERSION.version.split("-") : [];
  arr.pop();
  var version = arr.join("-")

  return (
    <Layout className="gx-app-layout">
      {getSidebar(navStyle, width)}
      <Layout>
        {getNavStyles(navStyle)}
        <Content className={`gx-layout-content ${getContainerClass(navStyle)} `}>
          <App match={match} />
          <Footer>
            <Row>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <div className="gx-layout-footer-content" style={{ textAlign: "left" }}>
                  <p>{version}</p>
                </div>
              </Col>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <div className="gx-layout-footer-content" style={{ textAlign: "center" }}>
                  <FooterText />
                </div>
                {/* {customerId &&
                  <div style={{ textAlign: "center" }}>
                    <a rel="noopener noreferrer" href={`https://verify.authorize.net/anetseal/?pid=${customerId}&rurl=https://demo.canojaverify.com`} target="_blank" ><img width="90" height="72" alt="example" src={"https://verify.authorize.net/anetseal/images/secure90x72.gif"} alt="Authorize-net-seal" /></a>
                  </div>
                } */}
              </Col>
              <Col xl={8} lg={8} md={8} sm={24} xs={24}>
                <div className="gx-layout-footer-content" style={{ textAlign: "right" }} >
                  <a rel="noopener noreferrer" href="https://canoja-verify-images.s3.amazonaws.com/CanojaTech-Privacy-Policy-v3.pdf" target="_blank" >Privacy Policy | </a>
                  <a rel="noopener noreferrer" href="https://canoja-verify-images.s3.amazonaws.com/CanojaTech-Terms-of-service-v3.pdf" target="_blank">Terms of Service</a>
                </div>

              </Col>
              <div className="gx-layout-footer-content" style={{ textAlign: "right" }} >
                <CountDownTime />
              </div>
            </Row>
          </Footer>
        </Content>
        {isShowDiscountModal &&
          <UserDiscountModal handleCloseModal={handleCloseModal} isLoggedUser={isLoggedUser} />
        }
        <Modal
          visible={visible}
          title="Title"
          onCancel={handleCancel}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
        >
          {
            typeModal === 1 ?
              <SignIn /> :
              typeModal === 2 ?
                <SignUp /> :
                typeModal === 3 ?
                  <VerifyEmail /> :
                  typeModal === 4 ?
                    <ForgotPassword /> :
                    typeModal === 5 ?
                      <ResetPassword /> : null
          }
        </Modal>
      </Layout>
    </Layout >
  )
};

const mapStateToProps = () => ({
});

export default connect(mapStateToProps, {
})(withRouter(MainApp));

