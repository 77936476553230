import Amplify from 'aws-amplify';
import { AMPLIFY_AUTH, AMPLIFY_STORAGE, REACT_APP_REGION, AMPLIFY_PAYMENT_GATEWAY, REACT_APP_GRAPHQL_ENDPOINT, REACT_APP_AWS_APPSYNC_AUTH_TYPE, REACT_APP_AWS_APPSYNC_API_KEY, AMPLIFY_CUSTOMER_REST_API } from './config/aws-config';

export function configureAmplify() {
    Amplify.configure({
        Auth: AMPLIFY_AUTH,
        Storage: AMPLIFY_STORAGE,
        "aws_project_region": REACT_APP_REGION,
        "aws_appsync_graphqlEndpoint": REACT_APP_GRAPHQL_ENDPOINT,
        "aws_appsync_region": REACT_APP_REGION,
        "aws_appsync_authenticationType": REACT_APP_AWS_APPSYNC_AUTH_TYPE,
        "aws_appsync_apiKey": REACT_APP_AWS_APPSYNC_API_KEY,
        "aws_cloud_logic_custom": [ AMPLIFY_PAYMENT_GATEWAY, AMPLIFY_CUSTOMER_REST_API],
    })
}

