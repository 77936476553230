import {
    ValueEditor as DefaultValueEditor,
    ValueEditorProps
} from "react-querybuilder";
import React from 'react';
import moment from "moment";
import { DatePicker } from "antd";
import '../index.css'

import InputText from "../../../components/InputControl/InputText/InputText";
import InputTag from "../../../components/InputControl/InputTag/InputTag";


const { RangePicker } = DatePicker;
const ValueEditor = (props) => {
    const { field, value, operator, handleOnChange } = props;
    const [val1, val2] = value === "" ? ["", ""] : value;
    if (field === "refreshed_on" || field === "licenseIssueDate" || field === "licenseExpireDate") {
        if (operator === "between" || operator === "notBetween") {
            return (
                <RangePicker
                    defaultValue={[typeof value !== 'string' ? val1 ? moment(val1) : '' : '', typeof value !== 'string' ? val2 ? moment(val2) : '' : '']}
                    format='YYYY-MM-DD'
                    onChange={(date) => handleOnChange([moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')])} />
            );

        } else if ((operator !== "null")) {
            return (
                <DatePicker
                    className="datepicker-query"
                    defaultValue={value ? moment(value, 'YYYY-MM-DD') : moment(new Date(), 'YYYY-MM-DD')}
                    onChange={(e) => handleOnChange(moment(e).format("YYYY-MM-DD"))} />
            )
        } else {
            return (
               <></>
            )
        }

    } else {
        if (operator === 'between' || operator === "notBetween") {

            return (
                <>
                    <InputText
                        display={true}
                        required={true}
                        defaultValue={val1}
                        onChange={(e) => handleOnChange([e, val2])}
                    />
                    <span className="and-between" >and </span>
                    <InputText
                        display={true}
                        required={true}
                        defaultValue={val2}
                        onChange={(e) => handleOnChange([e, val1])}
                    />
                </>)
        } else if (operator === "in" || operator === "notIn") {
            return (
                <InputTag
                    name='keywords'
                    defaultValue={typeof value === 'string' ? value.length > 0 ? value.split(",") : [] : []}
                    onChange={(e) => handleOnChange(e.toString())}
                />
            )
        }
    }
    return <DefaultValueEditor {...props} />;
};

export default ValueEditor;
