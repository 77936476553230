import React, { Component } from "react";
import { Card, Button, Form, Checkbox, Input, message, Spin } from "antd";
import { connect } from 'react-redux'
import PayButton from '../Payment/PayButton';
import { HidePurchase } from '../../appRedux/actions/Common';
import { API } from "aws-amplify";
import { isPremiumOrEnterprisePlan } from '../../service/SubscriptionService';
import { getSelectedPlan } from '../../service/SubscriptionService'
import { withRouter } from "react-router-dom";
import { setCurrentlyLoggedUser } from "appRedux/actions/DataTable";
import './CustomQueryPurchase.css'
import { getColumns } from "../../service/ReportService.js";
import SampleDataTable from "../../components/SampleDataTable/SampleDataTable";
import { getEnvByName } from "../../service/GeneralService";
import PayButtonContent from "../Payment/PayButtonContent";
// import { minimumPrice, pricePerRecord } from "util/config";

const FormItem = Form.Item;
const { TextArea } = Input;

class Purchase extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      loading: false,
      pageNumber: 1,
      pageSize: 5,
      price: 0,
      checked: false,
      privacyChecked: false,
      ischecked: true,
      isprivacyChecked: true,
      isOpen: false,
      filter: {},
      sorter: {},
      reportname: '',
      description: '',
      promotioncode: '',
      isDisabled: false,
      totalCost: 0,
      amountPerRecord: '',
      totalCounts: 0,
      priceLoading: false,
      checkoutMinimumPrice: '59.99',
      pricePerRecord: '4.00',
      isEnterprisePlan: false,
      columns: [],
      subscriptionId: false,
      userPlan: null,
      alertMessage: 'Something bad happen on server',
    }
    // this.handleChange = this.handleChange.bind(this)
  }


  async componentDidMount() {
    this.props.HidePurchase(false);
    this.fetchData()
  }

  componentDidUpdate = async () => {
    if (this.props.isCurrentlyLogged === true) {
      this.fetchData()
      this.props.setCurrentlyLoggedUser(false)
    }
  }

  fetchData = async () => {
    let alertData = localStorage.getItem('alertSettings')
    if (alertData) {
      alertData = JSON.parse(alertData)
      if (alertData.genericAlert) {
        this.setState({ alertMessage: alertData.genericAlert })
      }
    }
    let envString = `'REACT_APP_CHECKOUT_MINIMUM_PRICE', 'REACT_APP_PRICE_PER_RECORD'`
    let envList = await getEnvByName(envString)
    if (envList !== false && envList['REACT_APP_CHECKOUT_MINIMUM_PRICE']) {
      this.setState({ checkoutMinimumPrice: envList['REACT_APP_CHECKOUT_MINIMUM_PRICE'], pricePerRecord: envList['REACT_APP_PRICE_PER_RECORD'] })
    }
    this.getLicenseData()
    this.getAmount()
    this.checkPlan()
  }

  getLicenseData = async () => {
    this.setState({ loading: true, priceLoading: true })
    let filter = window.sessionStorage.getItem('filter')
    
    let body = {
      query: filter,
      page: this.state.pageNumber,
      size: this.state.pageSize
    }
    var response = await API.post('CustomerRestAPI', '/customers/getData', { body })
    if (response.success) {
      if (response.customQueryData.length > 0) {
        let columns = await getColumns(response.customQueryData[0])
        this.setState({ columns: columns })
      }
      window.sessionStorage.setItem("count", response.total)
      window.sessionStorage.setItem("pageTotal", response.total)
      this.setState({ data: response.customQueryData, totalCounts: response.total, pageTotal: response.total })
    } else {
      console.log(response.err)
      message.error(<p dangerouslySetInnerHTML={{ __html: this.state.alertMessage }} />)
    }
    this.setState({ loading: false, });
  }

  changePageNumber = async (e) => {
    this.setState({ pageNumber: e }, () => {
      this.getLicenseData()
    })
  }

  getAmount = async () => {
    this.setState({ loading: true, priceLoading: true })
    var customerId = window.sessionStorage.getItem('customerId')
    if (customerId) {
      this.setState({ customerId: customerId, })
      var res = await API.get('PaymentGatewayAPI', `/paymentgateway/customers/${customerId}`,)
      if (res.success) {
        res = res.customer
      } else {
        res = null
      }

      if (res && res.subscriptionIds && res.subscriptionIds.subscriptionId.length > 0) {
        let data = await getSelectedPlan(res.subscriptionIds.subscriptionId[0])
        if (data === false) {
          console.log('error')
        } else {
          this.setState({ amountPerRecord: Number(data[0].amountPerRecord), subscriptionStatus: data.subscriptionStatus, userPlan: data[0].planType, loading: false, priceLoading: false })
        }
        this.setState({ subscriptionId: res.subscriptionIds.subscriptionId[0] })
      }
    }
  }

  checkPlan = async () => {
    this.setState({ loading: true, priceLoading: true })
    let isPlanEnterprise = await isPremiumOrEnterprisePlan()
    let isEnterprisePlan = false
    if (isPlanEnterprise) {
      isEnterprisePlan = true
    }
    this.setState({ isEnterprisePlan: isEnterprisePlan, loading: false, priceLoading: false });
  }

  handleCheckChange = () => {
    this.setState({ checked: !this.state.checked })
    if (!this.state.checked) this.setState({ ischecked: true })
  }

  handlePrivacyCheckChange = () => {
    this.setState({ privacyChecked: !this.state.privacyChecked })
    if (!this.state.privacyChecked) this.setState({ isprivacyChecked: true })
  }

  goBack = () => {
    window.sessionStorage.setItem('cancel', true);
    const type = parseInt(window.sessionStorage.getItem('licenseType'));
    const title = window.sessionStorage.getItem('title');
    if (type === 1) {
      const t = {
        listByLicenseNumber: 'number',
        listByCategory: 'category',
        listByBusinessName: 'name',
        listByBusinessCity: 'city',
        listByState: 'state',
        listByCountry: 'country',
      }[title]
      this.props.history.push(`/license-search/${t}`);
    } else {
      this.props.history.push(`/customquerybuilder/add`);
    }
  }


  validateCheck = () => {
    if (!this.state.checked) {
      this.setState({ ischecked: false });
    }

    if (!this.state.privacyChecked) {
      this.setState({ isprivacyChecked: false });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.sorter !== nextProps.table.sorter || prevState.filter !== nextProps.table.filter) {
      return {
        sorter: nextProps.table.sorter,
        filter: nextProps.table.filter
      };
    }
    let newState = null
    let newValue = (!prevState.checked || !prevState.privacyChecked || prevState.reportname === '' || prevState.description === '')
    if (prevState.isDisabled !== newValue) {
      newState = {
        ...prevState,
        isDisabled: newValue,
      }
    }
    return newState;
  }

  handleChange = async (name, e) => {
    this.setState({ [name]: e.target.value })
  }

  onShowSizeChange = async (e) => {
    this.setState({ pageSize: e, pageNumber: 1 }, () => {
      this.getLicenseData()
    })
  }

  showPromotionCodeError = (error) => {
    this.setState({ promotionCodeError: error })
  }



  render() {
    const { amountPerRecord, totalCounts, userPlan, subscriptionId, subscriptionStatus, checkoutMinimumPrice, pricePerRecord } = this.state;
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };

    let amountForRecords = amountPerRecord ? Number(amountPerRecord) : (Number(pricePerRecord))
    let price = (amountForRecords) * totalCounts || 0
    if (price < Number(checkoutMinimumPrice)) {
      price = Number(checkoutMinimumPrice);
    }

    const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    return (
      <div className="purchase-page">
        <Card className="gx-card" title="Sample Data Report">

          <SampleDataTable showAllColumns={true} />

          {this.state.loading ? <Button type="primary" shape="circle" loading /> : null}


          <Form onSubmit={this.handleSubmit} >

            <div className='purchase-page-form'>
              <div className="purchase-page-form-price">
                {this.state.priceLoading ? <Spin tip="Calculating Price..." spinning={this.state.loadingData}></Spin> : <h6>Price: ${numberWithCommas(price.toFixed(2))}</h6>}
                <p>
                  Report includes PDF and CSV of requested data to be delivered once the transaction has been approved. Report files will be available for download for 30 days from date of purchase.
                </p>
              </div>

              <div className="privay-policy">
                <FormItem
                  className="required"
                  {...formItemLayout}
                  label="Report Name "
                  name="reportname"


                >{getFieldDecorator('given_name', {
                  rules: [{ required: true, message: 'Please input Report Name!' }],
                })(
                  <Input onChange={(e) => { this.handleChange('reportname', e) }} />
                )}
                </FormItem>
                <FormItem
                  className="required"
                  {...formItemLayout}
                  label="Report Description"

                >{getFieldDecorator('description', {
                  rules: [{ required: true, message: 'Please input Description!' }],
                })(
                  <TextArea rows={4} onChange={(e) => { this.handleChange('description', e) }} />
                  // <Input onChange={(e) => { this.handleDescriptionChange('description', e) }} />
                )}
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  className={!this.state.ischecked ? 'has-error' : null}
                >
                  <Checkbox id='checked1' name="checked1" checked={this.state.checked} onChange={this.handleCheckChange}>
                    I agree that this data is representative of data I am requesting and that actual values may be different or missing.
                  </Checkbox>
                </FormItem>
                <FormItem
                  {...formItemLayout}
                  className={!this.state.isprivacyChecked ? 'has-error' : null}
                >
                  <Checkbox id='checked2' name="checked2" checked={this.state.privacyChecked} onChange={this.handlePrivacyCheckChange}>
                    I agree to the Canoja Technologies <a rel="noopener noreferrer" href="https://canoja-verify-images.s3.amazonaws.com/CanojaTech-Terms-of-service-v3.pdf" target="_blank">Terms of Service</a> and <a rel="noopener noreferrer" href="https://canoja-verify-images.s3.amazonaws.com/CanojaTech-Privacy-Policy-v3.pdf" target="_blank">Privacy Policy</a>.
                  </Checkbox>
                </FormItem>
              </div>
              <div
                className='purchase-button-group'
                style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
              >
                <span
                  style={{ fontSize: 20, cursor: 'pointer' }}
                  onClick={this.goBack}
                >
                  Cancel
                </span>
                {this.state.priceLoading ?
                  (<Spin className={'paybutton-loader'} tip="loading" spinning={this.state.loadingData}></Spin>
                  ) : (
                    <PayButtonContent
                      apiName="PaymentGatewayAPI"
                      reportname={this.state.reportname}
                      promotioncode={this.state.promotioncode}
                      description={this.state.description}
                      coupon={this.state.coupon}
                      amount={price}
                      amountPerRecord={amountForRecords}
                      userPlan={userPlan}
                      subscriptionId={subscriptionId}
                      totalCounts={totalCounts}
                      customerId={this.state.customerId}
                      secondarySubscription={this.state.secondarySubscriptionId}
                      subscriptionStatus={subscriptionStatus}
                      discountedPrice={this.state.totalCost === 0 ? price.toFixed(2) : this.state.totalCost}
                      currency="usd"
                      isFrom='Custom'
                      quantity={1}
                      disabled={this.state.isDisabled}
                      validateCheck={this.validateCheck}
                      showPromotionCodeError={this.showPromotionCodeError}
                    />
                  )}
              </div>
            </div>

          </Form>

        </Card>
      </div >
    );
  }

}

const PurchaseCustomQuery = Form.create()(Purchase);

const mapStateToProps = (state) => ({
  table: state.table,
  isCurrentlyLogged: state.table.isCurrentlyLogged
});

export default connect(mapStateToProps, {
  HidePurchase,
  setCurrentlyLoggedUser
})(withRouter(PurchaseCustomQuery));