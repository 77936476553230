import React from 'react'
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css' // If using WebPack and style-loader.

export default function InputTag(props) {

    const handleChange = (tags) => {
        props.onChange(tags)
    }

    return (
        <>

            <TagsInput inputProps={{
                placeholder: 'Input Text Separated by Commas'
            }} value={props.defaultValue} onChange={(e) => handleChange(e)} />
        </>
    )
}



