import React, { useState, useEffect } from "react";
import { Menu, Button } from "antd";
import { Link, useHistory } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import { useSelector, useDispatch } from "react-redux";
import { userSignOutSuccess } from "appRedux/actions/Auth";
import { userSignOutSuccessData } from "appRedux/actions/DataTable";
import { signOut } from "appRedux/amplify";
import ModalData from "../App/ModalData";
// import { setTimeOut } from "appRedux/actions/DataTable";
import { setTimeOut } from "appRedux/actions/SetTime";
import { getSelectedPlanFeature, getFreePlanFeatures } from "../../service/SubscriptionService";
import { setSubscriptionChange } from 'appRedux/actions/DataTable';

const SubMenu = Menu.SubMenu;

const SidebarContent = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [features, setFeatures] = useState(null)
  const { authUserdata } = useSelector(({ table }) => table);
  let { navStyle, themeType, pathname } = useSelector(({ settings }) => settings);
  const user_token = window.sessionStorage.getItem('user_token')
  const [visible, setVisible] = useState(false);
  const [visibledata, setVisibledata] = useState(false);
  const isChangeSubscription = useSelector(({ table }) => table.isChangeSubscription);
  const [typeModal, setTypeModal] = useState(0);
  const [aboutPageUrl, setAboutPageUrl] = useState('https://canojatech.com/about-us');
  const subscriptionId = window.sessionStorage.getItem('subscriptionId')

  const getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  const signIn = () => {
    setTypeModal(1);
    setVisibledata(true)
    setVisible(true);
  }
  const signUp = () => {
    setVisible(false);
    history.push("/subscriptions");
    setTypeModal(0);
    // setTypeModal(2);
    // setVisibledata(true)
    // setVisible(true);
  }

  const handleCancel = () => {
    setVisible(false);
    setTypeModal(0);
  }
  const handelchangeModal = (data) => {
    setTypeModal(data);
    setVisibledata(true)
    setVisible(true);
  }

  useEffect(() => {
    if (isChangeSubscription === true) {
      dispatch(setSubscriptionChange(false));
    }

    const getSubscriptionId = () => {
      let subscriptionId = window.sessionStorage.getItem('subscriptionId')
      if (subscriptionId) {
        getFeatures(subscriptionId)
      } else {
        getFreePlan()
      }
    }

    const getFeatures = async (subscriptionId) => {
      let data = await getSelectedPlanFeature(subscriptionId)
      if (data === false) {
        console.log('error')
      } else {
        setFeatures(data[0])
      }
    }

    const getFreePlan = async () => {
      let data = await getFreePlanFeatures()
      if (data === false) {
        console.log('error')
      } else {
        setFeatures(data[0])
      }
    }

    getSubscriptionId()
  }, [isChangeSubscription, dispatch, subscriptionId]);

  useEffect(() => {
    let env = localStorage.getItem('envSetting')
    if (env) {
      env = JSON.parse(env)
      if (env) {

        setAboutPageUrl(env['REACT_APP_ABOUT_PAGE_URL'])
      }
    }
  }, [])

  const logOut = () => {
    if (window.sessionStorage.getItem('isProcessRunning') === 'true') {
      history.push("/home");
    } else {
      dispatch(setTimeOut(null));
      signOut(dispatch(userSignOutSuccess()));
      signOut(dispatch(userSignOutSuccessData()));
      window.sessionStorage.clear()
      history.push("/home");
    }
  }

  const profile = () => {
    history.push("/profile");
  }

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];
  const isProcessRunning = window.sessionStorage.getItem('isProcessRunning')
  return (
    <>
      <SidebarLogo />
      <div className="gx-sidebar-content">
        <div className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}>
          <UserProfile />
          <AppsNavigation />
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? 'lite' : 'green'}
            mode="inline">

            <Menu.Item key="home">
              <Link to="/home">
                DASHBOARD
              </Link>
            </Menu.Item>

            <SubMenu key="license-search" className={getNavStyleSubMenuClass(navStyle)}
              title={<span>LICENSE SEARCH</span>}>
              <Menu.Item key="license-search/number" className={features && features['isAllowLicenseNumberSearch'] === 'true' ? '' : 'premium-option'} >
                {features && features['isAllowLicenseNumberSearch'] === 'true' ?
                  <Link to="/license-search/number" >License Number</Link>
                  :
                  <span className='premium-option'>License Number</span>
                }
              </Menu.Item>



              <Menu.Item key="license-search/category" className={features && features['isAllowLicenseCategorySearch'] === 'true' ? '' : 'premium-option'}>
                {features && features['isAllowLicenseCategorySearch'] === 'true' ?
                  <Link to="/license-search/category">License Category</Link>
                  :
                  <span>License Category</span>
                }
              </Menu.Item>


              <Menu.Item key="license-search/name" className={features && features['isAllowNameSearch'] === 'true' ? '' : 'premium-option'}>
                {features && features['isAllowNameSearch'] === 'true' ?
                  <Link to="/license-search/name">Business Name</Link>
                  :
                  <span>Business Name</span>
                }
              </Menu.Item>



              <Menu.Item key="license-search/city" className={features && features['isAllowCitySearch'] === 'true' ? '' : 'premium-option'}>
                {features && features['isAllowCitySearch'] === 'true' ?
                  <Link to="/license-search/city">Business City</Link>
                  :
                  <span>Business City</span>
                }
              </Menu.Item>


              <Menu.Item key="license-search/state" className={features && features['isAllowStateSearch'] === 'true' ? '' : 'premium-option'}>
                {features && features['isAllowStateSearch'] === 'true' ?
                  <Link to="/license-search/state">Business State</Link>
                  :
                  <span>Business State</span>
                }
              </Menu.Item>


              <Menu.Item key="license-search/country" className={features && features['isAllowCountrySearch'] === 'true' ? '' : 'premium-option'}>
                {features && features['isAllowCountrySearch'] === 'true' ?
                  <Link to="/license-search/country">Business Country</Link>
                  :
                  <span>Business Country (Premium)</span>
                }
              </Menu.Item>


              <Menu.Item key="license-search/type" className={features && features['isAllowLicenseTypeSearch'] === 'true' ? '' : 'premium-option'}>
                {features && features['isAllowLicenseTypeSearch'] === 'true' ?
                  <Link to="/license-search/type">License Type</Link>
                  :
                  <span>License Type (Premium)</span>
                }
              </Menu.Item>

              <Menu.Item key="license-search/status" className={features && features['isAllowLicenseStatusSearch'] === 'true' ? '' : 'premium-option'}>
                {features && features['isAllowLicenseStatusSearch'] === 'true' ?
                  <Link to="/license-search/status">License Status</Link>
                  :
                  <span >License Status (Premium)</span>
                }
              </Menu.Item>

              <Menu.Item key="license-search/owner" className={features && features['isAllowLicenseOwnerSearch'] === 'true' ? '' : 'premium-option'}>
                {features && features['isAllowLicenseOwnerSearch'] === 'true' ?
                  <Link to="/license-search/owner">License Owner</Link>
                  :
                  <span>License Owner (Premium)</span>
                }
              </Menu.Item>

              <Menu.Item key="license-search/issuedate" className={features && features['isAllowLicenseIssueDateSearch'] === 'true' ? '' : 'premium-option'}>
                {features && features['isAllowLicenseIssueDateSearch'] === 'true' ?
                  <Link to="/license-search/issuedate">License Issue Date</Link>
                  :
                  <span>License Issue Date (Premium)</span>
                }
              </Menu.Item>

              <Menu.Item key="license-search/expiredate" className={features && features['isAllowLicenseExpireDateSearch'] === 'true' ? '' : 'premium-option'}>
                {features && features['isAllowLicenseExpireDateSearch'] === 'true' ?
                  <Link to="/license-search/expiredate">License Expire Date</Link>
                  :
                  <span>License Expire Date (Premium)</span>
                }
              </Menu.Item>

              <Menu.Item key="license-search/dba" className={features && features['isAllowDBASearch'] === 'true' ? '' : 'premium-option'}>
                {features && features['isAllowDBASearch'] === 'true' ?
                  <Link to="/license-search/dba">Business DBA</Link>
                  :
                  <span>Business DBA (Premium)</span>
                }
              </Menu.Item>

              <Menu.Item key="license-search/county" className={features && features['isAllowCountySearch'] === 'true' ? '' : 'premium-option'}>
                {features && features['isAllowCountySearch'] === 'true' ?
                  <Link to="/license-search/county">Business County</Link>
                  :
                  <span>Business County (Premium)</span>
                }
              </Menu.Item>

              <Menu.Item key="license-search/zipcode" className={features && features['isAllowPostalCodeSearch'] === 'true' ? '' : 'premium-option'}>
                {features && features['isAllowPostalCodeSearch'] === 'true' ?
                  <Link to="/license-search/zipcode" >Business Zip Code</Link>
                  :
                  <span>Business Zip Code (Premium)</span>
                }
              </Menu.Item>

              <Menu.Item key="license-search/phonenumber" className={features && features['isAllowPhoneNumberSearch'] === 'true' ? '' : 'premium-option'}>
                {features && features['isAllowPhoneNumberSearch'] === 'true' ?
                  <Link to="/license-search/phonenumber">Business Phone Number</Link>
                  :
                  <span>Business Phone Number (Premium)</span>
                }
              </Menu.Item>

              <Menu.Item key="license-search/email" className={features && features['isAllowEmailSearch'] === 'true' ? '' : 'premium-option'}>
                {features && features['isAllowEmailSearch'] === 'true' ?
                  <Link to="/license-search/email">Business Email</Link>
                  :
                  <span>Business Email (Premium)</span>
                }
              </Menu.Item>

              {/* <Menu.Item key="license-search/lastUpdatedDate" className={features && features['isAllowLastUpdatedDateSearch'] === 'true' ? '' : 'premium-option'}>
                {features && features['isAllowLastUpdatedDateSearch'] === 'true' ?
                  <Link to="/license-search/lastupadted">Last Updated Date</Link>
                  :
                  <span>Last Updated Date (Premium)</span>
                }
              </Menu.Item> */}

            </SubMenu>

            <Menu.Item className={getNavStyleSubMenuClass(navStyle)} key="customequery" title='Custome Query'>
              <Link to="/customquery">QUERY WORKBENCH</Link>
            </Menu.Item>


            {user_token &&
              <Menu.Item key="reports" >
                <Link to="/report">REPORTS</Link>
              </Menu.Item>
            }
            <Menu.Item key="subscription">
              <Link to="/subscriptions">
                SUBSCRIPTIONS
              </Link>
            </Menu.Item>

            <Menu.Item key="about">
              <a href={aboutPageUrl} target='blank'>ABOUT</a>
            </Menu.Item>

            {
              !authUserdata && user_token === null ?
                <>
                  <Button type="link"
                    className="ant-menu-item gx-pointer gx-d-block"
                    onClick={signIn}
                    style={{
                      pointerEvents: "inherit",
                      color: "#5FA30F",
                      marginRight: '0px',
                      padding: '0px',
                      margin: '0px',
                      textAlign: 'inherit',
                      fontSize: '14px'
                    }}
                  >
                    LOGIN
                  </Button>
                  <Button type="link"
                    className="ant-menu-item gx-pointer gx-d-block"
                    onClick={signUp}
                    style={{
                      pointerEvents: "inherit",
                      color: "#5FA30F",
                      marginRight: '0px',
                      padding: '0px',
                      margin: '0px',
                      textAlign: 'inherit',
                      fontSize: '14px'
                    }}
                  >
                    REGISTER
                  </Button>
                  {/* <a onClick={signIn} className="ant-menu-item gx-pointer gx-d-block">SIGN IN</a>
                  <a onClick={signUp} className="ant-menu-item gx-pointer gx-d-block">SIGN UP</a> */}
                </> :
                <>
                  <Button type="link"
                    className="ant-menu-item gx-pointer gx-d-block"
                    onClick={profile}
                    style={{
                      pointerEvents: "inherit",
                      color: "#5FA30F",
                      marginRight: '0px',
                      padding: '0px',
                      margin: '0px',
                      textAlign: 'inherit',
                      fontSize: '14px'
                    }}
                  >
                    PROFILE
                  </Button>
                  {isProcessRunning === 'true' ?
                    <Link to="/home">LOG OUT</Link>
                    :
                    <Button type="link"
                      className="ant-menu-item gx-pointer gx-d-block"
                      onClick={logOut}
                      style={{
                        pointerEvents: "inherit",
                        color: "#5FA30F",
                        marginLeft: '0px',
                        padding: '0px',
                        margin: '0px',
                        fontSize: '14px',
                        textAlign: 'inherit'

                      }}
                    >
                      LOG OUT
                </Button>
                  }

                  {/* <a onClick={profile} className="ant-menu-item gx-pointer gx-d-block">PROFILE</a>
                  <a onClick={logOut} className="ant-menu-item gx-pointer gx-d-block">LOG OUT</a> */}
                </>
            }
            <Menu.Item key="support">
              <Link to="/support">
                <img alt="" style={{ width: '44px' }} src={require("assets/images/great_customer_service.png")} />
              </Link>
            </Menu.Item>
            {visibledata ? <ModalData visible={visible} handleCancel={handleCancel} typeModal={typeModal} showmodaldata={(data) => handelchangeModal(data)} /> : null}

          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};
export default SidebarContent;

