import { SET_TIMEOUT } from '../../constants/ActionTypes';

const INIT_STATE = {
    title: '',
    name: '',
    total: 0,
    isPurchase: false,
    authPurchase: false,
    filter: {},
    sorter: {},
    filters: {},
    count: '',
    setLimit: 0
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case SET_TIMEOUT:
            return {
                ...state,
                timeOut: action.payload
            };

        default:
            return state;
    }
}
