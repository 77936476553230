import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Modal, Col, Spin, Row, Icon } from "antd";
import { API, graphqlOperation } from "aws-amplify";
import { withRouter } from "react-router-dom";
import './CardDetailsPopup.css'
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import { searchEnvVariables } from "../../graphql/queries";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import images from 'react-payment-inputs/images'
import { css } from 'styled-components';
import { getEnvByName } from "../../service/GeneralService";

const FormItem = Form.Item;

const CardDetails = (props) => {
    const [loading, setLoading] = useState(false);
    const [obj, setObj] = useState({})
    const [apiKey, setApiKey] = useState('')
    const [isAdrsErr, setIsAdrsErr] = useState(false)
    const [isCardErr, setIsCardErr] = useState(false)
    const [isShowCardError, setIsShowCardError] = useState(true)
    const { meta, getCardNumberProps, getExpiryDateProps, getCVCProps, getCardImageProps, wrapperProps } = usePaymentInputs();
    let card = ['Visa', 'Mastercard', 'American Express', 'JCB', 'Discover', 'Diners Club']
    const [cardErrors, setCardErrors] = useState({})
    const [cardErrorMessage, setCardErrorMessage] = useState(null)
    const [isShowPopup, setIsShowPopup] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (obj && obj.address && obj.address !== '') {
            setIsAdrsErr(false)
        }
        else {
            setLoading(false)
            setIsAdrsErr(true)
        }

        let isShowCardError = true
        if (meta && meta.cardType && meta.cardType.displayName) {
            let matchingIndex = card.findIndex((item) => item === meta.cardType.displayName)
            if (matchingIndex > -1) {
                isShowCardError = false
            }
        }
        if (isShowCardError) {
            setIsCardErr(true)
        } else {
            setIsCardErr(false)
        }
        if (!isShowCardError && !isAdrsErr) {
            props.form.validateFields((err, values) => {
                if (!err) {
                    var customerId = window.sessionStorage.getItem('customerId')
                    if (customerId) {
                        setLoading(true)
                        handleCheckout(customerId)
                    } else {
                        message.error('Customer is not define')
                    }
                }
            });
        }
    }
    useEffect(() => {
        async function fetchEnv() {
            let data = localStorage.getItem('alertSettings')
            if (data) {
                data = JSON.parse(data)
                if (data) {
                    setCardErrors(data)
                }

            }
            let envString = `'REACT_APP_GOOGLE_ADDRESS_API_KEY'`
            let envList = await getEnvByName(envString)
            if (envList !== false) {
                if (envList['REACT_APP_GOOGLE_ADDRESS_API_KEY']) {
                    setApiKey(envList['REACT_APP_GOOGLE_ADDRESS_API_KEY'])
                }
            }

        }
        fetchEnv()
    }, [])

    const handleCheckout = async (customerId) => {
        let expiryDate = obj.cardExpiryDate.replace(/[^A-Z0-9]/ig, "");
        let card_number = obj.cardNumber.replace(/[^A-Z0-9]/ig, "");
        if (obj.address && obj.address !== '') {
            setIsAdrsErr(false)
            if (customerId) {
                let body = {
                    paymentProfileId: props.paymentProfileId,
                    card_no: card_number,
                    expiry_date: expiryDate,
                    customer_profile_id: customerId,
                    first_name: obj.cardHolderFirstName,
                    last_name: obj.cardHolderLastName,
                    address: obj.address.substring(0, 59),
                    city: obj.locality,
                    zip: obj.postal_code,
                    phone_no: obj.phoneNo,
                    country: obj.country,
                    state: obj.administrative_area_level_1
                }
                await API.put('PaymentGatewayAPI', `/paymentgateway/paymentprofile/update`, { body })
                    .then((result) => {
                        if (result.err) {
                            throw result;
                        }
                        return result;
                    })
                    .then((result) => {
                        message.success('Card details change successfuly')
                        props.confirmCardDetailsChange()
                    })
                    .catch((error) => {
                        let errMessage
                        if (error.err.messages.message[0].code === 'E00027') {
                            errMessage = error.err.messages.message[0].text
                            setCardErrorMessage(cardErrors['invalidCardErrorMessage'])
                        }
                        else {
                            errMessage = error.message
                            if (error.err && error.err.code && error.err.raw && error.err.raw.message) {
                                errMessage = error.err.raw.message
                            } else if (error.error && error.error.decline_code) {
                                errMessage = error.error.decline_code
                            }
                            setCardErrorMessage(cardErrors['generalCardErrorMessage'])
                        }
                        setIsShowPopup(true)
                        setLoading(false)
                    })
            } else {
                setCardErrorMessage(cardErrors['generalCardErrorMessage'])
                setIsShowPopup(true)
                setLoading(false)
            }
        } else {
            setIsAdrsErr(true)
            setLoading(false)
        }
    }

    const handleChange = (name, e) => {
        let value = e.target.value
        let data = {
            ...obj,
            [name]: value
        }
        setObj(data)
        if (name === 'cardNumber') {
            setIsShowCardError(false)
        }
    }

    const handleAddressChange = (e) => {
        if (obj) {
            let placeInfo = obj
            placeInfo.address = e.label
            setObj(placeInfo)
        }
        let value = e.value.place_id
        setIsAdrsErr(false)
        getData(value)
    }



    const getData = async (value) => {
        if (value) {
            if (obj) {
                let placeInfo = obj
                placeInfo.country = null
                placeInfo.postal_code = null
                placeInfo.locality = null
                placeInfo.administrative_area_level_1 = null
                setObj(placeInfo)
            }
            let place_Id = value
            let body = {
                placeId: place_Id
            }
            props.form.resetFields()
            setLoading(true)
            let res = await API.post('CustomerRestAPI', `/customers/getPlaceDetails`, { body })
            if (res.success) {
                let data = res.place
                setObj({ ...obj, ...data })
                setLoading(false)
            } else {
                setLoading(false)
                console.error(res.error)
                message.error('something bad happen')
            }
        }
    }

    const handlePopupClose = () => {
        setIsShowPopup(false)
    }

    const { getFieldDecorator } = props.form;

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 8 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 16 },
        },
    };

    return (
        <div className="purchase-page">

            <Modal
                title="Vertically centered modal dialog"
                centered
                visible={true}
                onOk={(e) => handleSubmit(e)}
            >
                <Spin tip="Loading..." spinning={loading}>
                    <div className='card-number-popup-content2'>
                        <div className='report-modal-content'>
                            <p className='confirm-popup-img'> <img alt="" src={require("assets/images/canoja-leaf-gold.png")} /></p>
                        </div>
                        <div className='report-modal-content card-number-popup-text confirm-popup-text'>
                            <Row>
                                <Col sm={24} xs={24} xl={16}>

                                    <Form className="left-side-form" layout="vertical" onSubmit={(e) => handleSubmit(e)} >

                                        <div className='card-popup'>
                                            <img alt="valid-card" src={require("assets/images/discover.png")} />
                                        </div>
                                        <br />
                                        <FormItem
                                            {...formItemLayout}
                                            className={isShowCardError ? ' reduce-margin ant-form-explain2' : 'card-error-handle reduce-margin ant-form-explain2'}
                                            style={{ marginBottom: '5px' }}
                                            label="Card Number"
                                        >
                                            {getFieldDecorator('cardNumber', {
                                                initialValue: obj.cardNumber,
                                                rules: [{ required: true, message: 'Please input your card Number' }],
                                            })(

                                                <PaymentInputsWrapper {...wrapperProps} styles={{
                                                    fieldWrapper: {
                                                        base: css`
                                                                  width: 100%;
                                                                `
                                                    },
                                                    inputWrapper: {
                                                        base: css`
                                                                  border: none;
                                                                `,
                                                        errored: css`
                                                                  border-color: red;
                                                                `,
                                                        focused: css`
                                                                  border-color: unset;
                                                                  box-shadow: unset;
                                                                  outline: 2px solid green;
                                                                  outline-offset: 2px;
                                                                `
                                                    },
                                                }} >
                                                    <svg {...getCardImageProps({ images })} setFieldsValue={obj.cardNumber} />
                                                    <input {...getCardNumberProps({ placeholder: 'Card Number', onChange: (e) => handleChange('cardNumber', e) })} />
                                                </PaymentInputsWrapper>
                                            )}
                                        </FormItem>

                                        {isCardErr ? <div style={{
                                            color: "red", marginRight: '287px'
                                        }}>
                                            please input valid card details
                                        </div> : ''}
                                        <FormItem
                                            {...formItemLayout}
                                        >
                                            <div className='field2'>
                                                <FormItem
                                                    className={'flex-view-cvv-payment'}
                                                >
                                                    {getFieldDecorator('cardExpiryDate', {
                                                        initialValue: obj.cardExpiryDate,
                                                        rules: [{ required: true, message: 'Please input your card expiry date' }],
                                                    })(
                                                        <input className="card-elements" {...getExpiryDateProps({ onChange: (e) => handleChange('cardExpiryDate', e) })} setFieldsValue={obj.cardExpiryDate} />
                                                    )}

                                                </FormItem>
                                                <FormItem
                                                    className={'flex-view-cvv-payment'}
                                                >
                                                    {getFieldDecorator('cardCVV', {
                                                        initialValue: obj.cardCVV,
                                                        rules: [{ required: true, message: 'Please input your card cvv' }],
                                                    })(
                                                        <input className="card-elements" {...getCVCProps({ onChange: (e) => handleChange('cardCVV', e) })} setFieldsValue={obj.cardCVV} />
                                                    )}
                                                </FormItem>
                                            </div>
                                        </FormItem>
                                        <div className='input-name' >
                                            <FormItem
                                                {...formItemLayout}
                                                label="Card Holder First Name"
                                            >

                                                {getFieldDecorator('cardHolderFirstName', {
                                                    initialValue: obj.cardHolderFirstName,
                                                    rules: [{ required: true, message: 'Please input card holder first name' }],
                                                })(
                                                    <Input placeholder="Card Holder First Name" onChange={e => handleChange('cardHolderFirstName', e)} name='cardHolderFirstName' setFieldsValue={obj.cardHolderFirstName} />
                                                )}


                                            </FormItem>
                                            <FormItem
                                                {...formItemLayout}
                                                label="Card Holder Last Name"
                                            >

                                                {getFieldDecorator('cardHolderLastName', {
                                                    initialValue: obj.cardHolderLastName,
                                                    rules: [{ required: true, message: 'Please input card holder last name' }],
                                                })(
                                                    <Input placeholder="Card Holder Last Name" onChange={e => handleChange('cardHolderLastName', e)} name='cardHolderLastName' setFieldsValue={obj.cardHolderLastName} />
                                                )}
                                            </FormItem>
                                        </div>
                                        <FormItem
                                            {...formItemLayout}
                                            label="Phone-Number"
                                        >
                                            {getFieldDecorator('phoneNo', {
                                                initialValue: obj.phoneNo,
                                                rules: [{
                                                    required: true, message: 'Please input phone number',
                                                    pattern: /[+0-9 -]+$/,
                                                }],
                                            })(
                                                <Input placeholder='Phone Number' name="phoneNo" onChange={e => handleChange('phoneNo', e)} setFieldsValue={obj.phoneNo} />
                                            )}
                                        </FormItem>
                                        {apiKey &&
                                            <FormItem
                                                {...formItemLayout}
                                                label="Address"
                                            >
                                                {getFieldDecorator('address', {
                                                    initialValue: obj.address,
                                                    rules: [{
                                                        required: true, message: 'Please input address',
                                                    }],
                                                })(

                                                    <GooglePlacesAutocomplete
                                                        placeholder='Search your address'
                                                        apiKey={apiKey}
                                                        selectProps={{
                                                            obj,
                                                            onChange: handleAddressChange,
                                                        }}

                                                    />

                                                )}
                                            </FormItem>}
                                        {/* {isAdrsErr ? <div style={{
                                            color: "red", marginRight: '287px'
                                        }}>
                                            please input address
                                        </div> : ''} */}

                                        <FormItem
                                            {...formItemLayout}
                                            label="Country"
                                        >
                                            {getFieldDecorator('Country', {
                                                initialValue: obj.country,
                                                rules: [{
                                                    required: true, message: 'Please input country',
                                                }],
                                            })(
                                                <Input placeholder='Country' name="country" onChange={e => handleChange('country', e)} setFieldsValue={obj.country} />
                                            )}
                                        </FormItem>

                                        <FormItem
                                            {...formItemLayout}
                                            label="State/Province"
                                        >
                                            {getFieldDecorator('State', {
                                                initialValue: obj.administrative_area_level_1,
                                                rules: [{
                                                    required: true, message: 'Please input state',
                                                }],
                                            })(
                                                <Input placeholder='State' name="state" onChange={e => handleChange('administrative_area_level_1', e)} setFieldsValue={obj.administrative_area_level_1} />
                                            )}
                                        </FormItem>

                                        <FormItem
                                            {...formItemLayout}
                                            label="City"
                                        >
                                            {getFieldDecorator('City', {
                                                initialValue: obj.locality,
                                                rules: [{
                                                    required: true, message: 'Please input city',
                                                }],
                                            })(
                                                <Input placeholder='City' name="city" onChange={e => handleChange('locality', e)} setFieldsValue={obj.locality} />
                                            )}
                                        </FormItem>

                                        <FormItem
                                            {...formItemLayout}
                                            label="Zip/Postal Code"
                                        >
                                            {getFieldDecorator('zip', {
                                                initialValue: obj.postal_code,
                                                rules: [{
                                                    required: true, message: 'Please input zip/postalCode',
                                                }],
                                            })(
                                                <Input placeholder='Zip/Postal Code' name="zip" onChange={e => handleChange('postal_code', e)} setFieldsValue={obj.postal_code} />
                                            )}
                                        </FormItem>

                                    </Form>

                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className='modal-button'>
                        <Button onClick={(e) => handleSubmit(e)} type="primary">Ok</Button>
                        <Button onClick={() => { props.cancelCardDetailsChange() }}>Cancel</Button>
                    </div>
                </Spin>
            </Modal>
            <Modal
                title="Vertically centered modal dialog"
                centered
                maskClosable={true}
                onCancel={() => handlePopupClose()}
                visible={isShowPopup}
            >
                <div className='subscription-purchase'>
                    <div className='subscription-purchase-icon-wrapper'>
                        <p><Icon className='subscription-purchase-icon' style={{ fontSize: '16px' }} type="exclamation-circle" /></p>
                    </div>
                    <div className='subscription-purchase-content'>
                        {cardErrorMessage && <p dangerouslySetInnerHTML={{ __html: cardErrorMessage }} />}

                    </div>
                </div>
                <div className='modal-button'>
                    <Button onClick={() => handlePopupClose()} type="primary">Ok</Button>
                </div>
            </Modal>
        </div >
    );
}

const CardDetailsPage = Form.create()(CardDetails);

const CardDetailsElement = (props) => (

    <CardDetailsPage {...props} />

);

export default withRouter(CardDetailsElement);