import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { PURCHAS_ECOLUMN, SAMPLE_DATA_COLUMN } from '../../constants/ActionTypes';
import API from "@aws-amplify/api";

const SampleDataTable = (props) => {
  const [data, setData] = useState([])
  const [dataTitle, setDataTitle] = useState('Sample Data Report')
  const [details, setDetails] = useState('Details added here')

  useEffect(() => {
    async function fetchData() {
      let sampleData = localStorage.getItem('subscriptionSettings')
      if (sampleData) {
        sampleData = JSON.parse(sampleData)
        if (sampleData.sampleDataReportTitle) {
          setDataTitle(sampleData.sampleDataReportTitle)
        }
        if (sampleData.sampleDataReportDetails) {
          setDetails(sampleData.sampleDataReportDetails)
        }
      }
      var res = await API.post('PaymentGatewayAPI', '/paymentgateway/demoData', {})
      if (res.success) {
        res = res.result
        if (res && res.length > 0) {
          let numberList = res
          numberList = res.map((d, i) => {
            return {
              key: i + 1,
              number: d.licenseNumber,
              name: d.businessLegalName,
              business_doing_business_as: d.businessDoingBusinessAs,
              email: d.businessEmailAddress,
              phone: d.businessPhoneNumber,
              address1: d.businessAddress1,
              address2: d.businessAddress2,
              structure: d.businessStructure,
              expire: d.licenseExpireDate,
              issue: d.licenseIssueDate,
              owner: d.licenseOwner,
              county: d.businessCounty,
              type: d.licenseType,
              category: d.licenseCategory,
              status: d.licenseStatus,
              city: d.businessCity,
              state: d.businessState,
              country: d.businessCountry,
              zipcode: d.businessZipcode,
              refreshed_on: d.refreshed_on,
              // license_status_authority: d.license_status_authority,
              // license_type_authority: d.license_type_authority,
              // license_category_authority: d.license_category_authority,
              // lastUpdated: moment(d.last_updated).format('MM-DD-YYYY'),
              medical: d.medical.charAt(0).toUpperCase() + d.medical.slice(1).toLowerCase(),
              retail: d.retail.charAt(0).toUpperCase() + d.retail.slice(1).toLowerCase(),
            }
          })
          setData(numberList)
        }
      } else {
        console.log(JSON.stringify(res))
      }
    }
    fetchData();
  }, [])

  return (
    <>


      <div><b>{dataTitle}</b></div>

      <div style={{ minHeight: 70, border: '1px solid', borderRadius: 10, padding: 10, marginBottom: 20, marginTop: 10 }}>
        <p dangerouslySetInnerHTML={{ __html: details }} />
      </div>


      <Table
        className={"gx-table-responsive"}
        columns={props.showAllColumns ? PURCHAS_ECOLUMN : SAMPLE_DATA_COLUMN}
        loading={props.loading}
        dataSource={data}
        pagination={false}
        bordered
      />
    </>
  );
};

export default SampleDataTable;
