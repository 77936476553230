import React from "react";
import { Button, Checkbox, Form, Input, Select, notification } from "antd";

import { connect } from "react-redux";
import {
  hideMessage,
  showAuthLoader,
  hideAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignUp,
  userTwitterSignIn
} from "appRedux/actions/Auth";
import {
  showModal
} from 'appRedux/actions/Common';
import MaskedInput from 'antd-mask-input'

import IntlMessages from "util/IntlMessages";
import { message } from "antd/lib/index";
import CircularProgress from "components/CircularProgress/index";
import * as Amplify from 'aws-amplify';


const FormItem = Form.Item;
const Option = Select.Option;

const openNotificationWithIcon = (type, title, description) => {
  notification[type]({
    message: title,
    description: description,
  });
};

class SignUp extends React.Component {
  constructor() {
    super();
    this.state = {
      email: 'demo@example.com',
      password: 'demo#123',
      isChecked: false,
      isAge: false,
      isAgeChecked: true,
      isCheck: true,
      isDisable: false,
      upperState: false,
      numberState: false,
      lengthState: false,
      symbolState: false,
      loader: false,
    }
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    // if (this.props.authUser !== null) {
    //   this.props.history.push('/');
    // }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!this.state.isAge) this.setState({ isAgeChecked: false })
      if (!this.state.isChecked) this.setState({ isCheck: false })
      if (!err && this.state.isChecked && this.state.isAge) {
        // this.props.showAuthLoader();
        // this.props.userSignUp(values);
        this.setState({ loader: true, isDisable: true })
        const numberPattern = /\d+/g;
        const phone = values.phone.match(numberPattern).join([]);

        const data = {
          given_name: values.given_name,
          family_name: values.family_name,
          phone_number: values.prefix + phone,
          email: values.email,
          password: values.password,
          "custom:company-name": values.company || "",
        }

        this.signUp(data);
      }
    });
  };

  signUp = ({ email, password, ...data }) => {
    Amplify.Auth.signUp({
      username: email,
      password: password,
      attributes: data
    })
      .then(success => {
        openNotificationWithIcon('success', 'Success', 'Signup has been successfully done');
        this.setState({ loader: false, isDisable: false })
        window.sessionStorage.setItem("email", email);
        this.props.showmodaldata(3);
      })
      .catch(err => {
        if (err.message.includes("password") || err.message.includes("Password")) {
          document.getElementById("passwordRequirements").style.display = "block";

          password.length > 7 ? this.setState({ lengthState: true }) : this.setState({ lengthState: false });


          /[A-Z]/.test(password) ? this.setState({ upperState: true }) : this.setState({ upperState: false });

          /\d/.test(password) ? this.setState({ numberState: true }) : this.setState({ numberState: false });

          const format = /[!@#$%^&*()_+\-={};':"\\|,.<>?]+/
            ;
          format.test(password) ? this.setState({ symbolState: true }) : this.setState({ symbolState: false })
        } else {
          document.getElementById("passwordRequirements").style.display = "none";
        }
        openNotificationWithIcon('error', 'Fail', err.message);
        this.setState({ loader: false, isDisable: false })
        this.props.hideAuthLoader();
      })

  }

  handleCheckChange = () => {
    this.setState({ isChecked: !this.state.isChecked })
    if (!this.state.isChecked) this.setState({ isCheck: true })
  }

  handleAgeConfirm = () => {
    this.setState({ isAge: !this.state.isAge })
    if (!this.state.isAge) this.setState({ isAgeChecked: true })
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('The passwords you entered do not match!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.props.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { showMessage, alertMessage } = this.props;

    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '+1',
    })(
      <Select style={{ width: 70 }}>
        <Option value="+1">+1</Option>
      </Select>
    );

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
              </div>
              <div className="gx-app-logo" style={{ marginBottom: "auto" }}>
                <img alt="example" src={require("assets/images/logo.png")} />
              </div>
              <div className="gx-app-logo-wid" style={{ marginBottom: 0 }}>
                <h1><IntlMessages id="app.userAuth.signUp" /></h1>
                <p><IntlMessages id="app.userAuth.bySigning" /></p>
                <p><IntlMessages id="app.userAuth.getAccount" /></p>
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
                <FormItem
                  label="First / Given Name"
                >
                  {getFieldDecorator('given_name', {
                    rules: [{ required: true, message: 'Please input your given name!' }],
                  })(
                    <Input placeholder="First / Given Name" />
                  )}
                </FormItem>

                <FormItem
                  label="Last / Family Name"
                >
                  {getFieldDecorator('family_name', {
                    rules: [{ required: true, message: 'Please input your family name!' }],
                  })(
                    <Input placeholder="Last / Family Name" />
                  )}
                </FormItem>

                <FormItem
                  label="Email Address"
                >
                  {getFieldDecorator('email', {
                    rules: [{
                      required: true, type: 'email', message: 'The input is not valid E-mail!',
                    }],
                  })(
                    <Input placeholder="Email Address" />
                  )}
                </FormItem>

                <FormItem
                  className={!this.state.isAge ? 'has-error' : null}
                >
                  {getFieldDecorator('Age Confirmation')(
                    <Checkbox checked={this.state.isAge} onChange={this.handleAgeConfirm}>Are you over 21?</Checkbox>
                  )}
                  {!this.state.isAgeChecked ? <div className='ant-form-explain'>Please confirm you are over 21!</div> : null}
                </FormItem>

                <FormItem
                  label="Phone Number"
                >
                  {getFieldDecorator('phone', {
                    rules: [{ required: true, message: 'Please input your phone number!' }],
                  })(
                    <MaskedInput addonBefore={prefixSelector} mask="(111) 111-1111" name="card" />
                  )}
                </FormItem>

                <FormItem
                  label="Company Name"
                >
                  {getFieldDecorator('company', {
                  })(
                    <Input type="text" placeholder="Company name" />
                  )}
                </FormItem>

                <FormItem
                  label="Password"
                >
                  {getFieldDecorator('password', {
                    rules: [{
                      required: true, message: 'Please input your Password!'
                    }, {
                      validator: this.validateToNextPassword,
                    }],
                  })(
                    <Input type="password" placeholder="Password" />
                  )}
                </FormItem>

                <div id="passwordRequirements" style={{ paddingLeft: 30, paddingRight: 5 }}>
                  <span className={this.state.lengthState ? "set-green" : "set-red"}>* More than 8 characters.</span> <br />
                  <span className={this.state.upperState ? "set-green" : "set-red"}>* At least 1 Uppercase.</span> <br />
                  <span className={this.state.numberState ? "set-green" : "set-red"}>* At least 1 Number.</span> <br />
                  <span className={this.state.symbolState ? "set-green" : "set-red"}>* At least 1 Symbol.</span> <br />
                </div>

                <FormItem
                  label="Confirm Password"
                >
                  {getFieldDecorator('confirm', {
                    rules: [{
                      required: true, message: 'Please confirm your password!',
                    }, {
                      validator: this.compareToFirstPassword,
                    }],
                  })(
                    <Input placeholder="Retype New Password" type="password" />
                  )}
                </FormItem>

                <FormItem
                  className={!this.state.isAgeChecked ? 'has-error' : null}
                >
                  {getFieldDecorator('Agreement')(
                    <Checkbox checked={this.state.isChecked} onChange={this.handleCheckChange}><IntlMessages id="appModule.iAccept" /></Checkbox>
                  )}
                  <span className="gx-link gx-signup-form-forgot" onClick={() => { window.open("https://canoja-verify-images.s3.amazonaws.com/CanojaTech-Privacy-Policy-v3.pdf") }}>Privacy Policy </span>
                      and
                    <span className="gx-link gx-signup-form-forgot" onClick={() => { window.open("https://canoja-verify-images.s3.amazonaws.com/CanojaTech-Terms-of-service-v3.pdf") }}> Terms of Service</span>
                  {!this.state.isCheck ? <div className='ant-form-explain'>Agree to Terms of Service and Privacy Policy to continue!</div> : null}
                </FormItem>

                <FormItem style={{ display: 'inline-flex' }}
                >
                  <Button type="primary" className="gx-mb-0" htmlType="submit" disabled={this.state.isDisable}>
                    <IntlMessages id="app.userAuth.signUp" />
                  </Button>
                  <span ><IntlMessages id="app.userAuth.or" /></span> <li className="gx-pointer gx-d-block" to={''} style={{ marginLeft: 5, marginTop: 5, color: '#5FA30F' }} onClick={() => { this.props.showmodaldata(1) }}><IntlMessages
                    id="app.userAuth.signIn" /></li>
                </FormItem>
              </Form>
            </div>
            {this.state.loader &&
              <div className="gx-loader-view">
                <CircularProgress />
              </div>
            }
            {showMessage &&
              message.error(alertMessage)}
          </div>
        </div>
      </div>
    );
  }

}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, {
  userSignUp,
  hideMessage,
  showAuthLoader,
  hideAuthLoader,
  userFacebookSignIn,
  userGoogleSignIn,
  userGithubSignIn,
  userTwitterSignIn,
  showModal
})(WrappedSignUpForm);
