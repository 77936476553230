import { LICENCE_NUMBER, RESET_PURCHASE, DO_PURCHASE, IS_PURCHASE, SET_SUBSCRIPTION_CHANGE, SAVE_FILTER, SAVE_SORTER, SAVE_FILTERS, RESET_FILTERS, SIGNIN_USER_SUCCESS_DATA, SIGNOUT_USER_SUCCESS_DATA, SET_CURRENTLY_LOGGED_USER } from 'constants/ActionTypes'

const INIT_STATE = {
  title: '',
  name: '',
  total: 0,
  isPurchase: false,
  authPurchase: false,
  filter: {},
  sorter: {},
  filters: {},
  count: '',
  setLimit: 0,
  isCurrentlyLogged: false,
  isChangeSubscription: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case LICENCE_NUMBER: {
      return {
        ...state,
        title: action.payload.title,
        name: action.payload.name,
        isPurchase: true
      };
    }
    case RESET_PURCHASE:
      return {
        ...state,
        title: '',
        name: '',
        total: 0,
        isPurchase: false,
        count: '',
      }
    case DO_PURCHASE:
      return {
        ...state,
        isPurchase: true
      }
    case IS_PURCHASE:
      return {
        ...state,
        authPurchase: true
      }
    case SAVE_FILTER:
      return {
        ...state,
        filter: action.payload
      }
    case SAVE_SORTER:
      return {
        ...state,
        sorter: action.payload
      }
    case SIGNIN_USER_SUCCESS_DATA:
      return {
        ...state,
        authUserdata: action.payload
      }
    case SIGNOUT_USER_SUCCESS_DATA: {
      return {
        ...state,
        authUser: null,
        authUserdata: null,
        initURL: '/',
        loader: false
      }
    }
    case SAVE_FILTERS:
      return {
        ...state,
        filters: Object.assign({}, state.filters, action.payload)
      }
    case RESET_FILTERS:
      return {
        ...state,
        filter: {},
        sorter: {},
        filters: {}
      }
    // case SET_TIMEOUT:
    //   return {
    //     ...state,
    //     timeOut: action.payload
    //   };

    case SET_CURRENTLY_LOGGED_USER:
      return {
        ...state,
        isCurrentlyLogged: action.payload
      };

    case SET_SUBSCRIPTION_CHANGE:
      return {
        ...state,
        isChangeSubscription: action.payload
      };

    default:
      return state;
  }
}
