
console.log(process.env.NODE_ENV);

export const REACT_APP_REGION = process.env.REACT_APP_REGION;
export const REACT_APP_ACCESS_KEY_ID = process.env.REACT_APP_ACCESS_KEY_ID;
export const REACT_APP_SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
export const REACT_APP_ACCESS_DOMAIN = process.env.REACT_APP_DOMAIN;
export const REACT_APP_AWS_APPSYNC_AUTH_TYPE = process.env.REACT_APP_AWS_APPSYNC_AUTH_TYPE;
export const REACT_APP_AWS_APPSYNC_API_KEY = process.env.REACT_APP_AWS_APPSYNC_API_KEY;
export const REACT_APP_GRAPHQL_ENDPOINT = process.env.REACT_APP_GRAPHQL_ENDPOINT;
export const REACT_APP_API_DOMAIN = process.env.REACT_APP_API_DOMAIN;
export const REACT_APP_DECREPT_KEY = process.env.REACT_APP_DECREPT_KEY

export const REACT_APP_USER_POOL_ID = process.env.REACT_APP_USER_POOL_ID

export const AMPLIFY_AUTH = {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    mandatorySignIn: false,
    oauth: {
        domain: process.env.REACT_APP_AUTH_DOMAIN,
        scope: ['phone', 'email', 'profile', 'aws.cognito.signin.user.admin', 'openid'],
        redirectSignIn: process.env.REACT_APP_DOMAIN,
        redirectSignOut: process.env.REACT_APP_DOMAIN,
        // responseType: 'code',
        responseType: 'token',
        options: {
            AdvancedSecurityDataCollectionFlag: false
        }
    }
};

export const AMPLIFY_STORAGE = {
    bucket: process.env.REACT_APP_S3_BUCKET_NAME,
    region: process.env.REACT_APP_REGION,
};


export const AMPLIFY_CUSTOMER_REST_API = {
    "name": "CustomerRestAPI",
    "endpoint": process.env.REACT_APP_CUSTOMER_REST_API_URL,
    "region": process.env.REACT_APP_REGION
}

export const AMPLIFY_PAYMENT_GATEWAY = {
    "name": "PaymentGatewayAPI",
    "endpoint": process.env.REACT_APP_PAYMENT_GATEWAY_URL,
    "region": process.env.REACT_APP_REGION
}