import React, { Component } from 'react';
import { connect } from 'react-redux';
import './PayButton.css';
import { Button, CircularProgress, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { API } from 'aws-amplify';
import { doPurchase, toProfile } from 'appRedux/actions/DataTable';
import { currentUser } from 'appRedux/amplify';
import { withRouter } from "react-router";
import {
  showModal
} from 'appRedux/actions/Common';
import ModalData from '../../containers/App/ModalData';
import notify from '../../Notification';
import { message, Spin, Modal } from 'antd';
import { getCouponByCode, checkCouponIsValid, addUsageForCoupon } from '../../service/SubscriptionService';
import { checkSubscriptionStatus } from '../../service/GeneralService';

class PayButtonContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      visible: false,
      visibledata: false,
      isShowModalStatus: false,
      typeModal: 0,
      subscriptionStatusErrorMessage: 'status error',
      alertMessage: 'Something bad happen on server',
      purchaseMessage: 'Adding CanojaVerify Record Usage Charge to your plan..',
      isShowModal: false
    }
    this.handlePay = this.handlePay.bind(this)
  }

  componentDidMount() {
    this.fetchErrorData()
  }

  fetchErrorData = () => {
    let alertData = localStorage.getItem('alertSettings')
    if (alertData) {
      alertData = JSON.parse(alertData)
      if (alertData.genericAlert) {
        this.setState({ alertMessage: alertData.genericAlert })
      }
      if (alertData.reportPurchase) {
        this.setState({ purchaseMessage: alertData.reportPurchase })
      }
    }
  }


  saveReportData = async (totalAmount, discount, isCouponExist) => {
    window.sessionStorage.setItem('isFrom', this.props.isFrom)
    window.sessionStorage.setItem("discount", discount);
    window.sessionStorage.setItem("promotionCode", !isCouponExist ? 'Not Applicable' : this.props.promotioncode);
    window.sessionStorage.setItem("totalAmount", totalAmount);
    window.sessionStorage.setItem("amount", this.props.amount);
    window.sessionStorage.setItem("reportname", this.props.reportname);
    window.sessionStorage.setItem("description", this.props.description);
    window.sessionStorage.setItem("amountPerRecord", this.props.amountPerRecord);
  }

  handlePay = async () => {
    if (!this.props.disabled) {
      var user_token = window.sessionStorage.getItem('user_token')
      if (user_token) {
        currentUser()
          .then(async user => {
            if (user) {
              if (this.props.subscriptionId || this.props.isBasicPlan === true) {
                let isValidSubscriptionStatus = await checkSubscriptionStatus(this.props.subscriptionStatus)
                if (isValidSubscriptionStatus === true) {
                  this.handlePurchase()
                } else {
                  this.setState({ subscriptionStatusErrorMessage: isValidSubscriptionStatus, isShowModalStatus: true })
                }
                this.setState({ loading: false })
              } else {
                notify.openNotificationWithIcon('info', 'Information', "Any subscription not subscribe by user");
              }
            } else {
              notify.openNotificationWithIcon('info', 'Information', "Please wait for a moment, Another Report is generating now");
            }
            this.setState({ loading: false })
          })

          .catch(err => {
            console.log(err)
            message.error(<p dangerouslySetInnerHTML={{ __html: this.state.alertMessage }} />);
            this.setState({ visibledata: false })
            this.setState({ loading: false, })
          })
      } else {
        this.props.toProfile(true);
        this.setState({ visibledata: true, visible: true, typeModal: 1 })
      }
    } else {
      this.props.validateCheck();
    }
  }

  handlePurchase = async () => {
    
    this.setState({ loading: true })
    let promotioncode = ''
    let isCouponExist = false
    let coupon = ''
    let errorMessage = 'Promotion Code not exist'

    if (this.props.promotioncode !== '') {

      let promotionCodes = await getCouponByCode(this.props.promotioncode, this.props.userPlan)
      if (promotionCodes !== false && promotionCodes) {

        let isValidCoupon = await checkCouponIsValid(promotionCodes, this.props.userPlan, 'reports')
        if (isValidCoupon === true) {
          isCouponExist = true
          promotioncode = true
          coupon = promotionCodes
        } else {
          errorMessage = isValidCoupon
        }
      }

    }

    let body
    if (promotioncode || !isCouponExist) {

      this.props.showPromotionCodeError(null)
      var totalAmount = this.props.amount
      let discount = 0

      if (coupon.amountOff && coupon.couponType === 'amountOff') {
        discount = Number(coupon.amountOff) 
      } else if (coupon.percentageOff && coupon.couponType === 'percentageOff') {
        discount = (totalAmount * Number(coupon.percentageOff)) / 100
      }

      totalAmount = totalAmount - discount
      body = {
        plan: this.props.userPlan,
        customer_profile_id: this.props.customerId,
        interval: 'month',
        discount: discount,
        description: `CanojaVerify Record Usage for ${this.props.reportname}`,
        product_name: this.props.userPlan,
        no_of_records_fetch: this.props.totalCounts
      }

      if (this.props.userPlan && (this.props.userPlan === 'ENTERPRISE' || this.props.userPlan === 'PREMIUM')) {

        this.setState({ isShowModal: true })
        let response = await API.post(this.props.apiName, "/paymentgateway/charge", { body })
        if (response.success) {
          window.sessionStorage.setItem("isPurchase", true);
          await this.saveReportData(totalAmount, discount, isCouponExist)
          this.props.history.push("/report");
        } else {
          message.error(response.err)
        }
        this.setState({ isShowModal: false })

      } else {

        body = {
          interval: 'month',
          customer_profile_id: this.props.customerId,
          no_of_records_fetch: this.props.totalCounts,
          product_name: this.props.userPlan,
          discount: discount,
          description: `CanojaVerify Record Usage for ${this.props.reportname}`,
        }
        this.setState({ isShowModal: true })
        let response = await API.post(this.props.apiName, "/paymentgateway/addcharge", { body })
        if (response.success) {
          await addUsageForCoupon(discount, promotioncode)
          window.sessionStorage.setItem("isPurchase", true);
          await this.saveReportData(totalAmount, discount, isCouponExist)
          this.props.history.push("/report");
        } else {
          message.error(response.err)
        }
        this.setState({ isShowModal: false })

      }

    } else {
      this.props.showPromotionCodeError(errorMessage)
    }
  }

  handleCancel = () => {
    this.setState({ visible: false, typeModal: 0 })
  }

  handelchangeModal = (data) => {
    this.setState({ visible: true, visibledata: true, typeModal: data })
  }

  handleModalStatusClose = () => {
    this.setState({ isShowModalStatus: false, })
  }


  render() {
    const numberWithCommas = (x) => {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    const { loading, visible, visibledata, typeModal } = this.state;
    const {  discountedPrice, currency } = this.props;
    return (
      <>
        <Box display="flex" flexDirection="row-reverse" flexWrap="wrap" alignItems="center">
          <Button disabled={this.props.disabled || loading} onClick={this.handlePay} variant="contained" color="secondary">  Pay (${numberWithCommas(parseFloat(discountedPrice).toFixed(2))} {currency})</Button>
          <Box marginRight={2} />
          {
            loading ?
              <CircularProgress color="secondary" />
              :
              null
          }
        </Box>
        {visibledata ? <ModalData visible={visible} handleCancel={this.handleCancel} typeModal={typeModal} showmodaldata={(data) => this.handelchangeModal(data)} /> : null}
        <Modal
          title="Vertically centered modal dialog"
          centered
          visible={this.state.isShowModal}
        >
          <div className='payment-popup-content2 display-payment-content'>
            <div className='payment-modal-content2 icon-loader'>
              <p><Spin size="large" /></p>
            </div>
            <div className='payment-modal-content2 confirm-popup-text'>
              <p dangerouslySetInnerHTML={{ __html: this.state.purchaseMessage }} />
            </div>
          </div>
        </Modal>

        <Modal
          title="Vertically centered modal dialog"
          centered
          visible={this.state.isShowModalStatus}
          onCancel={this.handleModalStatusClose}
        >
          <div className='payment-popup-content2 display-payment-content'>
            <div className='payment-modal-content2 icon-loader'>
              <p><img alt="" src={require("assets/images/canoja-leaf-gold.png")} /></p>
            </div>
            <div className='payment-modal-content2 confirm-popup-text'>
              <div>{this.state.subscriptionStatusErrorMessage}</div>
            </div>
          </div>
          <div className='modal-button'>
            <Button onClick={this.handleModalStatusClose} variant="contained" color="secondary" style={{marginBottom : '10px'}}>Ok</Button>
          </div>
        </Modal>
      </>
    );
  }
}

PayButtonContent.propTypes = {
  apiName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  reportname: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  quantity: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
});

export default connect(mapStateToProps, {
  doPurchase,
  toProfile,
  showModal
})(withRouter(PayButtonContent));