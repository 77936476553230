import React from "react";
import { Button, Form, Input, message, Tooltip, Icon } from "antd";
import { connect } from "react-redux";

import {
  hideMessage,
  showAuthLoader,
  hideAuthLoader
} from "appRedux/actions/Auth";
import CircularProgress from "components/CircularProgress/index";
import * as Amplify from 'aws-amplify';
import notify from '../Notification';
import {
  showModal
} from 'appRedux/actions/Common';

const FormItem = Form.Item;

class Reset extends React.Component {
  constructor() {
    super()
    this.state = {
      loader: false
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        // this.props.showAuthLoader();
        // this.props.userSignIn(values);
        this.setState({ loader: true })
        const email =  window.sessionStorage.getItem("email");
        console.log(email);
        this.resetPassword(email, values.verify_code, values.password);
      }
    });
  };

  resetPassword = (email, code, password) => {
    Amplify.Auth.forgotPasswordSubmit(email, code, password)
      .then(success => {
        notify.openNotificationWithIcon('success', 'Success', 'Password was changed');
        // this.props.hideAuthLoader();
        this.setState({ loader: false })
        this.props.showmodaldata(1);
      })
      .catch(err => {
        console.log(err)
        notify.openNotificationWithIcon('error', 'Fail', err.message);
        // this.props.hideAuthLoader();
        this.setState({ loader: false })
      })
  }

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('The passwords you entered do not match!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.props.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { showMessage, alertMessage } = this.props;
    // const enterCodeError = isFieldTouched('Enter Code From Email') && getFieldError('Enter Code From Email');
    // const newPasswordError = isFieldTouched('Enter New Password') && getFieldError('Enter New Password');
    // const confirmPasswordError = isFieldTouched('Re-Enter New Password') && getFieldError('Re-Enter New Password');

    return (
      <div className="gx-app-login-wrap">
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-content-bg">
              </div>
              <div
                className="gx-app-logo"
                style={{ marginBottom: "auto" }}
              >
                <img alt="example" src={require("assets/images/logo.png")} />
              </div>
              <div className="gx-app-logo-wid">
                <h1>Reset Password</h1>
              </div>
            </div>
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                <FormItem
                  label={<span>
                    Enter code&nbsp;
                  <Tooltip title="Enter Code From Email">
                      <Icon type="question-circle" />
                    </Tooltip>
                  </span>
                  }
                // help={enterCodeError || ''}
                // validateStatus={enterCodeError ? 'error' : ''}
                >
                  {getFieldDecorator('verify_code', {
                    rules: [{
                      required: true, message: 'The input is not valid',
                    }],
                  })(
                    <Input placeholder="code" />
                  )}
                </FormItem>

                <FormItem
                  label={<span>
                    New Password&nbsp;
                  <Tooltip title="Enter New Password">
                      <Icon type="question-circle" />
                    </Tooltip>
                  </span>
                  }
                // help={newPasswordError || ''}
                // validateStatus={newPasswordError ? 'error' : ''}
                >
                  {getFieldDecorator('password', {
                    initialValue: "demo#123",
                    rules: [{
                      required: true, message: 'Please input your Password!'
                    }, {
                      validator: this.validateToNextPassword,
                    }],
                  })(
                    <Input type="password" placeholder="Password" />
                  )}
                </FormItem>

                <FormItem
                  label={<span>
                    Confirm Password&nbsp;
                  <Tooltip title="Re-Enter New Password">
                      <Icon type="question-circle" />
                    </Tooltip>
                  </span>
                  }
                // help={confirmPasswordError || ''}
                // validateStatus={confirmPasswordError ? 'error' : ''}
                >
                  {getFieldDecorator('confirm', {
                    rules: [{
                      required: true, message: 'Please confirm your password!',
                    }, {
                      validator: this.compareToFirstPassword,
                    }],
                  })(
                    <Input placeholder="Retype New Password" type="password" />
                  )}
                </FormItem>

                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit" >
                    Submit
                  </Button>
                </FormItem>
              </Form>
            </div>

            {this.state.loader ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div >
    );
  }
}

const WrappedNormalLoginForm = Form.create()(Reset);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser }
};

export default connect(mapStateToProps, {
  hideMessage,
  showAuthLoader,
  hideAuthLoader,
  showModal
})(WrappedNormalLoginForm);
