
import React from 'react';
import Widget from "../../../components/Widget";
import { Button, Spin, Row, Col, Form, Card, message, Icon, Modal } from "antd";
import QueryBuilder, { formatQuery } from 'react-querybuilder';
import InputText from "../../../components/InputControl/InputText/InputText";
import { API, graphqlOperation } from "aws-amplify";
import InputSelect from "../../../components/InputControl/InputSelect/InputSelect";
import MultipleSelect from "../../../components/InputControl/MultipleSelect/MultipleSelect";
import { getColumns, getSelectedColumns } from "../../../service/ReportService";
import { isEnterprisePlan } from "../../../service/SubscriptionService";
import Search from "antd/lib/input/Search";
import DataTable from "../../../components/Table/Table";
import "react-querybuilder/dist/query-builder.css";
import '../index.css'
import { listAllLicenseCategroy, listAllLicenseStatus, listAllLicenseType, listAllBusinessCountry, listAllBusinessState, listAllBusinessStructure } from "../../../graphql/queries";
import {
    AntDActionElement,
    AntDDragHandle,
    AntDNotToggle,
    AntDValueEditor,
    AntDValueSelector
} from '@react-querybuilder/antd';
import ValueEditor from "./ValueEditor";
import moment from 'moment';


const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 9 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 },
    },
};


class CustomQueryBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            query: {
                id: 'root',
                combinator: 'and',
                not: false,
                rules: []
            },
            tableList: [
                { name: 'License Information', id: 'LicenseInformation' },
            ],
            data: null,
            defaultColumns: [],
            alertMessage: 'Something bad happen on server',
            selectedColumns: null,
            tableColumnsList: null,
            sortedColumn: null,
            queryName: null,
            queryDescription: null,
            loading: false,
            disabled: false,
            pageNumber: 1,
            pageSize: 10,
            total: 0,
            fields: [],
            unsavedChangesAlertMessage: '',
            isShowUnsaveModal: false,
            filters: [],
            sortingOrderList: [
                { name: 'Ascending Order', id: 'ASC' },
                { name: 'descending Order', id: 'DESC' },
                { name: 'None', id: null },
            ],
            options: {
                licenseCategory: [],
                licenseStatus: [],
                businessState: [],
                licenseType: [],
                businessCountry: [],
                businessStructure: [],
                retail: [{ name: 'true', label: 'True' }, { name: 'false', label: 'False' }],
                medical: [{ name: 'true', label: 'True' }, { name: 'false', label: 'False' }],
            }
        }
    }

    componentDidMount() {
        this.checkIsValidPlan()
        if (this.props.location.state && this.props.location.state.editRowData) {
            this.setState({ isLoadingEditData: true })
        }
    }

    checkIsValidPlan = async () => {
        this.setState({ loading: true })
        let alertData = localStorage.getItem('alertSettings')
        if (alertData) {
            alertData = JSON.parse(alertData)
            if (alertData.genericAlert) {
                this.setState({ alertMessage: alertData.genericAlert })
            }
        }
        let unsavedAlertMessage = localStorage.getItem('generalSettings')
        if (unsavedAlertMessage) {
            unsavedAlertMessage = JSON.parse(unsavedAlertMessage)
            if (unsavedAlertMessage.unsavedChangesMessage) {
                this.setState({ unsavedChangesAlertMessage: unsavedAlertMessage.unsavedChangesMessage })
            }
        }
        let isPlanEnterprise = await isEnterprisePlan()
        if (!isPlanEnterprise) {
            this.props.history.push("/home");
        } else {
            await this.fetchFieldOptions()
            // this.setState({ loading: false })
            this.fetchQueryData()
            if (this.props.location.state && this.props.location.state.editRowData) {
                this.getRawData(this.props.location.state.editRowData)

            }
        }
    }

    getRawData = (rawData) => {
        let data = rawData
        data.filter = JSON.parse(data.filter)
        if (data.filter) {
            let coloumn = data.query
            var mySubString = coloumn.substring(
                coloumn.indexOf("select") + 8,
                coloumn.lastIndexOf("from")
            ).split(', ')
            this.fetchFieldData(mySubString)
            this.setState({ query: data.filter, queryName: data.name, queryDescription: data.description, editRawId: data.id, tableName: "LicenseInformation", defaultColumns: mySubString, selectedColumns: mySubString, tableColumnsList: mySubString }, () => this.setState({ isLoadingEditData: false }))
        }

    }

    fetchFieldOptions = async () => {
        let options = this.state.options

        await API.graphql(graphqlOperation(listAllLicenseCategroy, {}))
            .then(async (result) => {
                let licenseCategroyoptions = result.data.listAllLicenseCategroy
                licenseCategroyoptions = await this.formatOptions(licenseCategroyoptions)
                options['licenseCategory'] = licenseCategroyoptions
            })
            .catch(err => {
                console.log(err)
            });

        await API.graphql(graphqlOperation(listAllLicenseStatus, {}))
            .then(async (result) => {
                let licenseStatusOptions = result.data.listAllLicenseStatus
                licenseStatusOptions = await this.formatOptions(licenseStatusOptions)
                options['licenseStatus'] = licenseStatusOptions
            })
            .catch(err => {
                console.log(err)
            });

        await API.graphql(graphqlOperation(listAllLicenseType, {}))
            .then(async (result) => {
                let licenseTypeOptions = result.data.listAllLicenseType
                licenseTypeOptions = await this.formatOptions(licenseTypeOptions)
                options['licenseType'] = licenseTypeOptions
            })
            .catch(err => {
                console.log(err)
            });

        await API.graphql(graphqlOperation(listAllBusinessCountry, {}))
            .then(async (result) => {
                let businessCountryOptions = result.data.listAllBusinessCountry
                businessCountryOptions = await this.formatOptions(businessCountryOptions)
                options['businessCountry'] = businessCountryOptions
            })
            .catch(err => {
                console.log(err)
            });

        await API.graphql(graphqlOperation(listAllBusinessState, {}))
            .then(async (result) => {
                let businessStateOptions = result.data.listAllBusinessState
                businessStateOptions = await this.formatOptions(businessStateOptions)
                options['businessState'] = businessStateOptions
            })
            .catch(err => {
                console.log(err)
            });

        await API.graphql(graphqlOperation(listAllBusinessStructure, {}))
            .then(async (result) => {
                let businessStructureOptions = result.data.listAllBusinessStructure
                businessStructureOptions = await this.formatOptions(businessStructureOptions)
                options['businessStructure'] = businessStructureOptions
            })
            .catch(err => {
                console.log(err)
            });

        this.setState({ loading: false, options: options })
    }

    formatOptions = async (data) => {
        return data.map((item) => ({
            label: item.items,
            name: item.items,
        }));
    }

    formatColumns = async (data) => {
        return data.map((item, i) => {
            let defaultValue = this.state.defaultColumns
            defaultValue.push(item.COLUMN_NAME)

            return {
                ...item,
                name: item.COLUMN_NAME,
                id: item.COLUMN_NAME
            };
        });
    }

    formatColumnsDefaultValue = async (data) => {
        return data.map((item, i) => {
            return item.COLUMN_NAME
        });
    }


    fetchQueryData = () => {
        let queryData = localStorage.getItem('queryWorkbenchSettings')
        if (queryData) {
            queryData = JSON.parse(queryData)
            if (queryData.quickStart) {
                this.setState({ quickStart: queryData.quickStart })
            }
        }
    }

    fetchTableColumns = async (tableName) => {
        this.setState({ loading: true })
        let body = {
            tableName: tableName
        }
        var response = await API.post('CustomerRestAPI', '/customers/getColumnName', { body })
        if (response.success) {
            response = response.result
            let notNeededColumns = ['id', 'csvRowUniqueKey', 'active', 'last_updated', 'created_at', 'updated_at']

            response = await response.filter(function (el) {
                let matchIndex = notNeededColumns.findIndex(item => item === el.COLUMN_NAME)
                if (matchIndex > -1) {
                    return false;
                } else {
                    return true;
                }

            });
            let columns = await this.formatColumns(response)
            let defaultColumns = await this.formatColumnsDefaultValue(response)
            this.setState({ tableColumnsList: columns, defaultColumns: defaultColumns, selectedColumns: defaultColumns, disabled: false })
            this.fetchFieldData(defaultColumns)
        } else {
            console.log(response.err)
            message.error(<p dangerouslySetInnerHTML={{ __html: this.state.alertMessage }} />)
        }
        this.setState({ loading: false })
    }

    fetchFieldData = (columns) => {
        let fields = []
        const columnArray = ['licenseCategory', 'licenseStatus', 'businessState', 'businessCountry', 'retail', 'medical', 'businessStructure']
        const AllopratorColoumnArray = ['refreshed_on', "refreshed_on ", 'licenseIssueDate', 'licenseExpireDate']
        const BooleanColumn = ['retail', 'medical']

        const allOperator = [
            { name: '=', label: '=' },
            { name: '!=', label: '!=' },
            { name: '<', label: '<' },
            { name: '>', label: '>' },
            { name: '<=', label: '<=' },
            { name: '>=', label: '>=' },
            { name: 'contains', label: 'contains' },
            { name: 'beginsWith', label: 'begins with' },
            { name: 'endsWith', label: 'ends with' },
            { name: 'doesNotContain', label: 'does not contain' },
            { name: 'doesNotBeginWith', label: 'does not begin with' },
            { name: 'doesNotEndWith', label: 'does not end with' },
            { name: 'null', label: 'is null' },
            { name: 'notNull', label: 'is not null' },
            { name: 'in', label: 'in' },
            { name: 'notIn', label: 'not in' },
            { name: 'between', label: 'between' },
            { name: 'notBetween', label: 'not between' },
        ];

        for (let i = 0; i < columns.length; i++) {
            let obj = {}

            if (columnArray.includes(columns[i])) {
                if (BooleanColumn.includes(columns[i])) {
                    obj.name = columns[i]
                    obj.label = columns[i]
                    obj.valueEditorType = 'select'
                    obj.values = this.state.options[columns[i]]
                    obj.operators = allOperator.filter((item, index) => { return index < 2 });
                    fields.push(obj)
                } else {
                    if (columns[i] === "licenseCategory" || columns[i] === "licenseStatus") {
                        obj.name = columns[i]
                        obj.label = columns[i]
                        obj.valueEditorType = 'select'
                        obj.values = this.state.options[columns[i]]
                        obj.operators = allOperator.filter((item, index) => { return index < 2 || index === 14 || index === 15 });
                        fields.push(obj)
                    } else {
                        obj.name = columns[i]
                        obj.label = columns[i]
                        obj.valueEditorType = 'select'
                        obj.values = this.state.options[columns[i]]
                        obj.operators = allOperator.filter((item, index) => { return index < 2 || index === 6 || index === 7 || index === 8 || index === 9 || index === 10 || index === 11 || index === 14 || index === 15 });
                        fields.push(obj)
                    }
                }

            } else {
                if (AllopratorColoumnArray.includes(columns[i])) {
                    obj.name = columns[i] === "refreshed_on " ? "refreshed_on" : columns[i]
                    obj.label = columns[i]
                    obj.operators = allOperator.filter((item, index) => { return index < 6 || index === 12 || index === 13 || index === 16 || index === 17 })
                    obj.placeholder = 'Input Text'
                    fields.push(obj)
                } else {
                    obj.name = columns[i] === "refreshed_on " ? "refreshed_on" : columns[i]
                    obj.label = columns[i]
                    obj.operators = allOperator.filter((item, index) => { return index < 2 || index === 6 || index === 7 || index === 8 || index === 9 || index === 10 || index === 11 || index === 14 || index === 15 });
                    obj.placeholder = 'Input Text'
                    fields.push(obj)
                }
            }
        }
        this.setState({ fields: fields })
    }

    getQuery = async () => {

        let sorter = ''
        if (this.state.sortedColumn && this.state.sortingOrder) {
            sorter = ` order by ${this.state.sortedColumn} ${this.state.sortingOrder}`
        }
        let selectedColumns = await getSelectedColumns(this.state.selectedColumns)
        let filterString = formatQuery(this.state.query, 'sql')
        // filterString = filterString.replace(/[{()}]/g, '')
        return `select ${selectedColumns} from ${this.state.tableName} where ${filterString} ${sorter} `
    }

    filteredData = (data, value) => data.filter(elem => (
        Object.keys(elem).some(key => elem[key] != null ? elem[key].toString().toLowerCase().includes(value) : "")
    ));


    fetchData = async () => {
        this.setState({ loadingData: true })
        let body = {
            query: this.state.queryJson,
            page: this.state.pageNumber,
            size: this.state.pageSize
        }

        var response = await API.post('CustomerRestAPI', '/customers/getData', { body })
        if (response.success) {
            if (response.customQueryData.length > 0) {
                let columns = await getColumns(response.customQueryData[0])
                this.setState({ columns: columns })
            }
            if (response.customQueryData && response.customQueryData.length > 0) {
                response.customQueryData = await this.formatData(response.customQueryData)
            }
            this.setState({ data: response.customQueryData, total: response.total })
        } else {
            console.log(response.err)
            message.error(<p dangerouslySetInnerHTML={{ __html: this.state.alertMessage }} />)
        }
        this.setState({ loadingData: false })
    }

    formatData = async (data) => {
        return data.map(item => {

            if (item.licenseExpireDate === null) {
                item.licenseExpireDate = "NULL"
            } else {
                item.licenseExpireDate = moment(item.licenseExpireDate).format('YYYY-MM-DD')
            }
            if (item.licenseIssueDate === null) {
                item.licenseIssueDate = "NULL"
            } else {
                item.licenseIssueDate = moment(item.licenseIssueDate).format('YYYY-MM-DD')
            }
            if (item.refreshed_on === null) {
                item.refreshed_on = "NULL"
            } else {
                item.refreshed_on = moment(item.refreshed_on).format('YYYY-MM-DD')
            }
            return item
        })

    }


    handleSave = async () => {
        this.setState({ loading: true })

        if (this.state.queryName && this.state.queryName !== '') {
            let selectedColumns = this.state.selectedColumns
            if (selectedColumns.length > 0) {
                let query = await this.getQuery()
                let cusomerId = window.sessionStorage.getItem('customerId')
                let filterForJson = JSON.stringify(this.state.query)
                if (this.state.editRawId) {
                    let body = {
                        id: this.state.editRawId,
                        userId: cusomerId,
                        query: query,
                        filter: filterForJson,
                        name: this.state.queryName,
                        description: this.state.queryDescription
                    }
                    var response = await API.post('CustomerRestAPI', '/customers/updateCustomQuery', { body })
                    if (response.success) {
                        message.success('Query updated successfully')
                        setTimeout(() => {
                            this.props.history.push('/customquerybuilder')
                        }, 1500);
                    } else {

                        console.log(response.err)
                        message.error(<p dangerouslySetInnerHTML={{ __html: this.state.alertMessage }} />)
                    }
                } else {
                    let body = {
                        userId: cusomerId,
                        query: query,
                        filter: filterForJson,
                        name: this.state.queryName,
                        description: this.state.queryDescription
                    }
                    var response = await API.post('CustomerRestAPI', '/customers/saveQuery', { body })
                    if (response.success) {
                        message.success('Query saved successfully')
                        setTimeout(() => {
                            this.props.history.push('/customquerybuilder')
                        }, 1500);
                    } else {
                        console.log(response.err)
                        if (response.err === 'query with given name exists') {
                            message.error('query with given name exists')
                        } else {
                            message.error(<p dangerouslySetInnerHTML={{ __html: this.state.alertMessage }} />)
                        }
                    }
                }
            } else {
                message.error('please select at least one column')
            }
        } else {
            message.error('please Input Query name')
        }
        this.setState({ loading: false })
    }

    handleExecute = async () => {
        let query = await this.getQuery()
        this.setState({ queryJson: query }, () => {
            this.fetchData()
        })
    }

    handlePurchase = () => {
        window.sessionStorage.setItem('filter', this.state.queryJson)
        window.sessionStorage.setItem("reportname", this.state.queryName)
        window.sessionStorage.setItem("name", this.state.queryName)
        window.sessionStorage.setItem("title", 'Custom Query List')
        window.sessionStorage.removeItem('sorter')
        this.props.history.push("/customquery/purchase");
    }

    onSearch = value => {
        if (value === '') {
            this.setState({ filterdData: null })
            return;
        }
        const newData = this.filteredData(this.state.data, value);
        this.setState({ searchText: value, filterdData: newData })
    };

    onSearchStringChange = event => {
        if (event.target.value === '') {
            this.setState({ searchText: null, filterdData: null })
            return;
        }
        const value = event.target.value.toLowerCase();
        const newData = this.filteredData(this.state.data, value);
        this.setState({ searchText: event.target.value, filterdData: newData })

    };

    onSelectedColumnChange = async (e) => {
        this.setState({ selectedColumns: e })
        this.fetchFieldData(e)
    }

    onPageNumberChange = (pageNumber) => {
        this.setState({ pageNumber: pageNumber }, () => {
            this.fetchData()
        })
    }

    onSizeChange = (current, pageSize) => {
        this.setState({ pageNumber: 1, pageSize: pageSize }, () => {
            this.fetchData()
        })
    }

    handleChange = (data) => {
        this.setState({ query: data, queryJson: data })
    }

    selectTable = async (e) => {
        this.setState({ tableName: e }, () => {
            this.fetchTableColumns(e)
        })
    }

    SelectForSorter = async (name, value) => {
        this.setState({ [name]: value })
    }

    handleNameChange = (e) => {
        this.setState({ queryName: e })
    }

    handleDescriptionChange = (e) => {
        this.setState({ queryDescription: e })
    }

    handleCancel = () => {
        this.props.history.push("/customquerybuilder");
    }

    handleForPopup = () => {
        this.setState({ isShowUnsaveModal: true })
    }

    handleConfirm = () => {
        this.setState({ isShowUnsaveModal: false })
        this.handlePurchase()
    }

    closeModal = (callback) => {
        this.setState({ isShowUnsaveModal: false })
    }



    render() {
        const { isLoadingEditData, unsavedChangesAlertMessage, tableName, queryName, queryDescription, defaultColumns, queryJson, fields, query, loading, disabled, quickStart, isShowUnsaveModal } = this.state
        return (
            <>

                <Modal
                    title="Vertically centered modal dialog"
                    centered
                    visible={isShowUnsaveModal}
                    onOk={() => this.handleConfirm()}
                >
                    <div className='confirm-popup-content2'>
                        <div className='report-modal-content'>
                            <p className='confirm-popup-img'> <img alt="" src={require("assets/images/canoja-leaf-gold.png")} /></p>
                        </div>
                        <div className='report-modal-content confirm-popup-text'>
                            <p><p dangerouslySetInnerHTML={{ __html: unsavedChangesAlertMessage }} /></p>
                        </div>
                    </div>
                    <div className='modal-button'>
                        <Button onClick={() => this.handleConfirm()} type="primary">Yes</Button>
                        <Button onClick={() => this.closeModal()}>No</Button>
                    </div>
                </Modal>

                {isLoadingEditData ? <Card> <Spin size='large' tip="Loading Data..." spinning={isLoadingEditData}></Spin></Card> :

                    <Card>
                        <Spin tip="Loading..." spinning={loading}>
                            <div className='back-arrow'>
                                <Button type="link" className='buttonAsLink' onClick={() => this.handleCancel()}>
                                    <Icon type="arrow-left" />
                                    <span className='left-side-title'>Back to Queries</span>
                                </Button>
                            </div>
                            <Widget >
                                <div className='header-title-createquery'>Quick Start</div>
                                {quickStart && <div><p dangerouslySetInnerHTML={{ __html: quickStart }} /></div>}

                            </Widget>

                            <Row>
                                <Col className='rule-querybuilder' xl={14} sm={24} xs={24}>

                                    <Widget>
                                        <div className='header-title-createquery'>Create a New Query</div>
                                        <Form {...formItemLayout} >
                                            <InputText
                                                field={this.props.form}
                                                label='Query Name'
                                                name='queryName'
                                                validationMessage='Please input correct name'
                                                requiredMessage='Please select name'
                                                display={true}
                                                required={true}
                                                defaultValue={queryName}
                                                onChange={(e) => this.handleNameChange(e)}
                                            />
                                            <InputText
                                                field={this.props.form}
                                                label='Query Description'
                                                name='queryDescription'
                                                validationMessage='Please input correct Query Description'
                                                requiredMessage='Please select Query Description'
                                                display={true}
                                                required={true}
                                                defaultValue={queryDescription}
                                                onChange={(e) => this.handleDescriptionChange(e)}
                                            />

                                            <InputSelect
                                                field={this.props.form}
                                                label='Choose Table'
                                                name='table'
                                                validationMessage='Please input correct table'
                                                requiredMessage='Please select table'
                                                display={true}
                                                required={true}
                                                className='choose-table-class'
                                                defaultValue={tableName}
                                                list={this.state.tableList}
                                                onChange={(e) => this.selectTable(e)}
                                            />


                                            {this.state.tableColumnsList &&
                                                <MultipleSelect
                                                    field={this.props.form}
                                                    label='Choose Columns '
                                                    name='columns'
                                                    validationMessage='Please input correct table'
                                                    requiredMessage='Please select table'
                                                    display={true}
                                                    required={true}
                                                    defaultValue={this.state.defaultColumns}
                                                    list={this.state.tableColumnsList}
                                                    onChange={(e) => this.onSelectedColumnChange(e)}
                                                />
                                            }

                                            {defaultColumns.length > 0 && <QueryBuilder
                                                fields={fields}
                                                query={query}
                                                onQueryChange={this.handleChange}
                                                defaultValue={query}
                                                controlElements={{
                                                    addGroupAction: AntDActionElement,
                                                    addRuleAction: AntDActionElement,
                                                    cloneGroupAction: AntDActionElement,
                                                    cloneRuleAction: AntDActionElement,
                                                    combinatorSelector: AntDValueSelector,
                                                    fieldSelector: AntDValueSelector,
                                                    notToggle: AntDNotToggle,
                                                    operatorSelector: AntDValueSelector,
                                                    removeGroupAction: AntDActionElement,
                                                    removeRuleAction: AntDActionElement,
                                                    valueEditor: ValueEditor,
                                                }}
                                            />}
                                            <br></br>

                                            {/* {this.state.tableColumnsList &&
                                            <Row type="flex" justify="center">
                                                <Col sm={24} xs={24}>
                                                    <InputSelect
                                                        field={this.props.form}
                                                        label='Sorter Column Name'
                                                        name='sorterColumn'
                                                        validationMessage='Please input correct column'
                                                        requiredMessage='Please select column'
                                                        display={true}
                                                        required={true}
                                                        list={this.state.tableColumnsList}
                                                        onChange={(e) => this.SelectForSorter('sortedColumn', e)}
                                                    />
                                                </Col>
                                            </Row>
                                        }

                                        {this.state.tableColumnsList &&
                                            <Row type="flex" justify="center">
                                                <Col sm={24} xs={24}>
                                                    <InputSelect
                                                        field={this.props.form}
                                                        label='Sorting Order'
                                                        name='sortervalue'
                                                        validationMessage='Please input correct order'
                                                        requiredMessage='Please select order'
                                                        display={true}
                                                        required={true}
                                                        list={this.state.sortingOrderList}
                                                        onChange={(e) => this.SelectForSorter('sortingOrder', e)}
                                                    />
                                                </Col>
                                            </Row>
                                        } */}
                                        </Form>
                                        <br></br>
                                        <div className='buttons-group'>
                                            <Button onClick={this.handleCancel} >Cancel</Button>
                                            <Button onClick={this.handleExecute} disabled={disabled}>Execute</Button>
                                            <Button type="primary" onClick={this.handleSave} disabled={disabled} >{this.state.editRawId ? "Update" : "Save"}</Button>
                                        </div>
                                    </Widget>
                                </Col>
                                <Col className='query-detail' xl={10} sm={24} xs={24}>
                                    <Widget >
                                        <div className='header-title-createquery'>Query Detail</div>

                                        <div>
                                            <pre>{JSON.stringify(this.state.query, null, 2)}</pre>
                                        </div>

                                    </Widget>
                                </Col>
                            </Row>
                            {this.state.data &&
                                <Widget >
                                    <div className='header-title-createquery'>Executed Query Records</div>

                                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                        <Search
                                            placeholder={'Search'}
                                            onChange={this.onSearchStringChange}
                                            onSearch={this.onSearch}
                                            value={this.state.searchText}
                                            allowClear
                                            style={{ width: 500, marginRight: 15 }}
                                        />
                                        {this.state.data.length > 0 && <Button style={{ marginRight: 0 }} className='add-button' type="primary" onClick={() => this.handleForPopup()}>Purchase</Button>}
                                    </div>
                                    <DataTable
                                        data={this.state.filterdData || this.state.data}
                                        onShowSizeChange={(current, pageSize) => this.onSizeChange(current, pageSize)}
                                        onChange={(pageNumber) => this.onPageNumberChange(pageNumber)}
                                        pageTotal={this.state.total}
                                        columns={this.state.columns}
                                    />
                                </Widget>

                            }
                        </Spin >
                    </Card>
                }
            </>
        );
    }
}

export default CustomQueryBuilder;
