
import '../../node_modules/jspdf-autotable'
import { API, graphqlOperation } from '../../node_modules/aws-amplify'
import { listSubscriptionPlans, listFeatures, listCanceledSubscriptionBySubscriptionId, listUserByCustomerId, listCouponByCode } from '../graphql/queries'
import { createCanceledSubscription, createUser, updateCanceledSubscription, updateCoupon } from '../graphql/mutations'
import AWS from 'aws-sdk';
import * as Amplify from 'aws-amplify';
import { getEnvByName } from './GeneralService';



export const getSelectedPlan = async (subscriptionId) => {

    return new Promise(async (resolve) => {

        var res = await API.get('PaymentGatewayAPI', `/paymentgateway/subscription/${subscriptionId}`,)
        if (res.success) {
            res = res.subscription
        } else {
            resolve(false)
        }
        let subscriptionType
        let subscriptionStatus
        if (res && res.subscription && res.subscription.name) {
            subscriptionType = res.subscription.name
            subscriptionStatus = res.subscription.status
        }

        let response = await API.graphql(graphqlOperation(listSubscriptionPlans, {}))
        if (!response.errors) {
            response = response.data.listSubscriptionPlans
            if (response.length > 0) {
                let selectedPlan = response.filter(item => item.planType === subscriptionType)
                selectedPlan[0] = { ...selectedPlan[0], subscriptionStatus: subscriptionStatus }
                resolve(selectedPlan)
            } else {
                resolve(false)
            }
        }
    })
}

export const getSelectedPlanByPlanType = async (planType) => {

    return new Promise(async (resolve) => {

        let response = await API.graphql(graphqlOperation(listSubscriptionPlans, {}))
        if (!response.errors) {
            response = response.data.listSubscriptionPlans
            if (response.length > 0) {
                let selectedPlan = response.filter(item => item.planType === planType && item.active === 'true')
                resolve(selectedPlan[0])
            } else {
                resolve(false)
            }
        }
    })
}


export const getSelectedPlanFeature = async (subscriptionId) => {
    return new Promise(async (resolve) => {
        let selectedPlanType = null
        let selectedPlan = await getSelectedPlan(subscriptionId)
        if (selectedPlan !== false && selectedPlan.length > 0) {
            selectedPlanType = selectedPlan[0].planType
        } else {
            resolve(false)
        }
        let response = await API.graphql(graphqlOperation(listFeatures, {}))
        if (!response.errors) {
            response = response.data.listFeatures
            if (response.length > 0) {
                let selectedPlanFeature = response.filter(item => item.subscriptionPlanId === selectedPlanType)
                resolve(selectedPlanFeature)
            } else {
                resolve(false)
            }
        }
    })
}

export const getFreePlanFeatures = async () => {
    return new Promise(async (resolve) => {
        let res = await API.graphql(graphqlOperation(listFeatures, {}))
        if (!res.errors) {
            res = res.data.listFeatures
            if (res && res.length > 0) {
                let selectedPlanFeature = res.filter(item => item.subscriptionPlanId === 'FREE')
                resolve(selectedPlanFeature)
            } else {
                resolve(false)
            }
        }
    })
}

export const getAccessKey = (length) => {
    if (!length) {
        length = 26
    }
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < length; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
}

export const isPremiumOrEnterprisePlan = () => {
    return new Promise(async (resolve) => {
        var subscriptionId = window.sessionStorage.getItem('subscriptionId')
        if (subscriptionId) {
            let selectedPlan = await getSelectedPlan(subscriptionId)
            if (selectedPlan === false) {
                resolve(false)
            } else {
                if (selectedPlan[0].planType && (selectedPlan[0].planType === "PREMIUM" || selectedPlan[0].planType === "ENTERPRISE")) {
                    resolve(true)
                } else {
                    resolve(false)
                }
            }
        } else {
            resolve(false)
        }
    })
}

export const isEnterprisePlan = () => {
    return new Promise(async (resolve) => {
        var subscriptionId = window.sessionStorage.getItem('subscriptionId')
        if (subscriptionId) {
            let selectedPlan = await getSelectedPlan(subscriptionId)
            if (selectedPlan === false) {
                resolve(false)
            } else {
                if (selectedPlan[0].planType && selectedPlan[0].planType === "ENTERPRISE" && selectedPlan[0].subscriptionStatus === 'active') {
                    resolve(true)
                } else {
                    resolve(false)
                }
            }
        } else {
            resolve(false)
        }
    })
}


export const createAuthorizeCustomer = (userEmail) => {
    return new Promise(async (resolve) => {
        let customer_id = await getAccessKey(10)
        const body = {
            email: userEmail,
            customer_id: customer_id
        }
        var response = await API.post('PaymentGatewayAPI', `/paymentgateway/customers`, { body })
        if (response.success) {
            console.log(response)
            let userAccessKey = await getAccessKey()
            let userBody = {
                createUserInput: {
                    customerProfileId: response.customer.customerProfileId,
                    email: userEmail,
                    accessKey: userAccessKey,
                    active: true
                }
            }
            await API.graphql(graphqlOperation(createUser, userBody))
                .then(result => {
                    window.sessionStorage.setItem('customerId', response.customer.customerProfileId)
                    window.sessionStorage.setItem('accessKey', userAccessKey)
                    resolve(true)
                })
                .catch(err => {
                    console.log(err)
                    resolve(false)
                });
        } else {
            resolve(false)
        }
    })
}


export const isPremiumOrEnterprisePlan2 = () => {
    return new Promise(async (resolve) => {
        var subscriptionId = window.sessionStorage.getItem('subscriptionId')
        if (subscriptionId) {
            let selectedPlan = await getSelectedPlan(subscriptionId)
            if (selectedPlan === false) {
                resolve(false)
            } else {
                if (selectedPlan[0].planType && selectedPlan[0].planType === "PREMIUM") {
                    resolve('PREMIUM')
                } else if ((selectedPlan[0].planType && selectedPlan[0].planType === "ENTERPRISE")) {
                    resolve('ENTERPRISE')
                } else {
                    resolve(false)
                }
            }
        } else {
            resolve(false)
        }
    })
}

export const checkCanceledSubscription = async (id) => {
    return new Promise(async (resolve) => {
        let body = {
            subscriptionId: id
        }
        let response = await API.graphql(graphqlOperation(listCanceledSubscriptionBySubscriptionId, body))
        if (response.data && response.data.listCanceledSubscriptionBySubscriptionId && response.data.listCanceledSubscriptionBySubscriptionId && response.data.listCanceledSubscriptionBySubscriptionId.items.length > 0) {
            resolve(response.data.listCanceledSubscriptionBySubscriptionId.items[0])
        } else {
            resolve(false)
        }
    })
}


export const cancelSubscription = async (id) => {
    return new Promise(async (resolve) => {
        let isPlanEnterpriseOrPremium = await isPremiumOrEnterprisePlan2()
        let isCancelSuccess
        if (isPlanEnterpriseOrPremium === false) {
            var result = await API.del('PaymentGatewayAPI', `/paymentgateway/subscription/${id}`)
            if (result.success) {
                window.sessionStorage.removeItem('subscriptionId')
                let logout = 'logout'
                resolve(logout)
            } else {
                console.log(result.err)
                resolve(false)
            }
        } else {
            let cancelesSubscription = await checkCanceledSubscription(id)
            let cancelSubscriptionData
            if (cancelesSubscription === false) {
                let customerId = window.sessionStorage.getItem('customerId')
                cancelSubscriptionData = {
                    createCanceledSubscriptionInput: {
                        customerProfileId: customerId,
                        subscriptionId: id,
                        isCancelApplied: 'true',
                        isCanceled: 'false',
                    }
                }

                await API.graphql(graphqlOperation(createCanceledSubscription, cancelSubscriptionData))
                    .then(result => {
                        isCancelSuccess = true
                    })
                    .catch(err => {
                        console.log(err)
                        isCancelSuccess = false
                    });

            } else {
                cancelSubscriptionData = {
                    updateCanceledSubscriptionInput: {
                        id: cancelesSubscription.id,
                        isCancelApplied: 'true',
                    }
                }
                await API.graphql(graphqlOperation(updateCanceledSubscription, cancelSubscriptionData))
                    .then(result => {
                        isCancelSuccess = true
                    })
                    .catch(err => {
                        console.log(err)
                        isCancelSuccess = false
                    });

            }

            if (isCancelSuccess) {
                resolve(true)
            } else {
                resolve(false)
            }
        }
    })
}



export const checkIsBasicPlan = async (id) => {
    return new Promise(async (resolve) => {
        let result = false
        if (id) {
            let body = {
                customerId: id
            }
            let response = await API.graphql(graphqlOperation(listUserByCustomerId, body))
            if (response.data && response.data.listUserByCustomerId && response.data.listUserByCustomerId && response.data.listUserByCustomerId.items.length > 0) {
                if (response.data.listUserByCustomerId.items[0].planType === 'BASIC') {
                    result = await getSelectedPlanByPlanType('BASIC')
                    result.createdAt = response.data.listUserByCustomerId.items[0].createdAt
                }
            }
        }
        resolve(result)

    })
}


export const getUserBycustomerId = async (id) => {
    return new Promise(async (resolve) => {
        let result = false
        if (id) {
            let body = {
                customerProfileId: id
            }
            let res = await API.post('PaymentGatewayAPI', '/paymentgateway/user/validate/offermail', { body })
            if (res.success) {
                res = res.data
                if (res && res.isCouponApplied === true) {
                    result = true
                    sessionStorage.setItem('isDiscountRegistered', 'true')
                }
            }
        }

        if (result === false) {
            result = await checkIsShowDiscountModal()
        }

        resolve(result)
    })
}

export const checkIsShowDiscountModal = async () => {
    return new Promise(async (resolve) => {
        let result = true
        var res = await API.post('PaymentGatewayAPI', '/paymentgateway/generalSettings', {})
        if (res.success) {
            res = res.result
            if (res && res.length > 0) {
                if (res[0].discountModal && res[0].discountModal === 'ENABLE') {
                    result = false
                    sessionStorage.setItem('isShowDiscountModal', 'true')
                } else {
                    sessionStorage.setItem('isShowDiscountModal', 'false')
                }
            }
        }
        resolve(result)
    })
}

export const getCouponByCode = async (code) => {
    return new Promise(async (resolve) => {
        let result = false
        if (code) {
            let body = {
                code: code,
                active: 'true'
            }
            let response = await API.graphql(graphqlOperation(listCouponByCode, body))
            if (response.data && response.data.listCouponByCode && response.data.listCouponByCode && response.data.listCouponByCode.items.length > 0) {
                if (response.data.listCouponByCode.items[0]) {
                    result = response.data.listCouponByCode.items[0]
                }
            }
        }
        resolve(result)

    })
}

const createArray = async (data) => {
    data = data.split("@@")
    data.pop()
    return data
  }

export const checkCouponIsValid = async (coupon, plan, attachTo) => {
    return new Promise(async (resolve) => {
        let result = 'Invalid Promotion code'
        let isValidate = false
        if (coupon.couponAttachTo === 'both') {
            isValidate = true
        } else if (coupon.couponAttachTo === attachTo) {
            isValidate = true
        } else if (coupon.couponAttachTo === attachTo) {
            isValidate = true
        } else {
            result = `Promotion code not apply for ${attachTo} `
        }
        if (isValidate === true) {
            let applyFor = await createArray(coupon.couponApplyFor)
            let matchingIndex = applyFor.findIndex((item) => item === plan)
            if (matchingIndex > -1) {
                let todayDate = new Date(); //Today Date    
                let redeemedDate = new Date(coupon.redeemBy)
                if (redeemedDate >= todayDate) {
                    if (coupon.duration === "repeating") {
                        if (coupon.durationInMonth === '0') {
                            await updateCouponCode(coupon)
                            result = 'Promotion code Expired'
                        } else {
                            result = true
                        }
                    } else {
                        result = true
                    }
                } else {
                    await updateCouponCode(coupon)
                    result = 'Promotion code Expired'
                }
            } else {
                result = 'Promotion code not applicable for this plan'
            }
        }
        resolve(result)

    })
}

export const addUsageForCoupon = async (discount, coupon) => {
    return new Promise(async (resolve) => {
        if (discount && discount > 0 && coupon) {
            let result = false
            if (coupon.duration === "once") {
                await updateCouponCode(coupon)
            } else if (coupon.duration === "repeating") {
                coupon.durationInMonth = Number(coupon.durationInMonth) - 1
                if (coupon.durationInMonth === 0) {
                    await updateCouponCode(coupon)
                } else {
                    await usageControlForCoupon(coupon)
                }
            } else {
                result = true
            }
            resolve(result)
        } else {
            resolve(false)
        }
    })
}

export const updateCouponCode = async (code) => {
    return new Promise(async (resolve) => {
        let result = false
        if (code) {
            let couponData = {
                updateCouponInput: {
                    id: code.id,
                    active: 'false'
                }
            }
            await API.graphql(graphqlOperation(updateCoupon, couponData))
                .then(result => {
                    this.onSelectionChange(this.state.selectedRowKeys, this.state.selectedRows);
                    resolve(true)
                })
                .catch(err => {
                    console.log(err)
                    resolve(true)
                });
        }
        resolve(result)
    })
}

export const usageControlForCoupon = async (code) => {
    return new Promise(async (resolve) => {
        let result = false
        if (code) {
            let couponData = {
                updateCouponInput: {
                    id: code.id,
                    durationInMonth: code.durationInMonth,
                    active: 'true'
                }
            }
            await API.graphql(graphqlOperation(updateCoupon, couponData))
                .then(result => {
                    this.onSelectionChange(this.state.selectedRowKeys, this.state.selectedRows);
                    resolve(true)
                })
                .catch(err => {
                    console.log(err)
                    resolve(true)
                });
        }
        resolve(result)
    })
}

export const isEnterprisePlanWithPlanData = (subscription) => {
    return new Promise(async (resolve) => {
        let selectedPlan = await getSelectedPlanByData(subscription)
        if (selectedPlan === false) {
            resolve({ isValid: false, data: {} })
        } else {
            if (selectedPlan[0].planType && selectedPlan[0].planType === "ENTERPRISE" && selectedPlan[0].subscriptionStatus === 'active') {
                resolve({ isValid: true, data: selectedPlan })
            } else {
                resolve({ isValid: false, data: selectedPlan })
            }
        }
    })
}

export const getSelectedPlanByData = async (subscription) => {

    return new Promise(async (resolve) => {

        let subscriptionType
        let subscriptionStatus
        if (subscription && subscription.name) {
            subscriptionType = subscription.name
            subscriptionStatus = subscription.status
        }

        let response = await API.graphql(graphqlOperation(listSubscriptionPlans, {}))
        if (!response.errors) {
            response = response.data.listSubscriptionPlans
            if (response.length > 0) {
                let selectedPlan = response.filter(item => item.planType === subscriptionType)
                selectedPlan[0] = { ...selectedPlan[0], subscriptionStatus: subscriptionStatus }
                resolve(selectedPlan)
            } else {
                resolve(false)
            }
        }
    })
}

export const getGeneralSettings = async () => {
    return new Promise(async (resolve) => {
        let result = {}
        var res = await API.post('PaymentGatewayAPI', '/paymentgateway/generalSettings', {})
        if (res.success) {
            res = res.result
            if (res && res.length > 0) {
                result = res[0]
            }

        }
        resolve(result)
    })
}



